import { TableHead, TableRow } from '@mui/material'

import { StatisticsTableLeftColumns } from '../../helpers/StatisticsTableColumns/StatisticsTableLeftColumns'
import { StatisticsTableRightColumns } from '../../helpers/StatisticsTableColumns/StatisticsTableRightColumns'
import { TableHeadCellStyled } from '../../styled/StatisticsTable.styled'
import { StatisticsTableHeadCalendar } from './StatisticsTableHeadCalendar'

export const StatisticsTableHead = () => {
  return (
    <TableHead
      sx={{
        position: 'sticky',
        width: '100%',
        top: 0,
        left: 0,
      }}
    >
      <TableRow>
        <TableHeadCellStyled sx={{ minWidth: 60, borderRight: '1px solid #2950B4' }}>№, п/п</TableHeadCellStyled>
        {StatisticsTableLeftColumns.map(column => (
          <TableHeadCellStyled key={column.name} sx={{ minWidth: column.width }}>
            {column.name}
          </TableHeadCellStyled>
        ))}
        <StatisticsTableHeadCalendar />
        {StatisticsTableRightColumns.map(column => (
          <TableHeadCellStyled key={column.name} sx={{ minWidth: column.width }}>
            {column.name}
          </TableHeadCellStyled>
        ))}
        <TableHeadCellStyled sx={{ minWidth: 143, background: '#2250C7' }}>Итого к выплате</TableHeadCellStyled>
      </TableRow>
    </TableHead>
  )
}
