import { useAppDispatch } from 'hooks/redux'
import { useDeepEffect } from './../../useCustomCompare'
import { filteredSortToSelect, ROLES_COMPANY_LEADER, ROLES_PROJECT_LEADER } from './../../../helpers/roles'
import { ROLES_FOREMAN, ROLES_WORKER } from '../../../helpers/roles'
import { useMemo } from 'react'
import { useActionCreatorsTyped, useAppSelector } from '../../redux'
import { employeesActions } from '../../../store/reducers/EmployeesSlice'

export const useEmployeeCreateState = () => {
  const statesEmployee = useAppSelector(state => state.employeesReducer.statesEmployee)
  const isAddEmployee = useAppSelector(state => state.employeesReducer.isAddEmployee)
  const currentOrganization = useAppSelector(state => state.organizationReducer.currentOrganization)
  const projectId = useAppSelector(state => state.projectReducer.projectId)
  const currentRole = useAppSelector(state => state.rolesReducer.currentRole)

  const roles = useAppSelector(state => state.rolesReducer.roles)
  const positions = useAppSelector(state => state.positionReducer.positions)
  const activeProject = useAppSelector(state => state.projectReducer.activeProject)
  const actions = useActionCreatorsTyped(employeesActions)

  const dispatch = useAppDispatch()

  const elementsCommonEmployee = [
    {
      name: 'surname',
      label: 'Фамилия',
      type: 'input',
      required: true,
      tabIndex: 1,
    },
    {
      name: 'name',
      label: 'Имя',
      type: 'input',
      required: true,
      tabIndex: 1,
    },
    {
      name: 'patronymic',
      label: 'Отчество',
      type: 'input',
      tabIndex: 1,
    },
    {
      name: 'phoneNumber',
      label: 'Номер телефона',
      type: 'pattern',
      format: '+7 ### ### ## ##',
      mask: '_',
      placeholder: '+7 000 000 00 00',
      required: true,
      tabIndex: 1,
    },
    {
      name: 'citizenship',
      label: 'Гражданство сотрудника',
      type: 'countries',
      keyValue: 'localeRU',
      required: true,
      tabIndex: 1,
    },
    {
      name: 'birthDate',
      label: 'Дата рождения',
      type: 'input',
      inputType: 'date',
      required: true,
      tabIndex: 1,
    },
    {
      name: 'userName',
      label: 'Логин',
      type: 'input',
      required: true,
      tabIndex: 0,
    },
    {
      name: 'email',
      label: 'Email',
      inputType: 'email',
      type: 'input',
      required: true,
      tabIndex: 0,
    },
    {
      name: 'password',
      label: 'Пароль',
      inputType: 'password',
      type: 'input',
      required: true,
      tabIndex: 0,
    },
  ]

  const elementsWorker = [
    ...elementsCommonEmployee,
    {
      name: 'positionId',
      label: 'Должность',
      type: 'select',
      required: true,
      tabIndex: 2,
    },
    {
      name: 'workingMethod',
      label: 'Метод работы',
      type: 'input',
      required: true,
      tabIndex: 2,
    },
    {
      name: 'dayTimeHourlyPayment',
      label: 'Почасовая оплата для дневной смены, ₽',
      type: 'number',
      required: true,
      tabIndex: 2,
    },
    {
      name: 'nightTimeHourlyPayment',
      label: 'Почасовая оплата для ночной смены, ₽',
      type: 'number',
      tabIndex: 2,
      required: true,
    },
  ]

  const elementsForeman = [
    ...elementsCommonEmployee,
    // {
    //   name: 'projectId',
    //   label: 'Проект',
    //   type: 'select',
    //   tabIndex: 1,
    //   required: true,
    // },
    {
      name: 'workingMethod',
      label: 'Метод работы',
      type: 'input',
      required: true,
      tabIndex: 2,
    },
  ]

  useDeepEffect(() => {
    const filteredPositions: any = positions.map(pos => {
      return {
        value: pos.id,
        text: pos.name,
      }
    })

    dispatch(
      employeesActions.setOptionsEmployee({
        roleId: filteredSortToSelect(roles, activeProject),
        positionId: filteredPositions,
      }),
    )
  }, [activeProject, isAddEmployee])

  const elementsCompanyLeader = [...elementsCommonEmployee]

  const elements = useMemo(() => {
    if (!currentRole || !currentOrganization) return

    const employeeData = {
      name: '',
      surname: '',
      citizenship: '',
      userName: '',
      password: '',
      email: '',
      phoneNumber: '',
      birthDate: '',
    }

    switch (currentRole.name) {
      case ROLES_FOREMAN:
        actions.setStatesEmployee({
          ...statesEmployee,
          stateCreateEmployee: {
            ...employeeData,
            projectId: projectId,
            organizationId: currentOrganization.id,
            workingMethod: '',
          },
        })
        return elementsForeman
      case ROLES_COMPANY_LEADER:
        actions.setStatesEmployee({
          ...statesEmployee,
          stateCreateEmployee: {
            ...employeeData,
            organizationId: currentOrganization.id,
          },
        })
        return elementsCompanyLeader
      case ROLES_PROJECT_LEADER:
        actions.setStatesEmployee({
          ...statesEmployee,
          stateCreateEmployee: {
            ...employeeData,
            projectId: projectId,
            organizationId: currentOrganization.id,
            workingMethod: '',
          },
        })
        return elementsForeman
      case ROLES_WORKER:
        actions.setStatesEmployee({
          ...statesEmployee,
          stateCreateEmployee: {
            ...employeeData,
            projectId: projectId,
            organizationId: currentOrganization.id,
            workingMethod: '',
            positionId: null,
            dayTimeHourlyPayment: null,
            nightTimeHourlyPayment: null,
          },
        })
        return elementsWorker
      default:
        return elementsCommonEmployee
    }
  }, [currentRole])

  return { elements, statesEmployee }
}
