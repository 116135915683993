import { AssignmentFile } from './../../../../../../../models/requests/project-file-requests.model'

export const TabPanelSwitchAssignmentFileType = (type: AssignmentFile) => {
  switch (type) {
    case AssignmentFile.TKP:
      return 'ТКП и КП'
    case AssignmentFile.Contracts:
      return 'Договоры и ДС'
    case AssignmentFile.Other:
      return 'Другое'
    default:
      return type
  }
}
