export const getPutEmployeeParams = (put: any) => {
  if (!put) return

  let params: any = {}

  let arr = Object.keys(put)

  arr.map(key => {
    switch (key) {
      case 'workingMethod':
      case 'positionId':
      case 'dayTimeHourlyPayment':
      case 'nightTimeHourlyPayment':
        params = {
          ...params,
          [key]: put[key],
        }
        break
      default:
        params = {
          ...params,
          userData: {
            ...params.userData,
            [key]: put[key],
          },
        }
        break
    }
  })

  return params
}
