import { memo, useCallback } from 'react'
import { Typography } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { organizationActions } from 'store/reducers/OrganizationSlice'
import { OrganizationCardContentItem } from './OrganizationCardContentItem/OrganizationCardContentItem'
import { ButtonShowMorePagination } from 'features/MainPage/components/ButtonShowMorePagination/ButtonShowMorePagination'
import { useCheckClaims } from 'hooks/useCheckClaims'

export const OrganizationCardListChildrenOrg = memo(() => {
  const childrenOrganizations = useAppSelector(state => state.organizationReducer.childrenOrganizations)
  const totalItemsChildrenOrg = useAppSelector(state => state.organizationReducer.totalItemsChildrenOrg)
  const perPageChildrenOrg = useAppSelector(state => state.organizationReducer.perPageChildrenOrg)

  const { checkClaims } = useCheckClaims()

  const dispatch = useAppDispatch()

  const handleShowMore = useCallback(
    () => dispatch(organizationActions.setPerPageChildrenOrg(perPageChildrenOrg * 2)),
    [perPageChildrenOrg],
  )

  return childrenOrganizations && childrenOrganizations.length ? (
    <>
      {childrenOrganizations.map(org => (
        <OrganizationCardContentItem key={org.id} {...{ org }} />
      ))}
      {perPageChildrenOrg < totalItemsChildrenOrg && <ButtonShowMorePagination handleShowMore={handleShowMore} />}
    </>
  ) : (
    <Typography>{checkClaims('Front.organizationCard.addChildrenOrganization') && 'Нет дочерних организаций'}</Typography>
  )
})
