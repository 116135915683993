import { memo, useCallback, useState } from 'react'
import { Box } from '@mui/material'

import { uploadFile } from 'assets/images'
import { CustomButton } from 'components/UI/Button/CustomButton'
import { TabPanelDocumentsUploadFileModal } from './TabPanelDocumentsUploadFileModal'
import { useAppSelector } from 'hooks/redux'

export const TabPanelDocumentsUploadFile = memo(() => {
  const [open, setOpen] = useState<boolean>(false)
  const handleOpen = useCallback(() => setOpen(true), [])
  const handleClose = useCallback(() => setOpen(false), [])

  const isEditProject = useAppSelector(state => state.projectReducer.isEditProject)

  return (
    <>
      <Box mb={3.5}>
        <CustomButton
          disabled={!isEditProject}
          onClick={handleOpen}
          startIcon={<img src={uploadFile} alt={uploadFile} />}
          sx={{ borderRadius: 2, textTransform: 'capitalize', fontSize: 16 }}
        >
          Загрузить
        </CustomButton>
      </Box>
      <TabPanelDocumentsUploadFileModal {...{ open, handleClose }} />
    </>
  )
})
