import qs from 'query-string'
import { PeriodicPaymentResponse } from '../../models/responses/periodic-payment.response'
import { qrAuthTracker } from './../axios'
import { AxiosResponseModel } from '../types'
import { PeriodicPaymentRequestsBodyModel, PeriodicPaymentRequestsQueryModel } from '../../models/requests/periodic-payment-requests.model'

// =================== PERIODIC PAYMENT  ============================

//Пакетно добавить периодические выплаты сотруднику на проекте.
export const qrAuthPostAddPeriodicPayments = (id: number, params: PeriodicPaymentRequestsBodyModel.post): AxiosResponseModel<null> =>
  qrAuthTracker.post(`/management/worker/${id}/periodic-payments`, params)

//Получить периодические выплаты сотрудника на проекте в определённый период.
export const qrAuthGetPeriodicPayments = (
  query: PeriodicPaymentRequestsQueryModel.get = {},
  params: PeriodicPaymentRequestsBodyModel.postFilter,
): AxiosResponseModel<PeriodicPaymentResponse[]> =>
  qrAuthTracker.post(`/management/worker/periodic-payments?${qs.stringify(query)}`, params)
