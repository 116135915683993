import { useEffect, memo } from 'react'
import { useParams } from 'react-router-dom'

import { CustomLoader } from 'components/UI/CustomLoader/CustomLoader'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { getWorkerInProject } from 'service/Worker'
import { WorkerCardHeader } from './modules/WorkerCardHeader/WorkerCardHeader'
import { WorkerCardInfo } from './modules/WorkerCardInfo/WorkerCardInfo'

export const WorkerCard = memo(() => {
  const params = useParams()
  const dispatch = useAppDispatch()

  const status = useAppSelector(state => state.employeesReducer.status)
  const statusPosition = useAppSelector(state => state.positionReducer.status)
  const isPutEmployee = useAppSelector(state => state.employeesReducer.isPutEmployee)
  const isDeleteEmployee = useAppSelector(state => state.employeesReducer.isDeleteEmployee)
  const activeEmployee = useAppSelector(state => state.employeesReducer.activeEmployee)

  useEffect(() => {
    dispatch(getWorkerInProject(Number(params.employeeId)))
  }, [params, isPutEmployee, isDeleteEmployee])

  if (status === 'init' || status === 'loading' || statusPosition === 'init' || statusPosition === 'loading' || !activeEmployee)
    return <CustomLoader />

  return (
    <>
      <WorkerCardHeader />
      <WorkerCardInfo />
    </>
  )
})
