import { useNavigate } from 'react-router-dom'

import { projectActions } from 'store/reducers/ProjectSlice'
import { employeesActions } from 'store/reducers/EmployeesSlice'
import { useAppDispatch } from './redux'
import { organizationActions } from '../store/reducers/OrganizationSlice'
import { themeSlice } from '../store/reducers/ThemeSlice'
import { currentUserActions } from '../store/reducers/CurrentUserSlice'

export const useLogout = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  return function exit() {
    dispatch(currentUserActions.logout())
    dispatch(organizationActions.organizationResetData())
    dispatch(projectActions.projectReset())
    dispatch(employeesActions.employeesReset())
    dispatch(themeSlice.actions.setBreadCrumbs([]))
    sessionStorage.clear()
    localStorage.clear()
    navigate('/')
  }
}
