export const getCreateProjectParams = (create: any) => {
  if (!create) return

  let arr = Object.keys(create)
  let params: any = {}

  arr.forEach(key => {
    let projectInfo = params.projectInfo ? params.projectInfo : {}
    switch (key) {
      case 'name':
        params = {
          ...params,
          [key]: create[key],
        }
        break
      default:
        params = {
          ...params,
          projectInfo: {
            ...projectInfo,
            [key]: create[key],
          },
        }
        break
    }
  })

  return params
}
