export const ADMIN_AREA_PATH = 'adminArea'
export const PEOPLE_LIST_PATH = 'peopleList/:orgId'
export const PRINT_QR_ALL_PEOPLE_PATH = 'printQrAll/:orgId'
export const PRINT_QR_ALL_PEOPLE_PROJ_PATH = 'printQrAll/:orgId/:projectId' // люди которые в проекте
export const PROJECTS_PATH = `${ADMIN_AREA_PATH}/projects/:orgId`
export const PROJECT_PATH = `${ADMIN_AREA_PATH}/project/:projectId`
export const WORKER_PATH = `${ADMIN_AREA_PATH}/:orgId/project/:projectId/employee/:employeeId`
export const WORKERS_PATH = `${ADMIN_AREA_PATH}/projects/:orgId/employees/:projectId`
export const PRIVACY_POLICY_PATH = 'privacy-policy'
export const USER_PROFILE_PATH = 'userProfile'
export const REPORT_TABLE_PATH = 'statistics'
export const LOGIN_PATH = '/'

export const RoutesPath = [
  { path: ADMIN_AREA_PATH, title: 'Организации' },
  { path: PEOPLE_LIST_PATH, title: 'Все сотрудники' },
  { path: PRINT_QR_ALL_PEOPLE_PATH, title: 'Распечатка qr кодов' },
  { path: PRINT_QR_ALL_PEOPLE_PATH, title: 'Распечатка qr кодов' },
  { path: PROJECTS_PATH, title: 'Проекты' },
  { path: WORKERS_PATH, title: 'Сотрудники в проекте' },
  { path: USER_PROFILE_PATH, title: 'Личный кабинет' },
  { path: REPORT_TABLE_PATH, title: 'Статистика' },
  { path: LOGIN_PATH, title: 'Авторизация' },
  { path: PRIVACY_POLICY_PATH, title: 'Политика конфиденциальности' },
]
