import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { ProjectPassportHeader } from './modules/ProjectPassportHeader/ProjectPassportHeader'
import { ProjectPassportTabs } from './modules/ProjectPassportTabs/ProjectPassportTabs'
import { useAppDispatch } from 'hooks/redux'
import { fetchProjectById } from 'service/Projects'

export const ProjectPassport = () => {
  const params = useParams()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchProjectById(Number(params.projectId)))
  }, [params])

  return (
    <>
      <ProjectPassportHeader />
      <ProjectPassportTabs />
    </>
  )
}
