import { Box, Typography } from '@mui/material'
import { ProjectStatus } from '../../models/requests/project-requests.model'

interface Props {
  status: ProjectStatus
}

export const Statuses = ({ status }: Props) => {
  let name: string = ''
  let backgroundColor: string = ''
  let color: string = ''

  switch (status) {
    case ProjectStatus.Open:
      name = 'Открытый'
      color = '#6DC300'
      backgroundColor = 'rgba(109, 196, 0, 0.2)'
      break
    case ProjectStatus.Suspended:
      name = 'Приостановлен'
      color = '#FEB648'
      backgroundColor = 'rgba(254, 182, 72, 0.2)'
      break
    case ProjectStatus.Closed:
      name = 'Закрыт'
      color = '#FF5833'
      backgroundColor = 'rgba(255, 88, 51, 0.2)'
      break
  }

  return (
    <Box
      sx={{
        height: 22,
        borderRadius: 8,
        background: backgroundColor,
        px: 1.5,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <Typography fontSize={9} fontWeight={700} color={color}>
        {name}
      </Typography>
    </Box>
  )
}
