import { useAppSelector } from 'hooks/redux'
import { ProjectStatus } from 'models/requests/project-requests.model'

export const useStatisticsActionsDisabled = () => {
  const activeProject = useAppSelector(state => state.projectReducer.activeProject)

  const onDisabled = () => {
    if (Object.keys(activeProject).length === 0) return false
    if (activeProject.projectStatus !== ProjectStatus.Open) {
      return true
    }
    return false
  }

  return { onDisabled }
}
