import React, { useEffect, useRef } from 'react'
import equal from 'fast-deep-equal'

function usePrevious<T>(value: T) {
  const valueRef = useRef<T | null>(null)
  useEffect(() => {
    valueRef.current = value
  }, [value])
  return valueRef
}

function useCustomCompare(deps: any[], isEqual: (a: any, b: any) => boolean) {
  const counterRef = useRef(0)
  const prevDeps = usePrevious(deps)
  if (!isEqual(prevDeps.current, deps)) {
    counterRef.current++
  }
  return [counterRef.current]
}

export const useDeepEffect = (cb: React.EffectCallback, deps: any[]) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(cb, useCustomCompare(deps, equal))
}
