import { toast } from 'react-toastify'

// конвертация файла в base64
export const convertBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)

    fileReader.onload = () => {
      resolve(fileReader.result)
    }

    fileReader.onerror = error => {
      reject(error)
    }
  })
}

//Расширения фото для профиля пользователя
export const profilePhotoExtNames = ['png', 'jpg', 'jpeg', 'gif', 'bmp', 'webp', 'ico']

// Проверка расширения файла
export const checkExtNameFile = (extNames: string[], file: File): boolean => {
  const ext = file.name.split('.').pop()

  const toaster = () => toast.error(`Можно грузить файл только с расширениями ${extNames.join(', ')}`)

  if (!ext) {
    toaster()

    return false
  }

  if (!extNames.includes(ext)) {
    toaster()
  }

  return extNames.includes(ext)
}
