import { qrLoginParams } from '../api/types'
import { qrAuthLogin } from '../api/utils/authApi'
import { AppDispatch } from '../store/store'
import { currentUserActions } from '../store/reducers/CurrentUserSlice'

export const userSignIn = (loginAndPassword: qrLoginParams) => async (dispatch: AppDispatch) => {
  try {
    dispatch(currentUserActions.setStatus('loading'))

    const response = await qrAuthLogin(loginAndPassword)

    dispatch(currentUserActions.setCurrentUser(response.data.items.user))
    dispatch(currentUserActions.setToken(response.data.items.authToken))

    return Promise.resolve(response.data)
  } catch (err: any) {
    dispatch(currentUserActions.setStatus('error'))
    return Promise.reject(err)
  } finally {
    dispatch(currentUserActions.setStatus('success'))
  }
}
