import { fetchCreateWorker } from 'service/WorkerThunks'
import { EmployeeStateStaffType } from 'helpers/elementsCreateAndEditPeople'
import { RoleModel, UserResponse } from 'models/responses/user.response'
import { StatusType } from 'models/common/common.model'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SearchEmployeeModel } from 'models/search.model'
import { fetchPutWorker } from 'service/WorkerThunks'
import { fetchCreateForeman, fetchPutForeman } from 'service/ForemanThunks'
import { fetchDeleteEmployeeInProject } from 'service/Employees'
import { fetchPutProjectLeader } from 'service/ProjectLeaderThunks'
import { COUNT_ITEMS_PER_PAGE } from 'contants/constants'

export interface IStates {
  statePutEmployee: Partial<EmployeeStateStaffType> | null
  stateCreateEmployee: EmployeeStateStaffType | null
}

interface OptionsEmployee {
  [key: string]: Array<{
    value: string | number
    text: string
  }>
}

interface InitialState {
  employees: UserResponse[]
  page: number
  pageCount: number
  perPage: number
  totalItems: number
  searchEmployees: SearchEmployeeModel[]
  allEmployees: any[]
  employeeId: number | null
  activeEmployee: UserResponse | null
  status: StatusType
  isPutEmployee: boolean
  isEditEmployee: boolean
  statesEmployee: IStates
  isAddEmployee: boolean
  isModalAddEmployee: boolean
  isModalAddCompanyLeader: boolean
  isModalChangeRoleAddEmployee: boolean
  activeRoleEmployee: RoleModel | null
  isDeleteEmployee: boolean
  optionsEmployee: OptionsEmployee | null
}

const initialState: InitialState = {
  isModalAddCompanyLeader: false,
  employeeId: null,
  employees: [],
  searchEmployees: [],
  pageCount: 1,
  page: 1,
  perPage: COUNT_ITEMS_PER_PAGE,
  totalItems: 1,
  allEmployees: [],
  activeEmployee: null,
  status: 'init',
  isAddEmployee: false,
  activeRoleEmployee: null,
  isPutEmployee: false,
  isEditEmployee: false,
  isModalAddEmployee: false,
  isModalChangeRoleAddEmployee: false,
  isDeleteEmployee: false,
  statesEmployee: {
    statePutEmployee: null,
    stateCreateEmployee: null,
  },
  optionsEmployee: null,
}

export const employeesSlice = createSlice({
  name: 'employeesSlice',
  initialState,
  reducers: {
    setEmployeeId: (state, action: PayloadAction<number>) => {
      state.employeeId = action.payload
    },
    setOptionsEmployee: (state, action: PayloadAction<OptionsEmployee>) => {
      state.optionsEmployee = action.payload
    },
    setIsModalChangeRoleAddEmployee: (state, action: PayloadAction<boolean>) => {
      state.isModalChangeRoleAddEmployee = action.payload
    },
    setIsModalAddEmployee: (state, action: PayloadAction<boolean>) => {
      state.isModalAddEmployee = action.payload
    },
    setIsModalAddCompanyLeader: state => {
      state.isModalAddCompanyLeader = !state.isModalAddCompanyLeader
    },
    setIsAddEmployee: state => {
      state.isAddEmployee = !state.isAddEmployee
    },
    setIsEditEmployee: (state, action: PayloadAction<boolean>) => {
      state.isEditEmployee = action.payload
    },
    setIsPutEmployee: (state, action: PayloadAction<boolean>) => {
      state.isPutEmployee = action.payload
    },
    setStatesEmployee: (state, action: PayloadAction<IStates>) => {
      state.statesEmployee = action.payload
    },
    setEmployees: (state, action: PayloadAction<UserResponse[]>) => {
      state.employees = action.payload
    },
    setActiveEmployeeRole: (state, action: PayloadAction<RoleModel>) => {
      state.activeRoleEmployee = action.payload
    },
    employeesReset: state => {
      state.employees = []
      state.status = 'init'
    },
    setSearchEmployees: (state, action: PayloadAction<SearchEmployeeModel[]>) => {
      state.searchEmployees = action.payload
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload
    },
    setPerPage: (state, action: PayloadAction<number>) => {
      state.perPage = action.payload
    },
    setTotalItems: (state, action: PayloadAction<number>) => {
      state.totalItems = action.payload
    },
    setPageCount: (state, action: PayloadAction<number>) => {
      state.pageCount = action.payload
    },
    setAllEmployees: (state, action: PayloadAction<any[]>) => {
      state.allEmployees = action.payload
    },
    setActiveEmployee: (state, action: PayloadAction<any | null>) => {
      state.activeEmployee = action.payload
    },
    setStatus: (state, action: PayloadAction<StatusType>) => {
      state.status = action.payload
    },
  },
  extraReducers: {
    [fetchDeleteEmployeeInProject.fulfilled.type]: (state, action: PayloadAction<UserResponse>) => {
      state.status = 'success'
      state.isDeleteEmployee = true
    },
    [fetchDeleteEmployeeInProject.pending.type]: state => {
      state.status = 'loading'
    },
    [fetchDeleteEmployeeInProject.rejected.type]: (state, action: PayloadAction<string>) => {
      state.status = 'error'
    },

    [fetchPutWorker.fulfilled.type]: (state, action: PayloadAction<UserResponse>) => {
      state.status = 'success'
      state.activeEmployee = action.payload
    },
    [fetchPutWorker.pending.type]: state => {
      state.status = 'loading'
    },
    [fetchPutWorker.rejected.type]: (state, action: PayloadAction<string>) => {
      state.status = 'error'
    },

    [fetchPutProjectLeader.fulfilled.type]: (state, action: PayloadAction<UserResponse>) => {
      state.status = 'success'
      state.activeEmployee = action.payload
    },
    [fetchPutProjectLeader.pending.type]: state => {
      state.status = 'loading'
    },
    [fetchPutProjectLeader.rejected.type]: (state, action: PayloadAction<string>) => {
      state.status = 'error'
    },

    [fetchPutForeman.fulfilled.type]: (state, action: PayloadAction<UserResponse>) => {
      state.status = 'success'
      state.activeEmployee = action.payload
    },
    [fetchPutForeman.pending.type]: state => {
      state.status = 'loading'
    },
    [fetchPutForeman.rejected.type]: (state, action: PayloadAction<string>) => {
      state.status = 'error'
    },

    [fetchCreateForeman.fulfilled.type]: (state, action: PayloadAction<UserResponse>) => {
      state.status = 'success'
      state.activeEmployee = action.payload
    },
    [fetchCreateForeman.pending.type]: state => {
      state.status = 'loading'
    },
    [fetchCreateForeman.rejected.type]: (state, action: PayloadAction<string>) => {
      state.status = 'error'
    },

    [fetchCreateWorker.fulfilled.type]: (state, action: PayloadAction<UserResponse>) => {
      state.status = 'success'
    },
    [fetchCreateWorker.pending.type]: state => {
      state.status = 'loading'
    },
    [fetchCreateWorker.rejected.type]: (state, action: PayloadAction<string>) => {
      state.status = 'error'
    },
  },
})

export const { reducer: employeesReducer, actions: employeesActions } = employeesSlice
