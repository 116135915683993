import { memo, useCallback, useState } from 'react'
import { Tabs, Divider, Stack } from '@mui/material'
import { SwitchBlockShifts } from './components/SwitchBlockShifts'
import { TabPanelBasicInformation } from './components/TabPanelBasicInformation/TabPanelBasicInformation'
import { TabPanelDocuments } from './components/TabPanelDocuments/TabPanelDocuments'
import { TabStyled } from './styled/ProjectPassportTabs.styled'
import { useCheckClaims } from 'hooks/useCheckClaims'

export const ProjectPassportTabs = memo(() => {
  const [value, setValue] = useState(0)

  const { checkClaims } = useCheckClaims()

  const handleChange = useCallback((_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }, [])

  return (
    <>
      <Stack position={'relative'} mb={5.3} direction='row' justifyContent={'space-between'}>
        <Tabs
          value={value}
          onChange={handleChange}
          sx={{
            '.MuiTabs-flexContainer': {
              gap: 10,
            },
          }}
        >
          {checkClaims('Front.projectPassport.info') && <TabStyled label='Основная информация' />}
          {checkClaims('Front.projectPassport.documents') && <TabStyled label='Документы' />}
        </Tabs>
        {checkClaims('Front.projectPassport.blockShift') && <SwitchBlockShifts />}

        <Divider
          sx={{
            position: 'absolute',
            width: '200vw',
            left: '-100vw',
            top: '100%',
          }}
        />
      </Stack>
      <div role='tabpanel' hidden={value !== 0}>
        <TabPanelBasicInformation />
      </div>
      <div role='tabpanel' hidden={value !== 1}>
        <TabPanelDocuments />
      </div>
    </>
  )
})
