import { ChangeEvent, FC, useCallback } from 'react'
import { Stack, TextField } from '@mui/material'
import { debounce } from 'lodash'
import { toast } from 'react-toastify'
import { useActionCreatorsTyped, useAppSelector } from 'hooks/redux'
import { employeesActions } from 'store/reducers/EmployeesSlice'
import { fetchPutWorker } from 'service/WorkerThunks'
import { ShiftModel } from 'models/shift.model'
import { ShiftTypeCondition } from 'models/requests/shift-requests.model'
import { moonBlue, sunBlue } from 'assets/images'

interface IProps {
  shift: ShiftModel
}

export const StatisticsTableBodyChangeValueInput: FC<IProps> = props => {
  const { shift } = props
  const { id, dayTimeHourlyPayment, nightTimeHourlyPayment } = shift.employeeProject
  const type = useAppSelector(state => state.shiftsReducer.type)
  const actionsEmployees = { ...employeesActions, putWorker: fetchPutWorker }

  const actions = useActionCreatorsTyped(actionsEmployees)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChange = useCallback(
    debounce((e: ChangeEvent<HTMLInputElement>) => {
      const name = e.target.name
      const value = Number(e.target.value)

      if (value < 1) {
        toast.error('Введите числo бoльше либo равнo 1')
        return
      }

      actions.putWorker({
        id,
        params: {
          dayTimeHourlyPayment,
          nightTimeHourlyPayment,
          [name]: value,
        },
      })
    }, 500),
    [],
  )

  return (
    <Stack direction={'row'} alignItems='center' gap={1}>
      <img src={type === ShiftTypeCondition.Day ? sunBlue : moonBlue} alt='typeShift' />
      <TextField
        name={type === ShiftTypeCondition.Day ? 'dayTimeHourlyPayment' : 'nightTimeHourlyPayment'}
        onChange={handleChange}
        variant='standard'
        required
        type={'number'}
        defaultValue={
          type === ShiftTypeCondition.Day ? shift.employeeProject.dayTimeHourlyPayment : shift.employeeProject.nightTimeHourlyPayment
        }
        InputProps={{ disableUnderline: true }}
        sx={{
          '.MuiInput-root': {
            fontSize: 14,
            px: 0,
            m: 0,
          },
        }}
      />
    </Stack>
  )
}
