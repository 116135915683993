import { StatusType } from '../../models/common/common.model'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PositionResponse } from '../../models/responses/position.response'
import { fetchAllPositions } from '../../service/PositionThunks'

interface IState {
  positions: PositionResponse[]
  pageCount: number
  status: StatusType
}

const initialState: IState = {
  positions: [],
  pageCount: 1,
  status: 'init',
}

export const positionSlice = createSlice({
  name: 'positionSlice',
  initialState,
  reducers: {
    setStatus: (state, action: PayloadAction<StatusType>) => {
      state.status = action.payload
    },
    positionFetch: (state, action: PayloadAction<PositionResponse[]>) => {
      state.positions = action.payload
    },
    setPageCount: (state, action: PayloadAction<number>) => {
      state.pageCount = action.payload
    },
  },
  extraReducers: {
    [fetchAllPositions.fulfilled.type]: (state, action: PayloadAction<PositionResponse[]>) => {
      state.status = 'success'
      state.positions = action.payload
    },
    [fetchAllPositions.pending.type]: state => {
      state.status = 'loading'
    },
    [fetchAllPositions.rejected.type]: (state, action: PayloadAction<string>) => {
      state.status = 'error'
    },
  },
})

export const { reducer: positionReducer, actions: positionActions } = positionSlice
