import { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { CustomLayout } from './components/CustomLayout/CustomLayout'

import { LoginPage } from './pages/LoginPage/LoginPage'
import { PrivateRoute } from './routers/PrivateRouter'
import { UserProfile } from './pages/UserProfile/UserProfile'
import { ROLES_WORKER } from './helpers/roles'
import {
  ADMIN_AREA_PATH,
  LOGIN_PATH,
  PRIVACY_POLICY_PATH,
  PROJECT_PATH,
  REPORT_TABLE_PATH,
  USER_PROFILE_PATH,
  WORKER_PATH,
} from './helpers/routePaths'
import { usePermissions } from './hooks/usePermissions'
import { useDocumentTitle } from './hooks/useDocumentTitle'
import { ProjectPassport } from './pages/ProjectPassport/ProjectPassport'
import { WorkerCard } from './pages/WorkerCard/WorkerCard'
import { Statistics } from './pages/Statistics/Statistics'
import { MainPage } from './pages/MainPage/MainPage'
import { PrivacyPolicy } from 'pages/PrivacyPolicy/PrivacyPolicy'

export const App = () => {
  const location = useLocation()

  const { checkUserPermissions } = usePermissions()
  const setDocumentTitle = useDocumentTitle()

  useEffect(() => {
    setDocumentTitle()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  return (
    <Routes>
      <Route path={LOGIN_PATH} element={<LoginPage />} />
      <Route path={PRIVACY_POLICY_PATH} element={<PrivacyPolicy />} />
      <Route element={<PrivateRoute />}>
        <Route element={<CustomLayout />}>
          {!checkUserPermissions(ROLES_WORKER) && <Route path={ADMIN_AREA_PATH} element={<MainPage />} />}

          {/* <Route path={PRINT_QR_ALL_PEOPLE_PATH} element={<PrintAllQrPeple />} />
          <Route path={PRINT_QR_ALL_PEOPLE_PROJ_PATH} element={<PrintAllQrPeple />} /> */}
          <Route path={PROJECT_PATH} element={<ProjectPassport />} />
          <Route path={WORKER_PATH} element={<WorkerCard />} />
          <Route path={USER_PROFILE_PATH} element={<UserProfile />} />
          <Route path={REPORT_TABLE_PATH} element={<Statistics />} />
          <Route path='*' element={<h1>`Страница не найдена :(`</h1>} />
        </Route>
      </Route>
    </Routes>
  )
}
