import { MenuItem, TextFieldProps } from '@mui/material'

import { CustomSelect } from '../UI/CustomSelect/CustomSelect'
import { MainTextField } from '../UI/TextFields/MainTextField/MainTextField'
import { MultiValueInput } from '../UI/MultiValueInput/MultiValueInput'
import { NumberTextField } from 'components/UI/TextFields/NumberTextField/NumberTextField'
import { PatternTextField } from 'components/UI/TextFields/PatternTextField/PatternTextField'

interface Option {
  value: number | string
  text: string
}

export type Filter = TextFieldProps & {
  onChange: any
  inputType?: string
  mask?: string
  options?: Array<Option>
  multiple?: boolean
  format?: string
}

export const SortByTypeBasic = (filter: Partial<Filter>, state?: any, setState?: any, redux?: boolean) => {
  switch (filter.type) {
    case 'input':
      return (
        <MainTextField
          type={filter.inputType || 'text'}
          name={filter.name}
          value={filter.value}
          defaultValue={filter.defaultValue}
          label={filter.label}
          required={filter.required}
          onChange={filter.onChange}
          fullWidth
          multiline={filter.multiline}
          rows={filter.multiline ? 4 : undefined}
          placeholder={filter.placeholder}
        />
      )
    case 'number':
      return (
        <NumberTextField
          onChange={filter.onChange}
          name={filter.name}
          placeholder={filter.placeholder}
          value={filter.value}
          label={filter.label}
        />
      )
    case 'pattern':
      return (
        <PatternTextField
          name={filter.name}
          format={filter.format as string}
          placeholder={filter.placeholder}
          value={filter.value as string}
          label={filter.label}
          onChange={filter.onChange}
          mask={filter.mask}
          fullWidth
        />
      )
    case 'multiInput':
      return <MultiValueInput element={filter} state={state} setState={setState} redux />
    case 'select':
      return (
        <CustomSelect
          children={
            filter?.options &&
            filter.options.map((option: Option) => (
              <MenuItem key={option?.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))
          }
          value={filter.value}
          disabled={filter.disabled}
          label={filter.label}
          name={filter.name}
          onChange={filter.onChange}
          multiple={filter.multiple}
          fullWidth
        />
      )
    default:
      break
  }
}
