import { FC, memo, useCallback } from 'react'
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { personalCard, qr } from 'assets/images'
import { returnLocaleRole, ROLES_WORKER } from 'helpers/roles'
import { UserResponse } from 'models/responses/user.response'
import { REPORT_TABLE_PATH } from 'helpers/routePaths'
import { useAppDispatch } from 'hooks/redux'
import { workersActions } from 'store/reducers/WorkersSlice'
import { qrCodesActions } from 'store/reducers/QrCodeSlice'
import { getQrCodesByFilterService } from 'service/QrCodesService'
import { useCheckClaims } from 'hooks/useCheckClaims'
import { TypographyMainStyled } from 'components/UI/styled/TypographyMain'

interface Props {
  worker: UserResponse
}

export const EmployeeCardContentItem: FC<Props> = memo(({ worker }) => {
  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const { checkClaims } = useCheckClaims()

  const getQr = useCallback((worker: UserResponse) => {
    dispatch(getQrCodesByFilterService({ employeeProjectIds: [worker.employee.employeeProjects[0].id] })).then(() => {
      dispatch(workersActions.setWorkerId(worker.id))
      dispatch(qrCodesActions.setIsModalQrPrint(true))
    })
  }, [])

  const navigateToStatistics = useCallback(() => {
    if (worker.role.name !== ROLES_WORKER) return
    navigate(`/${REPORT_TABLE_PATH}?orgId=${worker.employee.organization?.id}&employeeId=${worker.employee.employeeProjects[0].id}`)
  }, [])

  const navigateToWorkerCard = (worker: UserResponse) => {
    navigate(`${worker.employee.organization.id}/project/${worker.employee.employeeProjects[0].project.id}/employee/${worker.employee.id}`)
  }

  return (
    <Box>
      <Stack direction='row' alignItems='center' gap={2}>
        <TypographyMainStyled isHover={worker.role.name === ROLES_WORKER} onClick={() => navigateToStatistics()}>
          {worker.surname} {worker.name} {worker.patronymic}
        </TypographyMainStyled>
        <Stack direction='row' gap={1}>
          {checkClaims('Front.employeeCard.item.showCardWorker') && (
            <Tooltip title='Карточка сотрудника'>
              <IconButton sx={{ p: 0 }} onClick={() => navigateToWorkerCard(worker)}>
                <img src={personalCard} alt={personalCard} />
              </IconButton>
            </Tooltip>
          )}
          {checkClaims('Front.employeeCard.item.showQrCode') && worker.role.name === ROLES_WORKER && (
            <Tooltip title='QR-code'>
              <IconButton sx={{ p: 0 }} onClick={() => getQr(worker)}>
                <img src={qr} alt={qr} />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      </Stack>
      <Stack direction='row' gap={3}>
        <Typography color='rgba(55, 68, 90, 0.7)'>
          {worker.role.name === ROLES_WORKER ? worker.employee.employeeProjects[0].position.name : returnLocaleRole(worker.role.name)}
        </Typography>
      </Stack>
    </Box>
  )
})
