import { memo, useMemo, useEffect, useCallback } from 'react'
import { toast } from 'react-toastify'
import { Button, Stack } from '@mui/material'

import { Validation } from 'helpers/validation'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { ProjectStatus } from 'models/requests/project-requests.model'
import { fetchPatchProjectStatus, fetchPutProject } from 'service/Projects'
import { projectActions } from 'store/reducers/ProjectSlice'
import { useCheckClaims } from 'hooks/useCheckClaims'

export const ProjectPassportHeaderActions = memo(() => {
  const activeProject = useAppSelector(state => state.projectReducer.activeProject)
  const isEditProject = useAppSelector(state => state.projectReducer.isEditProject)
  const projectInfo = useAppSelector(state => state.projectReducer.projectInfo)

  const { checkClaims } = useCheckClaims()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(projectActions.setIsEditProject(false))
  }, [])

  const handleChangeStatus = (status: ProjectStatus) => {
    dispatch(fetchPatchProjectStatus({ id: Number(activeProject?.id), projectStatus: status }))
  }

  const handleSave = useCallback(() => {
    if (!projectInfo) return

    if (!Validation.text(projectInfo.name)) {
      toast.error('Не валидное имя проекта')
      return
    }

    dispatch(fetchPutProject(activeProject.id, projectInfo))
    dispatch(projectActions.setIsEditProject(false))
  }, [projectInfo, activeProject])

  const statusesActions = useMemo(
    () => [
      !isEditProject && {
        status: ProjectStatus.Closed,
        name: 'редактировать',
        onClick: () => dispatch(projectActions.setIsEditProject(true)),
      },
      isEditProject &&
        checkClaims('Front.projectPassport.edit') && {
          name: 'сохранить',
          onClick: () => handleSave(),
        },
      !isEditProject && {
        status: ProjectStatus.Open,
        name: 'открыть',
        onClick: () => handleChangeStatus(ProjectStatus.Open),
      },
      !isEditProject && {
        status: ProjectStatus.Suspended,
        name: 'приостановить',
        onClick: () => handleChangeStatus(ProjectStatus.Suspended),
      },
      !isEditProject && {
        status: ProjectStatus.Closed,
        name: 'закрыть',
        onClick: () => handleChangeStatus(ProjectStatus.Closed),
      },
    ],
    [isEditProject, handleSave, handleChangeStatus],
  )

  return (
    <Stack direction={'row'} gap={2.5}>
      {statusesActions.map(
        statusAction =>
          statusAction && (
            <Button
              onClick={statusAction.onClick}
              disabled={activeProject.projectStatus === statusAction.status}
              key={statusAction.name}
              variant='contained'
            >
              {statusAction.name}
            </Button>
          ),
      )}
    </Stack>
  )
})
