import { qrAuthPostQrCodeFilterWithRoleWorker } from '../api/utils/employeeRole/workerApi'
import { ShiftRequestsBodyModel } from '../models/requests/shift-requests.model'
import { AppDispatch } from '../store/store'
import { qrCodesActions } from '../store/reducers/QrCodeSlice'

// Получить qr коды по фильтру
export const getQrCodesByFilterService = (params: ShiftRequestsBodyModel.postFilterQrCode) => async (dispatch: AppDispatch) => {
  try {
    const response = await qrAuthPostQrCodeFilterWithRoleWorker(params)
    dispatch(qrCodesActions.qrCodesFetch(response.data.items))
    dispatch(qrCodesActions.setStatus('loading'))
    return Promise.resolve(response)
  } catch (err) {
    dispatch(qrCodesActions.setStatus('error'))
    dispatch(qrCodesActions.qrCodesFetch([]))
    return Promise.reject(err)
  } finally {
    dispatch(qrCodesActions.setStatus('success'))
  }
}
