import { FC } from 'react'
import { FilledInputProps, TextField, TextFieldProps, styled } from '@mui/material'

export const MyStyledTextField = styled(TextField)(({ theme }) => ({
  height: '100%',
  minWidth: 227,
  '.MuiFilledInput-root': {
    border: `1px solid ${theme.palette.text.secondary}`,
    overflow: 'hidden',
    borderRadius: '4px',
    background: '#ffffff',
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
    '&:hover': {
      background: '#ffffff',
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent',
      borderColor: theme.palette.primary.main,
    },
    '&::after,&::before': {
      display: 'none',
    },
  },
  '.MuiInputBase-root.MuiFilledInput-root.Mui-disabled': {
    backgroundColor: 'none',
  },
}))

export const MainTextField: FC<TextFieldProps> = ({ children, variant, multiline, rows, ...props }) => {
  return (
    <MyStyledTextField
      variant={variant || 'filled'}
      InputLabelProps={{ shrink: props.type === 'time' || props.type === 'date' ? true : undefined }}
      InputProps={
        {
          multiline: multiline,
          maxRows: rows,
          disableUnderline: true,
        } as Partial<FilledInputProps>
      }
      {...props}
    >
      {children}
    </MyStyledTextField>
  )
}
