import { qrAuthGetProjectByEmployee, qrAuthPatchProjectStatus } from './../api/utils/projectApi'
import { qrAuthGetSearchProjects } from './../api/utils/searchApi'
import {
  qrAuthDeleteProject,
  qrAuthGetProjectById,
  qrAuthGetProjectsByFilter,
  qrAuthPostProject,
  qrAuthPutProject,
} from '../api/utils/projectApi'
import { AppDispatch } from '../store/store'
import { ProjectRequestsBodyModel, ProjectRequestsQueryModel } from '../models/requests/project-requests.model'
import { SearchRequestsBodyModel, SearchRequestsQueryModel } from '../models/requests/search-requests.model'
import { projectActions } from '../store/reducers/ProjectSlice'

export const fetchProjectFilter =
  (query: ProjectRequestsQueryModel.postFilter = {}, params: ProjectRequestsBodyModel.postFilter = {}) =>
  async (dispatch: AppDispatch) => {
    try {
      const response = await qrAuthGetProjectsByFilter(params, query)
      dispatch(projectActions.projectDataFetch(response.data.items))
      dispatch(projectActions.setPage(response.data?.paging?.page || 1))
      dispatch(projectActions.setPageCount(response.data?.paging?.lastPage || 1))
      dispatch(projectActions.setTotalItems(response.data?.paging?.totalItems || 1))
      dispatch(projectActions.setStatus('loading'))
    } catch (err) {
      dispatch(projectActions.setStatus('error'))
      console.log(err)
    } finally {
      dispatch(projectActions.setStatus('success'))
    }
  }

export const fetchProjectByEmployee = (id: number) => async (dispatch: AppDispatch) => {
  try {
    const response = await qrAuthGetProjectByEmployee(id)

    dispatch(projectActions.projectDataFetch(response.data.items))
    dispatch(projectActions.setTotalItems(response.data?.paging?.totalItems || 1))
    dispatch(projectActions.setStatus('loading'))
  } catch (err) {
    console.log(err)
    dispatch(projectActions.setStatus('error'))
  } finally {
    dispatch(projectActions.setStatus('success'))
  }
}

export const getSearchProjects =
  (query: SearchRequestsQueryModel.postProject = {}, params: SearchRequestsBodyModel.postProject) =>
  async (dispatch: AppDispatch) => {
    try {
      const response = await qrAuthGetSearchProjects(query, params)

      dispatch(projectActions.setSearchProject(response.data.items))
      dispatch(projectActions.setPerPage(response.data?.paging?.lastPage || 1))

      dispatch(projectActions.setStatus('loading'))
      return Promise.resolve(response)
    } catch (err: any) {
      dispatch(projectActions.setStatus('error'))
      return Promise.reject(err)
    } finally {
      dispatch(projectActions.setStatus('success'))
    }
  }

export const fetchProjectById = (id: number) => async (dispatch: AppDispatch) => {
  try {
    const response = await qrAuthGetProjectById(id)

    dispatch(projectActions.setStatus('loading'))

    dispatch(projectActions.setActiveProject(response.data.items))
    dispatch(projectActions.setProjectInfo({ ...response.data.items.projectInfo, name: response.data.items.name }))
  } catch (err) {
    console.log(err)
    dispatch(projectActions.setStatus('error'))
  } finally {
    dispatch(projectActions.setStatus('success'))
  }
}

export const fetchAddProject = (params: ProjectRequestsBodyModel.post) => async (dispatch: AppDispatch) => {
  try {
    const response = await qrAuthPostProject(params)

    return Promise.resolve(response)
  } catch (err) {
    console.log(err)

    return Promise.reject(err)
  }
}

export const fetchDeleteProject = (id: number) => async (dispatch: AppDispatch) => {
  try {
    await qrAuthDeleteProject(id)
  } catch (err) {
    console.log(err)
  }
}

export const fetchPutProject = (id: number, params: ProjectRequestsBodyModel.put) => async (dispatch: AppDispatch) => {
  try {
    const response = await qrAuthPutProject(id, params)

    dispatch(fetchProjectById(id))
    return Promise.resolve(response)
  } catch (err) {
    console.log(err)

    return Promise.reject(err)
  }
}

export const fetchPatchProjectStatus = (params: ProjectRequestsQueryModel.patchStatus) => async (dispatch: AppDispatch) => {
  try {
    const response = await qrAuthPatchProjectStatus(params)

    dispatch(fetchProjectById(params.id))

    return Promise.resolve(response)
  } catch (err) {
    console.log(err)

    return Promise.reject(err)
  }
}
