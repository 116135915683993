import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

import { Header } from './Header/Header'
import { Box, CssBaseline } from '@mui/material'
import { useAppDispatch } from 'hooks/redux'
import { fetchAllPositions } from 'service/PositionThunks'
import { useCheckClaims } from 'hooks/useCheckClaims'
import { fetchGetRoles } from 'service/RoleThunks'

export const CustomLayout = () => {
  const dispatch = useAppDispatch()
  const { checkClaims } = useCheckClaims()

  useEffect(() => {
    checkClaims('Back.position.PostByFilter') && dispatch(fetchAllPositions({}))
    checkClaims('Back.role.PostByFilter') && dispatch(fetchGetRoles({}))
  }, [])

  return (
    <>
      <CssBaseline />
      <Header />
      <Box
        component={'main'}
        sx={{
          flex: '1 1 auto',
          maxWidth: 1575,
          width: '100%',
          margin: '0 auto',
          marginY: 3,
        }}
      >
        <Outlet />
      </Box>
    </>
  )
}
