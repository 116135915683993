import { useEffect, useState } from 'react'
import { AsyncThunk } from '@reduxjs/toolkit'

import { fetchCreateWorker } from 'service/WorkerThunks'
import { fetchCreateForeman, fetchPutForeman } from 'service/ForemanThunks'
import { ROLES_COMPANY_LEADER, ROLES_FOREMAN, ROLES_PROJECT_LEADER, ROLES_WORKER } from 'helpers/roles'
import { fetchPutWorker } from 'service/WorkerThunks'
import { UserResponse } from 'models/responses/user.response'
import { fetchCreateCompanyLeader } from 'service/CompanyLeaderThunks'
import { useAppSelector } from 'hooks/redux'
import { fetchCreateProjectLeader, fetchPutProjectLeader } from 'service/ProjectLeaderThunks'

interface Fetches {
  putEmployee: AsyncThunk<
    UserResponse,
    {
      id: number
      params: any
    },
    any
  > | null
  createEmployee: AsyncThunk<UserResponse, any, any> | null
}

export const useEmployeeFetches = () => {
  const currentRole = useAppSelector(state => state.rolesReducer.currentRole)

  const [fetches, setFetches] = useState<Fetches>({
    putEmployee: null,
    createEmployee: null,
  })

  useEffect(() => {
    if (!currentRole) return

    switch (currentRole.name) {
      case ROLES_FOREMAN:
        setFetches({ putEmployee: fetchPutForeman, createEmployee: fetchCreateForeman })
        break
      case ROLES_WORKER:
        setFetches({ putEmployee: fetchPutWorker, createEmployee: fetchCreateWorker })
        break
      case ROLES_COMPANY_LEADER:
        setFetches({ putEmployee: null, createEmployee: fetchCreateCompanyLeader })
        break
      case ROLES_PROJECT_LEADER:
        setFetches({ putEmployee: fetchPutProjectLeader, createEmployee: fetchCreateProjectLeader })
        break
    }
  }, [currentRole])

  return fetches
}
