import { memo } from 'react'
import { useAppSelector } from 'hooks/redux'
import { AddPeopleChangeRoleModal } from './AddPeopleModal/AddPeopleChangeRoleModal'
import { AddPeopleModal } from './AddPeopleModal/AddPeopleModal'
import { OrganizationsModal } from './OrganizationModal'
import { AddProjectModal } from './AddProjectModal/AddProjectModal'
import { QrPrintModal } from './QrPrintModal/QrPrintModal'
import { ReportModal } from './ReportModal/ReportModal'

export const Modals = memo(() => {
  const isModalQrPrint = useAppSelector(state => state.qrCodesReducer.isModalQrPrint)
  const isModalAddEmployee = useAppSelector(state => state.employeesReducer.isModalAddEmployee)
  const isModalChangeRoleAddEmployee = useAppSelector(state => state.employeesReducer.isModalChangeRoleAddEmployee)
  const isModalAddCompany = useAppSelector(state => state.organizationReducer.isModalAddCompany)
  const isModalOpen = useAppSelector(state => state.projectReducer.isModalOpen)
  const isModalDownloadReport = useAppSelector(state => state.projectReducer.isModalDownloadReport)

  return (
    <>
      {isModalQrPrint && <QrPrintModal />}
      {isModalAddEmployee && <AddPeopleModal />}
      {isModalChangeRoleAddEmployee && <AddPeopleChangeRoleModal />}
      {isModalAddCompany && <OrganizationsModal />}
      {isModalDownloadReport && <ReportModal />}
      {isModalOpen && <AddProjectModal />}
    </>
  )
})
