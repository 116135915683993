import { ROLES_WORKER } from './../helpers/roles'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { WorkerRequestsBodyModel } from '../models/requests/employee-role-requests/worker-requests.model'
import { qrAuthPostCreateWithRoleWorker, qrAuthPutUpdateWithRoleWorker } from '../api/utils/employeeRole/workerApi'
import { AxiosError } from 'axios'

export const fetchPutWorker = createAsyncThunk(
  `${ROLES_WORKER}/fetchPutWorker`,
  async (data: { id: number; params: WorkerRequestsBodyModel.put }, { rejectWithValue }) => {
    const { id, params } = data
    try {
      const response = await qrAuthPutUpdateWithRoleWorker(id, params)
      return response.data.items
    } catch (error: any) {
      const err: AxiosError = error
      return rejectWithValue(err.message)
    }
  },
)

export const fetchCreateWorker = createAsyncThunk(
  `${ROLES_WORKER}/fetchCreateWorker`,
  async (params: WorkerRequestsBodyModel.postCreate, { rejectWithValue, dispatch }) => {
    try {
      const response = await qrAuthPostCreateWithRoleWorker(params)
      return response.data.items
    } catch (error: any) {
      const err: AxiosError = error
      return rejectWithValue(err.message)
    }
  },
)
