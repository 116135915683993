import { StatusType } from '../../models/common/common.model'
import { PaginationModel } from './../../models/pagination.model'
import { ProjectFile } from './../../models/project.model'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface InitialState {
  items: ProjectFile[] | null
  paging: PaginationModel | null
  isFetchProjectFiles: boolean
  status: StatusType
}

const initialState: InitialState = {
  items: null,
  paging: {} as PaginationModel,
  isFetchProjectFiles: false,
  status: 'init',
}
export const projectFilesSlice = createSlice({
  name: 'projectFilesSlice',
  initialState,
  reducers: {
    projectFilesFilterFetch: (state, action) => {
      state.items = action.payload.items
      state.paging = action.payload.paging
    },
    setIsFetchProjectFiles: (state, action: PayloadAction<boolean>) => {
      state.isFetchProjectFiles = action.payload
    },
    setStatus: (state, action: PayloadAction<StatusType>) => {
      state.status = action.payload
    },
  },
})
export const { reducer: projectFilesReducer, actions: projectFilesActions } = projectFilesSlice
