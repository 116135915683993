import { combineReducers, configureStore } from '@reduxjs/toolkit'

import { organizationReducer } from './reducers/OrganizationSlice'
import { shiftsReducer } from './reducers/ShiftsSlice'
import { projectReducer } from './reducers/ProjectSlice'
import { workersReducer } from './reducers/WorkersSlice'
import { rolesReducer } from './reducers/RolesSlice'
import { positionReducer } from './reducers/PositionSlice'
import { employeesReducer } from './reducers/EmployeesSlice'
import { currentUserReducer } from './reducers/CurrentUserSlice'
import { statusReducer } from './reducers/StatusSlice'
import { qrCodesReducer } from './reducers/QrCodeSlice'
import { projectFilesReducer } from './reducers/ProjectFilesSlice'
import { periodicPaymentReducer } from './reducers/PeriodicPaymentSlice'
import { filtersReducer } from './reducers/FiltersSlice'
import { tabsReducer } from './reducers/TabsSlice'
import { themeReducer } from './reducers/ThemeSlice'

const rootReducer = combineReducers({
  periodicPaymentReducer,
  projectFilesReducer,
  tabsReducer,
  filtersReducer,
  organizationReducer,
  shiftsReducer,
  projectReducer,
  workersReducer,
  rolesReducer,
  themeReducer,
  positionReducer,
  employeesReducer,
  currentUserReducer,
  statusReducer,
  qrCodesReducer,
})

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware(),
  })
}

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']
