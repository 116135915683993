import { SearchRequestQueryEmployeeModel } from './search-requests.model'

export enum ShiftTypeCondition {
  Day,
  Night,
  All,
}

export interface ShiftsGetByFilterBodyRequest {
  employeeProjectIds?: number[]
  projectIds?: number[]
  organizationIds?: number[]
  statusIds?: number[]
  period: {
    start: string
    end: string
  }
  completeness?: number
  type?: number
  originType?: number
}

export interface IShiftsReportDownloadRequest {
  employeeProjectIds?: number[]
  projectIds?: number[]
  organizationIds?: number[]
  statusIds?: number[]
  period: {
    start: string
    end: string
  }
  completeness?: number
  type?: number
  originType?: number
}

interface Shift {
  employeeProjectId?: number
  shiftId?: number
  statusId: number
  startDate: string
  finishDate?: string | null
}

export interface ShiftsPostBatchBodyRequest {
  type: number
  shift: Shift
}

export interface IShiftsDownloadQrCodeByFilter {
  positionIds?: number[]
  projectIds?: number[]
  statusIds?: number[]
  employeeProjectIds?: number[]
  workingMethods?: string[]
}

export namespace ShiftRequestsBodyModel {
  export type postBatch = ShiftsPostBatchBodyRequest
  export type postFilter = ShiftsGetByFilterBodyRequest
  export type downloadReport = IShiftsReportDownloadRequest
  export type postFilterQrCode = IShiftsDownloadQrCodeByFilter
}

export namespace ShiftRequestsQueryModel {
  export type postFilter = SearchRequestQueryEmployeeModel
}
