import { AxiosError } from 'axios'
import { qrAuthGetPeriodicPayments, qrAuthPostAddPeriodicPayments } from './../api/utils/periodicPaymentApi'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { PeriodicPaymentRequestsBodyModel, PeriodicPaymentRequestsQueryModel } from '../models/requests/periodic-payment-requests.model'

export const fetchAddPeriodicPayment = createAsyncThunk(
  `periodicPayment/fetchAddPeriodicPayment`,
  async (data: { id: number; params: PeriodicPaymentRequestsBodyModel.post }, { rejectWithValue, dispatch }) => {
    const { id, params } = data
    try {
      const response = await qrAuthPostAddPeriodicPayments(id, params)
      return response.data.items
    } catch (error: any) {
      const err: AxiosError = error
      return rejectWithValue(err.message)
    }
  },
)

export const fetchGetPeriodicPayment = createAsyncThunk(
  `periodicPayment/fetchGetPeriodicPayment`,
  async (
    data: { query?: PeriodicPaymentRequestsQueryModel.get; params: PeriodicPaymentRequestsBodyModel.postFilter },
    { rejectWithValue, dispatch },
  ) => {
    const { query, params } = data
    try {
      const response = await qrAuthGetPeriodicPayments(query, params)
      return response.data.items
    } catch (error: any) {
      const err: AxiosError = error
      return rejectWithValue(err.message)
    }
  },
)
