import { FC } from 'react'
import { NumericFormat } from 'react-number-format'
import { TextFieldProps } from '@mui/material'

import { MyStyledTextField } from '../MainTextField/MainTextField'

export const NumberTextField: FC<TextFieldProps> = props => {
  return (
    <MyStyledTextField
      {...props}
      fullWidth
      variant='filled'
      InputProps={{
        inputComponent: NumericFormat as any,
      }}
    />
  )
}
