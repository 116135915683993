import { memo, useEffect, useMemo, useCallback } from 'react'
import { Typography, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import { toast } from 'react-toastify'
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/redux'
import { deleteProjectFile, deleteAllProjectFiles, postProjectFilesFilter } from '../../../../../../../service/ProjectFile'
import { useParams } from 'react-router-dom'
import { projectFilesActions } from '../../../../../../../store/reducers/ProjectFilesSlice'
import { CustomLoader } from '../../../../../../../components/UI/CustomLoader/CustomLoader'
import { TabPanelSwitchAssignmentFileType } from '../helpers/TabPanelSwitchAssignmentFileType'
import { MyStyledTableCell } from '../styled/TabPanelDocuments.styled'
import { useCheckClaims } from 'hooks/useCheckClaims'

export const TabPanelDocumentsTable = memo(() => {
  const params = useParams()
  const projectId = Number(params.projectId)

  const projectFilesItems = useAppSelector(state => state.projectFilesReducer.items)
  const isFetchProjectFiles = useAppSelector(state => state.projectFilesReducer.isFetchProjectFiles)
  const status = useAppSelector(state => state.projectFilesReducer.status)

  const { checkClaims } = useCheckClaims()

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(postProjectFilesFilter({ projectIds: [projectId] }, {}))
  }, [isFetchProjectFiles])

  const handleDeleteFile = useCallback(
    (id: number) => {
      dispatch(deleteProjectFile(id)).then(() => {
        toast.success('Файл удален', {
          autoClose: 1000,
        })
        dispatch(projectFilesActions.setIsFetchProjectFiles(!isFetchProjectFiles))
      })
    },
    [isFetchProjectFiles],
  )

  const handleDeleteFiles = useCallback(() => {
    if (!projectFilesItems) return
    dispatch(deleteAllProjectFiles(projectId)).then(() => {
      toast.success('Файлы удалены', {
        autoClose: 1000,
      })
      dispatch(projectFilesActions.setIsFetchProjectFiles(!isFetchProjectFiles))
    })
  }, [projectFilesItems])

  const headRowCells = useMemo(
    () => [
      {
        name: 'Наименование документа',
      },
      { name: 'Тип документа' },
      { name: 'Действия' },
    ],
    [],
  )

  if (status === 'init' || status === 'loading') return <CustomLoader />

  return (
    <>
      {projectFilesItems && projectFilesItems?.length ? (
        <TableContainer>
          <Table sx={{ minWidth: 800 }}>
            <TableHead>
              <TableRow>
                {headRowCells.map(item => (
                  <MyStyledTableCell key={item.name}>
                    <Typography fontSize={18} fontWeight={700} color='text.primary'>
                      {item.name}
                    </Typography>
                  </MyStyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {projectFilesItems &&
                projectFilesItems.map(item => (
                  <TableRow key={item.id} sx={{ border: 0 }}>
                    <MyStyledTableCell>
                      <Typography fontSize={18} color='text.primary' sx={{ wordWrap: 'break-word' }}>
                        {item.fileName}
                      </Typography>
                    </MyStyledTableCell>
                    <MyStyledTableCell>
                      <Typography fontSize={18} color='text.primary'>
                        {TabPanelSwitchAssignmentFileType(item.assignment)}
                      </Typography>
                    </MyStyledTableCell>
                    {checkClaims('Front.projectPassport.documents.deleteDocument') && (
                      <MyStyledTableCell>
                        <Typography
                          onClick={() => handleDeleteFile(item.id)}
                          display={'inline'}
                          sx={{ cursor: 'pointer' }}
                          fontSize={18}
                          color='text.primary'
                          borderBottom={'1px solid #37445A'}
                        >
                          Удалить
                        </Typography>
                      </MyStyledTableCell>
                    )}
                  </TableRow>
                ))}
              {checkClaims('Front.projectPassport.documents.allDeleteDocuments') && (
                <TableRow sx={{ border: 'none' }}>
                  <MyStyledTableCell></MyStyledTableCell>
                  <MyStyledTableCell></MyStyledTableCell>
                  <MyStyledTableCell>
                    <Typography
                      display={'inline'}
                      onClick={handleDeleteFiles}
                      sx={{ cursor: 'pointer' }}
                      fontSize={18}
                      color='text.primary'
                      borderBottom={'1px solid #37445A'}
                    >
                      Удалить всё
                    </Typography>
                  </MyStyledTableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant='h5'>Нет документов. Загрузите документ.</Typography>
      )}
    </>
  )
})
