import { styled } from '@mui/material'
import { CustomButton } from '../Button/CustomButton'

export const ButtonShowMore = styled(CustomButton)(() => ({
  fontSize: 16,
  borderRadius: 4,
  width: 174,
  minHeight: 42,
  textTransform: 'none',
  p: 0,
}))
