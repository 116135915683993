import { memo, useCallback } from 'react'
import { Typography } from '@mui/material'

import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { organizationActions } from 'store/reducers/OrganizationSlice'
import { OrganizationCardContentItem } from './OrganizationCardContentItem/OrganizationCardContentItem'
import { ButtonShowMorePagination } from 'features/MainPage/components/ButtonShowMorePagination/ButtonShowMorePagination'

export const OrganizationCardListOrg = memo(() => {
  const dispatch = useAppDispatch()

  const organization = useAppSelector(state => state.organizationReducer.organizations)
  const totalItemsOrg = useAppSelector(state => state.organizationReducer.totalItemsOrg)
  const perPageOrg = useAppSelector(state => state.organizationReducer.perPageOrg)

  const handleShowMore = useCallback(() => dispatch(organizationActions.setPerPageOrg(perPageOrg * 2)), [perPageOrg])

  return organization && organization.length ? (
    <>
      {organization.map((org, idx) => (
        <OrganizationCardContentItem key={idx} {...{ org }} />
      ))}
      {perPageOrg < totalItemsOrg && <ButtonShowMorePagination handleShowMore={handleShowMore} />}
    </>
  ) : (
    <Typography>Нет организаций</Typography>
  )
})
