import { memo } from 'react'
import { Box } from '@mui/material'

import { SortByTypeBasic } from 'components/SortByTypeBasic/SortByTypeBasic'
import { useAppSelector } from 'hooks/redux'
import { usePutEmployeeElements } from 'hooks/employeeHooks/employeeElements/usePutEmployeeElements'
import { useEmployeePutState } from 'hooks/employeeHooks/employeeState/useEmployeePutState'
import { BoxEdited } from 'components/UI/BoxEdited'

export const WorkerCardInfo = memo(() => {
  const isEditEmployee = useAppSelector(state => state.employeesReducer.isEditEmployee)

  useEmployeePutState()

  const elements = usePutEmployeeElements()

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 340px)',
        columnGap: 8.8,
        rowGap: 4,
      }}
    >
      {elements && elements.length
        ? elements.map(filter => {
            return (
              <BoxEdited isEdited={isEditEmployee} key={filter.name}>
                {SortByTypeBasic(filter)}
              </BoxEdited>
            )
          })
        : null}
    </Box>
  )
})
