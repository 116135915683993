import { memo } from 'react'
import { Stack, Typography } from '@mui/material'

import { CustomModal } from 'components/UI/CustomModal/CustomModal'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { PeriodicPayment } from 'models/requests/periodic-payment-requests.model'
import { periodicPaymentActions } from 'store/reducers/PeriodicPaymentSlice'
import { StatisticsButton } from '../../styled/StatisticsButton'
import { ReportTime } from 'helpers/timeHelpers'
import { CustomLoader } from 'components/UI/CustomLoader/CustomLoader'

export const StatisticsViewListAmountOfFinesModal = memo(() => {
  const isViewPeriodicPaymentModal = useAppSelector(state => state.periodicPaymentReducer.isViewPeriodicPaymentModal)
  const periodicPaymentItems = useAppSelector(state => state.periodicPaymentReducer.periodicPaymentItems)
  const periodicPaymentType = useAppSelector(state => state.periodicPaymentReducer.periodicPaymentType)
  const status = useAppSelector(state => state.periodicPaymentReducer.status)

  const dispatch = useAppDispatch()

  const onClose = () => dispatch(periodicPaymentActions.setIsViewPeriodicPaymentModal(false))

  const renderContent = () => {
    if (status === 'init' || status === 'loading') return <CustomLoader />
    return (
      <Stack mb={3.5} gap={3} sx={{ height: 200, overflowY: 'auto' }}>
        {periodicPaymentItems ? (
          periodicPaymentItems?.map(item => (
            <Stack>
              <Stack direction={'row'} justifyContent='space-between'>
                <Typography color='text.primary' fontSize={18} fontWeight={700}>
                  {item.amount} ₽
                </Typography>
                <Typography color='#A4ACBC'>{ReportTime.getDotFormatDate(item.addedAt)}</Typography>
              </Stack>
              <Typography color='rgba(55, 68, 90, 0.8)' fontSize={14}>
                {item.comment}
              </Typography>
            </Stack>
          ))
        ) : (
          <Typography textAlign={'center'} fontSize={32}>
            Нет данных
          </Typography>
        )}
      </Stack>
    )
  }

  return (
    <CustomModal
      sx={{ maxWidth: 400, padding: 4 }}
      titleModal={PeriodicPayment.SupervisorsFine === periodicPaymentType ? 'Сумма штрафов' : 'Сумма компенсаций'}
      open={isViewPeriodicPaymentModal}
      onClose={onClose}
    >
      <Stack>
        {renderContent()}
        <StatisticsButton onClick={onClose} variant='outlined' sx={{ alignSelf: 'flex-end' }}>
          Закрыть
        </StatisticsButton>
      </Stack>
    </CustomModal>
  )
})
