import { Box, styled, TableCell } from '@mui/material'

import { moon, sun } from 'assets/images'

export const TableHeadCellStyled = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#446FDD',
  fontWeight: 400,
  color: theme.palette.background.paper,
  textAlign: 'center',
  fontSize: 12,
  lineHeight: 16 / 12,
  padding: '8px 16px',
  height: 50,
}))

export const BoxHeadCalendarStyled = styled(Box)<{ typecondition?: string }>(({ typecondition }) => ({
  '&.MuiBox-root': {
    position: 'relative',
    width: 50,
    height: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&::before': {
      content: '""',
      display: 'block',
      backgroundImage: `url(${typecondition ? moon : sun})`,
      width: 12,
      height: 12,
      position: 'absolute',
      right: 5,
      top: 0,
    },
  },
}))

export const BoxBodyCalendarStyled = styled(Box)(({ theme }) => ({
  width: 50,
  fontWeight: 700,
  height: '100%',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const TableBodyCellStyled = styled(TableHeadCellStyled)(({ theme }) => ({
  color: '#606060',
  fontSize: 14,
  padding: '0 4px',
  borderRight: '1px solid #E8E7E7',
  backgroundColor: theme.palette.background.paper,
}))
