import { memo, useState, useCallback } from 'react'
import { Box, Card, IconButton, Stack, Tooltip, Typography } from '@mui/material'

import { plus } from 'assets/images'
import { useActionCreatorsTyped, useAppSelector } from 'hooks/redux'
import { projectActions } from 'store/reducers/ProjectSlice'
import { ProjectCardContentItem } from './components/ProjectCardContentItem'
import { useCheckClaims } from 'hooks/useCheckClaims'
import { ButtonShowMorePagination } from 'features/MainPage/components/ButtonShowMorePagination/ButtonShowMorePagination'

export const ProjectCardContent = memo(() => {
  const currentOrganization = useAppSelector(state => state.organizationReducer.currentOrganization)
  const stateModal = useAppSelector(state => state.projectReducer.stateModal)
  const perPage = useAppSelector(state => state.projectReducer.perPage)
  const totalItems = useAppSelector(state => state.projectReducer.totalItems)
  const projects = useAppSelector(state => state.projectReducer.projects)

  const { checkClaims } = useCheckClaims()

  const [initialState] = useState(stateModal)

  const actions = useActionCreatorsTyped(projectActions)

  const addProject = useCallback(() => {
    actions.setIsModalOpen(true)
    actions.setStateModal(initialState)
  }, [])

  const handleShowMore = useCallback(() => actions.setPerPage(perPage * 2), [perPage])

  return (
    <Box>
      <Stack mb={2.5} alignItems='center' direction={'row'} gap={1.25}>
        <Typography fontSize={32} color='text.primary'>
          Проекты
        </Typography>
        {checkClaims('Front.projectCard.addProject') && (
          <Tooltip title='Добавить проект'>
            <IconButton sx={{ p: 0 }} onClick={addProject}>
              <img src={plus} alt={plus} />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
      <Card>
        <Stack mb={2.6} direction='row'>
          <Typography fontWeight={600} fontSize={16}>
            Организация: {currentOrganization?.name}
          </Typography>
        </Stack>
        {/* <CustomInput placeholder='Поиск проектов' sx={{ mb: 3, maxWidth: 300 }} /> */}
        <Stack
          gap={2.5}
          sx={{
            overflowY: 'auto',
            maxHeight: 650,
          }}
        >
          {projects && projects.length ? (
            <>
              {projects.map((project, idx) => (
                <ProjectCardContentItem project={project} key={idx} />
              ))}
              {perPage < totalItems && <ButtonShowMorePagination handleShowMore={handleShowMore} />}
            </>
          ) : (
            <Typography>Нет проектов</Typography>
          )}
        </Stack>
      </Card>
    </Box>
  )
})
