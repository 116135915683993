import { FC, useEffect, memo, useCallback } from 'react'

import { OrganizationCardContent } from './modules/OrganizationCardContent/OrganizationCardContent'
import { useOrganizationCardActionsController } from './modules/OrganizationCardContent/controllers/useOrganizationCardActionsController'
import { useAppSelector } from 'hooks/redux'
import { CustomLoader } from 'components/UI/CustomLoader/CustomLoader'

export const OrganizationCard: FC = memo(() => {
  const { getOrganization } = useOrganizationCardActionsController()

  const status = useAppSelector(state => state.organizationReducer.status)
  const perPageOrg = useAppSelector(state => state.organizationReducer.perPageOrg)
  const perPageChildrenOrg = useAppSelector(state => state.organizationReducer.perPageChildrenOrg)
  const orgId = useAppSelector(state => state.organizationReducer.organizationId)
  const isModalAddCompanyLeader = useAppSelector(state => state.employeesReducer.isModalAddCompanyLeader)

  useEffect(() => {
    getOrganization(orgId ? orgId : null)
  }, [perPageOrg, perPageChildrenOrg, isModalAddCompanyLeader])

  const renderOrgCard = useCallback(() => {
    if (status === 'init' || status === 'loading') return <CustomLoader />
    return <OrganizationCardContent />
  }, [status])

  return renderOrgCard()
})
