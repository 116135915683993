import { FC } from 'react'
import { Stack, CircularProgress } from '@mui/material'

export const CustomLoader: FC = () => {
  return (
    <>
      <Stack
        direction={'row'}
        alignItems='center'
        justifyContent={'center'}
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color='primary' />
      </Stack>
    </>
  )
}
