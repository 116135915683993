import { qrAuthPutUpdateWithRoleProjectLeader } from './../api/utils/employeeRole/projectLeaderApi'
import { ROLES_PROJECT_LEADER } from './../helpers/roles'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { qrAuthPostCreateWithRoleProjectLeader } from 'api/utils/employeeRole/projectLeaderApi'
import { AxiosError } from 'axios'
import { ProjectLeaderRequestsBodyModel } from 'models/requests/employee-role-requests/project-leader-requests.model'

export const fetchCreateProjectLeader = createAsyncThunk(
  `${ROLES_PROJECT_LEADER}/fetchCreateProjectLeader`,
  async (params: ProjectLeaderRequestsBodyModel.postCreate, { rejectWithValue, dispatch }) => {
    try {
      const response = await qrAuthPostCreateWithRoleProjectLeader(params)
      return response.data.items
    } catch (error: any) {
      const err: AxiosError = error
      return rejectWithValue(err.message)
    }
  },
)

export const fetchPutProjectLeader = createAsyncThunk(
  `${ROLES_PROJECT_LEADER}/fetchPutProjectLeader`,
  async (data: { id: number; params: ProjectLeaderRequestsBodyModel.put }, { rejectWithValue, dispatch }) => {
    const { id, params } = data
    try {
      const response = await qrAuthPutUpdateWithRoleProjectLeader(id, params)
      return response.data.items
    } catch (error: any) {
      const err: AxiosError = error
      return rejectWithValue(err.message)
    }
  },
)
