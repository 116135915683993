import { memo } from 'react'
import { Box } from '@mui/material'

import { EmployeeCard } from 'features/MainPage/modules/EmployeeCard/EmployeeCard'
import { OrganizationCard } from 'features/MainPage/modules/OrganizationCard/OrganizationCard'
import { ProjectCard } from 'features/MainPage/modules/ProjectCard/ProjectCard'
import { useAppSelector } from 'hooks/redux'

export const MainPage = memo(() => {
  const projectId = useAppSelector(state => state.projectReducer.projectId)
  const activeProject = useAppSelector(state => state.projectReducer.activeProject)
  const currentOrganization = useAppSelector(state => state.organizationReducer.currentOrganization)

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(3,1fr)',
        gridTemplateRows: 750,
        gap: 7.5,
      }}
    >
      <OrganizationCard />
      {currentOrganization && <ProjectCard />}
      {currentOrganization && projectId && activeProject && <EmployeeCard />}
    </Box>
  )
})
