import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { COUNT_ITEMS_PER_PAGE } from 'contants/constants'
import { StatusType } from 'models/common/common.model'
import { ProjectRequestsPutBody } from 'models/requests/project-requests.model'
import { ProjectModel } from 'models/project.model'
import { SearchProjectModel } from 'models/search.model'

interface IState {
  projects: Array<ProjectModel> | null
  activeProject: ProjectModel
  page: number
  pageCount: number
  perPage: number
  totalItems: number
  searchProject: SearchProjectModel[]
  projectId: number
  isEditProject: boolean
  projectInfo: ProjectRequestsPutBody | null
  status: StatusType
  stateModal: any
  isModalOpen: boolean
  isFetchProjects: boolean
  isModalDownloadReport: boolean
}

const initialState: IState = {
  isFetchProjects: false,
  projects: null,
  activeProject: {} as ProjectModel,
  page: 1,
  perPage: COUNT_ITEMS_PER_PAGE,
  pageCount: 1,
  totalItems: 1,
  searchProject: [],
  projectId: localStorage.getItem('projectId') ? JSON.parse(localStorage.getItem('projectId') || 'null') : null,
  isEditProject: false,
  projectInfo: null,
  status: 'init',
  isModalOpen: false,
  isModalDownloadReport: false,
  stateModal: {
    name: '',
  },
}

export const projectSlice = createSlice({
  name: 'projectSlice',
  initialState,
  reducers: {
    setIsEditProject: (state, action: PayloadAction<boolean>) => {
      state.isEditProject = action.payload
    },
    setIsFetchProjects: (state, action: PayloadAction<boolean>) => {
      state.isFetchProjects = !action.payload
    },
    setStateModal: (state, action: PayloadAction<any>) => {
      state.stateModal = action.payload
    },
    setIsModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isModalOpen = action.payload
    },
    setIsModalDownloadReport: (state, action: PayloadAction<boolean>) => {
      state.isModalDownloadReport = action.payload
    },
    projectDataFetch: (state, action: PayloadAction<ProjectModel[]>) => {
      state.projects = action.payload
    },
    projectReset: state => {
      state.projects = null
      state.status = 'init'
    },
    setStatus: (state, action: PayloadAction<StatusType>) => {
      state.status = action.payload
    },
    setProjectId: (state, action) => {
      state.projectId = action.payload
      localStorage.setItem('projectId', action.payload)
    },
    setActiveProject: (state, action) => {
      state.activeProject = action.payload
    },
    setProjectInfo: (state, action: PayloadAction<ProjectRequestsPutBody>) => {
      state.projectInfo = action.payload
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload
    },
    setPerPage: (state, action: PayloadAction<number>) => {
      state.perPage = action.payload
    },
    setPageCount: (state, action: PayloadAction<number>) => {
      state.pageCount = action.payload
    },
    setTotalItems: (state, action: PayloadAction<number>) => {
      state.totalItems = action.payload
    },
    setSearchProject: (state, action: PayloadAction<SearchProjectModel[]>) => {
      state.searchProject = action.payload
    },
  },
})

export const { reducer: projectReducer, actions: projectActions } = projectSlice
