import { qrAuthPostExistWithRoleWorker } from '../api/utils/employeeRole/workerApi'
import { qrAuthGetEmployeesByFilter, qrAuthGetEmployeesInProject } from './../api/utils/projectApi'
import { AppDispatch } from '../store/store'
import { ProjectRequestsBodyModel, ProjectRequestsQueryModel } from '../models/requests/project-requests.model'
import { employeesActions } from '../store/reducers/EmployeesSlice'

// Костыль (потом надо будет убрать)
export const fetchWorkersForSelect = (params: ProjectRequestsBodyModel.postEmployeesOnProject) => async (dispatch: AppDispatch) => {
  try {
    const response = await qrAuthGetEmployeesByFilter(params, { page: 1, perPage: 100000 })

    return Promise.resolve(response)
  } catch (err) {
    console.log(err)
    return Promise.reject(err)
  }
}

export const fetchWorkersInProject =
  (params: ProjectRequestsBodyModel.postEmployeesOnProject, query: ProjectRequestsQueryModel.postFilter) =>
  async (dispatch: AppDispatch) => {
    try {
      const response = await qrAuthGetEmployeesByFilter(params, query)

      dispatch(employeesActions.setEmployees(response.data.items))
      dispatch(employeesActions.setPageCount(response.data?.paging?.lastPage || 1))
      dispatch(employeesActions.setPerPage(response.data?.paging?.perPage))
      dispatch(employeesActions.setTotalItems(response.data?.paging?.totalItems))
      dispatch(employeesActions.setStatus('loading'))

      return Promise.resolve(response)
    } catch (err) {
      console.log(err)
      dispatch(employeesActions.setStatus('error'))
      return Promise.reject(err)
    } finally {
      dispatch(employeesActions.setStatus('success'))
    }
  }

export const addWorkersInProject = (params: any) => async (dispatch: AppDispatch) => {
  try {
    const response = await qrAuthPostExistWithRoleWorker(params)

    return Promise.resolve(response)
  } catch (err) {
    console.log(err)

    return Promise.reject(err)
  }
}

export const getWorkerInProject = (id: number) => async (dispatch: AppDispatch) => {
  try {
    const response = await qrAuthGetEmployeesInProject(id)
    dispatch(employeesActions.setStatus('loading'))

    dispatch(employeesActions.setActiveEmployee(response.data.items))
    return Promise.resolve(response)
  } catch (err) {
    dispatch(employeesActions.setStatus('error'))
    console.log(err)
    return Promise.reject(err)
  } finally {
    dispatch(employeesActions.setStatus('success'))
  }
}
