import { ChangeEvent } from 'react'
import { toast } from 'react-toastify'

import { ReportTime } from 'helpers/timeHelpers'
import { projectActions } from 'store/reducers/ProjectSlice'
import { shiftsActions } from 'store/reducers/ShiftsSlice'
import { useAppDispatch, useAppSelector } from './redux'
import { downloadReportShifts } from 'service/Shifts'
import { useNavigate } from 'react-router-dom'
import { REPORT_TABLE_PATH } from 'helpers/routePaths'

export const useReport = () => {
  const isModalDownloadReport = useAppSelector(state => state.projectReducer.isModalDownloadReport)
  const projectId = useAppSelector(state => state.projectReducer.projectId)
  const currentOrganization = useAppSelector(state => state.organizationReducer.currentOrganization)
  const period = useAppSelector(state => state.shiftsReducer.period)
  const type = useAppSelector(state => state.shiftsReducer.type)

  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const onClose = () => dispatch(projectActions.setIsModalDownloadReport(false))

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target

    dispatch(shiftsActions.setPeriod({ ...period, [name]: value }))
  }

  const elementsByReport = [
    {
      name: 'start',
      label: 'Начало смены',
      type: 'input',
      value: period.start,
      inputType: 'date',
      onChange: handleChange,
    },
    {
      name: 'end',
      value: period.end,
      label: 'Конец смены',
      type: 'input',
      inputType: 'date',
      onChange: handleChange,
    },
  ]

  const navigateStatistics = () => {
    onClose()
    navigate(`/${REPORT_TABLE_PATH}?orgId=${currentOrganization?.id}&projectId=${projectId}`)
  }

  const handleDownloadReport = () => {
    if (!period.start || !period.end) {
      toast.error(`Выберите дату!`, {
        toastId: `DownloadError`,
      })
      return
    }

    if (!ReportTime.checkBetweenMinAndMax(period.start, period.end)) {
      toast.error(`Можно скачивать отчет с интервалом 1 год!`, {
        toastId: `DownloadDateError`,
      })
      return
    }

    const params = {
      projectIds: [projectId],
      period,
      type,
    }

    dispatch(downloadReportShifts(params))

    onClose()
  }
  return {
    handleDownloadReport,
    projectId,
    elementsByReport,
    navigateStatistics,
    isModalDownloadReport,
    onClose,
  }
}
