import { AppDispatch } from '../store/store'
import { StatusRequestsBodyModel, StatusRequestsQueryModel } from '../models/requests/status-requests.model'
import { qrAuthGetStatusesByFilter } from '../api/utils/statusApi'
import { statusActions } from '../store/reducers/StatusSlice'

// Получение статусов
export const getStatuses =
  (query: StatusRequestsQueryModel.postFilter = {}, params: StatusRequestsBodyModel.postFilter = {}) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(statusActions.setStatus('loading'))

      const response = await qrAuthGetStatusesByFilter(query, params)
      dispatch(statusActions.fetchStatuses(response.data.items))

      return Promise.resolve(response)
    } catch (e) {
      dispatch(statusActions.fetchStatuses([]))
      dispatch(statusActions.setStatus('error'))
      return Promise.reject(e)
    } finally {
      dispatch(statusActions.setStatus('success'))
    }
  }
