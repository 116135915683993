import { ShiftTypeCondition } from './shift-requests.model'
import { FilterQueryCommonModel } from '../common/common.model'
import { commonExistence } from './project-requests.model'

export enum PeriodicPayment {
  SupervisorsFine = 0,
  Compensation = 1,
}

interface PostCreatePeriodicPayment {
  amount: number
  comment?: string | null
  periodicPaymentType: PeriodicPayment
  timeType: Omit<ShiftTypeCondition, ShiftTypeCondition.All>
}

interface PostFilterPeriodicPayment {
  ids?: number[]
  employeeProjectIds?: number[]
  period: Period
  periodicPaymentType?: PeriodicPayment
  employeeProjectExistence?: commonExistence
}

interface Period {
  start: string
  end: string
}

export namespace PeriodicPaymentRequestsBodyModel {
  export type post = PostCreatePeriodicPayment[]
  export type postFilter = PostFilterPeriodicPayment
}

export namespace PeriodicPaymentRequestsQueryModel {
  export type get = FilterQueryCommonModel
}
