import { useEffect, useRef } from 'react'
import { Paper, Table, TableContainer } from '@mui/material'

import { StatisticsTableBody } from './components/StatisticsTableBody/StatisticsTableBody'
import { StatisticsTableHead } from './components/StatisticsTableHead/StatisticsTableHead'
import { useActionCreatorsTyped, useAppSelector } from 'hooks/redux'
import { themeActions } from 'store/reducers/ThemeSlice'

export const StatisticsTable = () => {
  const scrollPositionStatisticsTable = useAppSelector(state => state.themeReducer.scrollPositionStatisticsTable)
  const tableContainerRef = useRef<HTMLDivElement | null>(null)
  const actionsTheme = useActionCreatorsTyped(themeActions)

  useEffect(() => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTo({ left: scrollPositionStatisticsTable })
    }
  }, [])

  return (
    <TableContainer
      component={Paper}
      ref={tableContainerRef}
      onScroll={e => actionsTheme.setScrollPositionStatisticsTable(e.currentTarget.scrollLeft)}
    >
      <Table>
        <StatisticsTableHead />
        <StatisticsTableBody />
      </Table>
    </TableContainer>
  )
}
