import { FC, memo } from 'react'
import { Stack, Tooltip, Typography } from '@mui/material'
import { OrganizationCardContentItemInfoType } from 'features/MainPage/modules/OrganizationCard/types/OrganizationCardTypes'

export const OrganizationCardContentItemInfo: FC<OrganizationCardContentItemInfoType> = memo(props => {
  const { image, contentInfo: nameInfo, tooltipTitle } = props
  return (
    <Tooltip title={tooltipTitle}>
      <Stack direction='row' gap={1} alignItems='center'>
        <img src={image} alt={image} />
        <Typography color='rgba(55, 68, 90, 0.7)'>{nameInfo}</Typography>
      </Stack>
    </Tooltip>
  )
})
