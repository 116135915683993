import { memo, useCallback, FC, useMemo } from 'react'
import { IconButton, Stack, Tooltip, Typography } from '@mui/material'

import { arrowBack, briefcase, hierarchy, plus, userSquare } from 'assets/images'
import { useAppDispatch } from 'hooks/redux'
import { useCheckClaims } from 'hooks/useCheckClaims'
import { useOrganizationCardActionsController } from '../controllers/useOrganizationCardActionsController'
import { rolesActions } from 'store/reducers/RolesSlice'
import { employeesActions } from 'store/reducers/EmployeesSlice'
import { ROLES_COMPANY_LEADER } from 'helpers/roles'
import { organizationActions } from 'store/reducers/OrganizationSlice'
import { projectActions } from 'store/reducers/ProjectSlice'
import { usePermissions } from 'hooks/usePermissions'
import { OrganizationCardContentItemInfoType } from '../../../types/OrganizationCardTypes'
import { OrganizationCardContentItemInfo } from './OrganizationCardContentItem/OrganizationCardContentItemInfo'
import { OrganizationResponse } from 'models/responses/organization.response'

interface Props {
  currentOrganization: OrganizationResponse
}

export const CurrentOrganizationContent: FC<Props> = memo(props => {
  const { currentOrganization } = props

  const { checkClaims } = useCheckClaims()
  const { getOrganization, addOrg } = useOrganizationCardActionsController()
  const { checkUserPermissions } = usePermissions()

  const dispatch = useAppDispatch()

  let marginLeftStyle: number = 0

  const addCompanyLeader = useCallback(() => {
    dispatch(rolesActions.setCurrentRole({ name: ROLES_COMPANY_LEADER }))
    dispatch(employeesActions.setIsModalAddEmployee(true))
  }, [])

  const handleBackNavigateOrg = useCallback(() => {
    dispatch(organizationActions.organizationResetData())
    dispatch(projectActions.projectReset())
    dispatch(projectActions.setProjectId(null))
    getOrganization(currentOrganization ? currentOrganization.foundingOrganizationId : null)
  }, [])

  const renderNavigateBackIcon = useCallback(() => {
    if (!checkClaims('Front.organizationCard.navigateBackToOrganization')) return null
    if (checkUserPermissions(ROLES_COMPANY_LEADER) && !currentOrganization?.foundingOrganizationId) return null

    // eslint-disable-next-line react-hooks/exhaustive-deps
    marginLeftStyle = 4.5
    return (
      <IconButton sx={{ p: 0 }} onClick={handleBackNavigateOrg}>
        <img src={arrowBack} alt={arrowBack} />
      </IconButton>
    )
  }, [])

  const elementsInfo: OrganizationCardContentItemInfoType[] = useMemo(
    () => [
      { image: hierarchy, tooltipTitle: 'Кол-во доч. организаций', contentInfo: 0 },
      { image: briefcase, tooltipTitle: 'Кол-во проектов', contentInfo: currentOrganization.activeProjects },
      { image: userSquare, tooltipTitle: 'Кол-во сотрудников', contentInfo: currentOrganization.employees },
    ],
    [],
  )

  return (
    <Stack mb={currentOrganization ? 2.6 : undefined} gap={1}>
      <Stack direction={'row'} gap={1.25}>
        {renderNavigateBackIcon()}
        <Typography fontSize={20} fontWeight={600} color='primary.main'>
          {currentOrganization?.name}
        </Typography>
        {!currentOrganization?.isHaveCompanyLeader && checkClaims('Front.organizationCard.addCompanyLeader') && (
          <Tooltip title='Добавить руководителя компании'>
            <IconButton sx={{ p: 0 }} onClick={addCompanyLeader}>
              <img src={plus} alt={plus} />
            </IconButton>
          </Tooltip>
        )}
        {checkClaims('Front.organizationCard.addChildrenOrganization') && (
          <Tooltip title={'Добавить дочернюю организацию'}>
            <IconButton sx={{ p: 0 }} onClick={addOrg}>
              <img src={plus} alt={plus} />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
      <Stack direction='row' gap={3} ml={marginLeftStyle}>
        {elementsInfo.map(({ contentInfo, image, tooltipTitle }) => {
          return <OrganizationCardContentItemInfo key={tooltipTitle} {...{ contentInfo, image, tooltipTitle }} />
        })}
      </Stack>
    </Stack>
  )
})
