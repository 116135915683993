import { memo } from 'react'
import { Box, Card, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import { OrganizationCardListChildrenOrg } from './components/OrganizationCardListChildrenOrg'
import { OrganizationCardListOrg } from './components/OrganizationCardListOrg'
import { useAppSelector } from '../../../../../../hooks/redux'
import { useCheckClaims } from 'hooks/useCheckClaims'
import { plus } from 'assets/images'
import { CurrentOrganizationContent } from './components/CurrentOrganizationContent'
import { useOrganizationCardActionsController } from './controllers/useOrganizationCardActionsController'

export const OrganizationCardContent = memo(() => {
  const currentOrganization = useAppSelector(state => state.organizationReducer.currentOrganization)

  const { addOrg } = useOrganizationCardActionsController()
  const { checkClaims } = useCheckClaims()

  return (
    <Box>
      <Stack mb={2.5} alignItems='center' direction={'row'} gap={1.25}>
        <Typography fontSize={32} color='text.primary'>
          Организации
        </Typography>
        {checkClaims('Front.organizationCard.addOrganization') && !currentOrganization && (
          <Tooltip title={'Добавить организацию'}>
            <IconButton sx={{ p: 0 }} onClick={addOrg}>
              <img src={plus} alt={plus} />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
      <Card>
        {currentOrganization && <CurrentOrganizationContent currentOrganization={currentOrganization} />}
        {/* <CustomInput placeholder='Поиск организации' sx={{ mb: 2, maxWidth: 300 }} /> */}
        <Stack
          gap={2.5}
          sx={{
            overflowY: 'auto',
            height: currentOrganization ? 630 : '100%',
          }}
        >
          {currentOrganization ? <OrganizationCardListChildrenOrg /> : <OrganizationCardListOrg />}
        </Stack>
      </Card>
    </Box>
  )
})
