import { IShift } from './../../models/shift.model'
import { ReportTime } from './../../helpers/timeHelpers'
import { StatusType } from '../../models/common/common.model'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ShiftTypeCondition } from '../../models/requests/shift-requests.model'
import { ShiftModel } from '../../models/shift.model'

type TypeState = {
  intervalData: string[]
  shifts: ShiftModel[]
  shiftEmployee: ShiftModel | null
  urlQr: any | null
  page: number
  isShiftModal: boolean
  isShiftEditModal: boolean
  pageCount: number
  type: ShiftTypeCondition
  status: StatusType
  isUpdateShift: boolean
  stateShiftEmployee: IShift | null
  period: {
    start: string
    end: string
  }
  startDateShiftStatus: string
  isChangeValueInput: boolean
}

const initialState: TypeState = {
  shiftEmployee: null,
  isChangeValueInput: false,
  isUpdateShift: false,
  stateShiftEmployee: null,
  intervalData: [],
  isShiftEditModal: false,
  isShiftModal: false,
  shifts: [],
  urlQr: null,
  pageCount: 1,
  page: 1,
  type: ShiftTypeCondition.Day,
  status: 'init',
  period: {
    start: ReportTime.getFirstMonthDay(),
    end: ReportTime.getLastMonthDay(),
  },
  startDateShiftStatus: '',
}

export const shiftsSlice = createSlice({
  name: 'shiftsSlice',
  initialState,
  reducers: {
    setIsChangeValueInput: (state, action: PayloadAction<boolean>) => {
      state.isChangeValueInput = action.payload
    },
    setShiftEmployee: (state, action: PayloadAction<ShiftModel>) => {
      state.shiftEmployee = action.payload
    },
    setIsUpdateShift: state => {
      state.isUpdateShift = !state.isUpdateShift
    },
    setStartDateShiftStatus: (state, action: PayloadAction<string>) => {
      state.startDateShiftStatus = action.payload
    },
    setStateShiftEmployee: (state, action: PayloadAction<IShift>) => {
      state.stateShiftEmployee = action.payload
    },
    setIsShiftModal: (state, action: PayloadAction<boolean>) => {
      state.isShiftModal = action.payload
    },
    setIsShiftEditModal: (state, action: PayloadAction<boolean>) => {
      state.isShiftEditModal = action.payload
    },
    setPeriod: (state, action: PayloadAction<{ start: string; end: string }>) => {
      state.period = action.payload
    },
    setStatus: (state, action: PayloadAction<StatusType>) => {
      state.status = action.payload
    },
    shiftsFetch: (state, action: PayloadAction<ShiftModel[]>) => {
      state.shifts = action.payload
    },
    shiftsSliceUrlQr: (state, action) => {
      state.urlQr = action.payload
    },
    setPageCount: (state, action: PayloadAction<number>) => {
      state.pageCount = action.payload
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload
    },
    setIntervalData: (state, action: PayloadAction<string[]>) => {
      state.intervalData = action.payload
    },
    setShiftConditionType: (state, action: PayloadAction<ShiftTypeCondition>) => {
      state.type = action.payload
    },
  },
})

export const { reducer: shiftsReducer, actions: shiftsActions } = shiftsSlice
