import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IMenu {
  name: string
  href: string
}

type ViewFilterType = 'card' | 'table'

export interface ICommonFilters {
  open: boolean
  mainView: ViewFilterType
}

export interface IBreadCrumbs {
  name: string
  href: string
  key: string
}

interface IInitialState {
  menu: IMenu[]
  title: string
  breadcrumbs: IBreadCrumbs[]
  filters: ICommonFilters
  rowCount: number
  scrollPositionStatisticsTable: number
}

const initialState: IInitialState = {
  scrollPositionStatisticsTable: 0,
  menu: [],
  title: '',
  breadcrumbs: JSON.parse(localStorage.getItem('breadcrumbs') || '[]'),
  filters: {
    open: false,
    mainView: localStorage.getItem('mainView') ? (localStorage.getItem('mainView') as ViewFilterType) : 'card',
  },
  rowCount: Number(sessionStorage.getItem('rowCount')) || 10,
}

export const themeSlice = createSlice({
  name: 'themeSlice',
  initialState,
  reducers: {
    addMenu: (state, action) => {
      state.menu = action.payload
    },
    setTitle: (state, action) => {
      state.title = action.payload
    },
    emptyStates: (state, action) => {
      state.menu = []
      state.title = ''
    },
    setScrollPositionStatisticsTable: (state, action: PayloadAction<number>) => {
      state.scrollPositionStatisticsTable = action.payload
    },
    setBreadCrumbs: (state, action: PayloadAction<IBreadCrumbs[]>) => {
      state.breadcrumbs = action.payload
      localStorage.setItem('breadcrumbs', JSON.stringify(action.payload))
    },
    setFiltersOpen: (state, action: PayloadAction<boolean>) => {
      state.filters.open = action.payload
    },
    setFiltersMainView: (state, action: PayloadAction<'card' | 'table'>) => {
      state.filters.mainView = action.payload
      localStorage.setItem('mainView', action.payload)
    },
    setRowCount: (state, action: PayloadAction<number>) => {
      state.rowCount = action.payload
      sessionStorage.setItem('rowCount', action.payload.toString())
    },
  },
})

export const { reducer: themeReducer, actions: themeActions } = themeSlice
