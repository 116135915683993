import { qrAuthTracker } from './../axios'
import { ProjectFile } from './../../models/project.model'
import { AxiosResponseModel } from './../types'
import qs from 'query-string'
import { ProjectFileRequestsBodyModel, ProjectFileRequestsQueryModel } from '../../models/requests/project-file-requests.model'
import { qrAuthTrackerUpload } from '../axios'

// ========================= PROJECT FILE =====================================
export const qrAuthPostProjectFile = (
  params: ProjectFileRequestsBodyModel.post,
  query: ProjectFileRequestsQueryModel.post,
): AxiosResponseModel<ProjectFile> => qrAuthTrackerUpload.post(`/file/project/${query.projectId}?assignment=${query.assignment}`, params)

export const qrAuthPostProjectFilesFilter = (
  query: ProjectFileRequestsQueryModel.postFilter = {},
  params: ProjectFileRequestsBodyModel.postFilter,
): AxiosResponseModel<ProjectFile[]> => qrAuthTracker.post(`/file/project/filter${qs.stringify(query)}`, params)

export const qrAuthDeleteProjectFile = (projectFileId: number): AxiosResponseModel<null> =>
  qrAuthTracker.delete(`/file/project/${projectFileId}`)

export const qrAuthDeleteProjectFiles = (projectFileId: number, projectFilesIds: number[]): AxiosResponseModel<null> =>
  qrAuthTracker.delete(`/file/project/${projectFileId}/batch?${qs.stringify(projectFilesIds)}`)

export const qrAuthDeleteProjectFilesAll = (projectId: number): AxiosResponseModel<null> =>
  qrAuthTracker.delete(`/file/project/${projectId}/all`)
