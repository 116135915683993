export const getCreateEmployeeParams = (create: any) => {
  if (!create) return

  let arr = Object.keys(create)
  let params: any = {}

  arr.forEach(key => {
    let employee = params.employee ? params.employee : {}
    let user = params.employee && params.employee.user ? params.employee?.user : {}
    switch (key) {
      case 'workingMethod':
      case 'positionId':
      case 'projectId':
      case 'dayTimeHourlyPayment':
      case 'nightTimeHourlyPayment':
        params = {
          ...params,
          [key]: create[key],
        }
        break
      case 'organizationId':
        params = {
          ...params,
          employee: {
            ...employee,
            [key]: create[key],
          },
        }
        break
      default:
        params = {
          ...params,
          employee: {
            ...employee,
            user: {
              ...user,
              [key]: create[key],
            },
          },
        }
        break
    }
  })

  return params
}
