import { Box, BoxProps, Link, Stack, Typography } from '@mui/material'
import { PRIVACY_POLICY_PATH } from 'helpers/routePaths'

export const Footer = (props: BoxProps) => {
  return (
    <Stack direction='row' {...props} px={5} justifyContent='space-between'>
      <Typography variant='body2' color='text.secondary' align='center'>
        {'Copyright © '}
        <Link color='inherit' href='https://routeam.ru/'>
          Routeam
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>

      <Typography variant='body2' color='text.secondary' align='center'>
        <Link color='inherit' href={PRIVACY_POLICY_PATH}>
          Политика конфиденциальности
        </Link>
      </Typography>
    </Stack>
  )
}
