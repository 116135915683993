import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { ROLES_COMPANY_LEADER, ROLES_WORKER } from 'helpers/roles'
import { usePermissions } from 'hooks/usePermissions'
import { useCheckClaims } from 'hooks/useCheckClaims'
import { organizationDelete, organizationFetchAll, organizationGetChildren, organizationGetCurrent } from 'service/Organization'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { organizationActions } from 'store/reducers/OrganizationSlice'
import { projectActions } from 'store/reducers/ProjectSlice'
import { OrganizationResponse } from 'models/responses/organization.response'
import { shiftsActions } from 'store/reducers/ShiftsSlice'
import { REPORT_TABLE_PATH } from 'helpers/routePaths'
import { toast } from 'react-toastify'

export const useOrganizationCardActionsController = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const perPageOrg = useAppSelector(state => state.organizationReducer.perPageOrg)
  const perPageChildrenOrg = useAppSelector(state => state.organizationReducer.perPageChildrenOrg)
  const currentUser = useAppSelector(state => state.currentUserReducer.currentUser)

  const currentUserOrganizationId = currentUser?.employee?.organization?.id ? currentUser.employee.organization.id : null

  const { checkClaims } = useCheckClaims()
  const { checkUserPermissions } = usePermissions()

  const getOrganization = useCallback(
    async (organizationId?: number | null) => {
      dispatch(projectActions.projectReset())

      if (organizationId) {
        dispatch(organizationActions.organizationResetData())

        checkClaims('Back.organization.PostSubsidiariesByFilter') &&
          dispatch(organizationGetChildren(organizationId, { level: 1, existence: 0 }, { perPage: perPageChildrenOrg })).then(() => {
            dispatch(organizationActions.setOrganizationId(organizationId))
          })
        checkClaims('Back.organization.GetById') && dispatch(organizationGetCurrent(organizationId))
        return
      }

      if (checkClaims('Back.organization.PostSubsidiariesByFilter') && checkUserPermissions(ROLES_COMPANY_LEADER)) {
        dispatch(
          organizationGetChildren(currentUserOrganizationId as number, { level: 1, existence: 0 }, { perPage: perPageChildrenOrg }),
        ).then(() => dispatch(organizationActions.setOrganizationId(currentUserOrganizationId as number)))
      }

      if (checkClaims('Back.organization.PostByFilter') && !currentUser?.employee?.organization?.id) {
        dispatch(organizationFetchAll({ perPage: perPageOrg }, { existence: 0, subordinationLevel: 0 }))
      }

      if (!checkUserPermissions(ROLES_WORKER) && checkClaims('Back.organization.GetById') && currentUserOrganizationId) {
        dispatch(organizationGetCurrent(currentUserOrganizationId as number))
      }
    },
    [perPageOrg, perPageChildrenOrg],
  )

  const deleteCompany = useCallback((org: OrganizationResponse) => {
    if (!org) return

    dispatch(organizationActions.organizationResetData())
    dispatch(organizationDelete(org.id)).then(async () => {
      await getOrganization(org.foundingOrganizationId)
      toast.success(`Организация ${org.name} успешна удалена`)
    })
  }, [])

  const addOrg = useCallback(() => {
    dispatch(organizationActions.setIsEdit(false))
    dispatch(organizationActions.setIsModal(true))
    dispatch(
      organizationActions.setStateModalEdit({
        id: '',
        name: '',
        managersFullName: '',
        foundingOrganizationId: '',
      }),
    )
  }, [])

  const handleEditOrg = (org: OrganizationResponse) => {
    dispatch(organizationActions.setIsEdit(true))
    dispatch(organizationActions.setIsModal(true))
    dispatch(
      organizationActions.setStateModalEdit({
        id: org.id.toString(),
        name: org.name,
        managersFullName: org.managersFullName ? org.managersFullName : '',
        foundingOrganizationId: org.foundingOrganizationId,
      }),
    )
  }

  const handleStatistics = useCallback((org: OrganizationResponse) => {
    dispatch(organizationActions.setOrganizationId(org.id))
    dispatch(shiftsActions.shiftsFetch([]))
    dispatch(organizationActions.setCurrentOrganization(org))
    navigate(`${REPORT_TABLE_PATH}?orgId=${org.id}`)
  }, [])

  return {
    getOrganization,
    handleEditOrg,
    handleStatistics,
    deleteCompany,
    addOrg,
  }
}
