// Локализация ошибок от сервера
export const errorMessagesFromServer = {
  // ДОЛЖНОСТИ (positions)
  2000: 'Несколько активных пользователей находятся в должности',
  2001: 'Должность не найдена',
  2002: 'Есть должность с таким же названием',
  2003: 'Должность недоступна',
  2004: 'Запрещено удалять общие должности',
  2005: 'Должность должна быть корпоративной',
  2006: 'Запрещено обновлять общие должности',
  2007: 'Ошибка при получении должностей',

  // ПОЛЬЗОВАТЕЛИ (users)
  2010: 'Ошибка при обновлении пользователя',
  2011: 'Пользователь не найден',
  2012: 'Ошибка при обновлении токена',
  2013: '',
  2014: '',
  2015: '',
  2016: '',
  2017: '',
  2018: '',
  2019: '',
  20110: '',
  20111: 'Электронная почта пользователя существует',
  20112: 'Что-то пошло не так',
  20113: 'Ошибка создания пользователя',
  20114: '',
  20115: 'Ошибка сброса пароля',
  20116: 'Недостаточно прав',
  20117: 'Разрешение пользователя не найдено',
  20118: 'Разрешения не существуют',
  20119: 'Пароль пользователя пуст',
  20120: 'Пароль должен содержать не менее 6 символов, 1 строчную букву, 1 прописную букву, 1 цифру и 1 специальный символ (#$^+=!*()@%&_)',
  20121: '',
  20122: 'Лoгин существует',
  20123: 'У пользователя нет этой разрешении',
  20124: 'Неверный токен',
  20125: 'Запрашиваемый пользователь недоступен для вас',
  20126: '',
  20127: '',
  20128: '',
  20129: '',
  20130: '',
  20131: 'Пользователь недоступен',
  20132: '',
  20133: '',
  20134: '',
  20135: 'Пользователь не имеет организации',
  20136: 'Разрешения пользователей уже существуют',
  20137: 'Пользователь должен иметь должность в организации',
  20138: 'Пользователь должен быть в организации',
  20139: 'Пользователь не может иметь должность без организации',
  20140: 'Пользователь не может быть в организации без должности',
  20141: 'Номер телефона уже используется',
  20142: 'Ошибка авторизации пользователя',
  20143: 'Фотография профиля не установлена',
  20144: 'Обновление роли пользователя недоступно',

  // РОЛИ  (roles)
  2020: '',
  2021: '',
  2022: 'Недопустимая роль',
  2023: '',
  2024: '',
  2025: 'Роль пользователя не найдена',
  2026: 'Ошибка смены роли',
  2027: '',
  2028: 'Роль администратора не может быть в организации',
  2029: 'Роль уже существует',
  20210: 'Роль не найдена',
  20211: 'Имя роли не может быть пустым',
  20212: 'Ошибка получения ролей',

  // Организации (Organizations)
  2030: 'Организация не найдена',
  2031: '',
  2032: 'Организация с таким названием уже существует',
  2033: '',
  2034: 'Организация недоступна',
  2035: 'Ошибка получения организаций',

  // Смены (shifts)
  2050: 'Смена не найдена',
  2051: 'Смена пользователя уже закончилась',
  2052: 'Смена пользователя еще не началась',
  2053: 'На сегодня у пользователя есть смена',
  2054: 'Статус "пришел" пустой',
  2055: 'Присланные смены не прошли проверку',
  2056: 'Пустая партия значений смены',
  2057: 'Нет доступных рабочих для создания отчета',
  2058: 'Имена рабочих листов не могут быть пустыми.',
  2059: 'Неправильный фильтр начала и окончания времени',
  20612: 'Установка смен вне текущей недели заблокирована',

  // Проекты (projects)
  2060: 'Название проекта уже существует',
  2061: '',
  2062: 'Проект не найден',
  2063: 'Имя проекта не может быть пустым',
  2064: 'В проекте должен быть только один мастер',
  2065: 'Проект недоступен',
  2066: 'Такой же проект уже существует',
  2067: 'Ошибка получения проектов',
  2068: 'Проекты не найдены',
  2069: 'Проекты недоступны',
  20610: 'Пустое тело запроса',

  //Разрешения к проекту (ProjectClaims)
  2070: 'Разрешения на проект уже существуют',
  2071: 'У пользователя нет разрешений в этом проекте',
  2072: 'Разрешения к проекту не найдены',
  2073: 'Добавление нового пользователя в этот проект отклонено настройками',
  2074: 'Получение заявки на проект с ошибкой',

  // Сотрудники (workers)
  2080: 'Сотрудник не найден',
  2081: 'Сотрудник уже существует в этом проекте',
  2082: 'Прораб должен иметь только один проект',
  2083: 'Пользователь с этой ролью не может быть в проектах',
  2084: 'Рабочие недоступны',
  2085: 'Рабочие не найдены',

  // Ошибки проверки (ValidationErrors)
  2090: 'Не валидные данные',

  // Штрафы (PeriodicPayments)
  2180: 'Штрафы/компенсации не найдены',

  // STATUSES
  2100: 'Статус не найден',
  2101: 'Есть статус с таким же именем',
  2102: 'Ошибка получения статусов',
  2103: 'Позиция недоступна',
  2104: 'Запрещено удалять общее положение',
  2105: 'Должность должна быть корпоративной',
  2106: 'Запрещено обновлять общее положение',
  2107: 'Ошибка получения позиций',
  2108: 'Пакет позиций не прошел проверку',
  2109: '',
  2110: 'Статус не найден',
  2111: 'Есть статус с таким же именем',
  2112: 'Ошибка получения статусов',

  //EMPLOYEES
  2120: 'Сотрудник не найден',
  2121: 'Сотрудник недоступен',
  2122: 'Получение ошибки сотрудников',
  2123: 'Форман или рабочий должны быть назначены оплаты',
  2124: 'Неправильная роль для сотрудника',
  2162: 'Сотрудник не на проекте',
}
