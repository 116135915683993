import { FC, memo } from 'react'
import { ButtonShowMore } from 'components/UI/styled/ButtonShowMore'

interface Props {
  handleShowMore: () => void
}

export const ButtonShowMorePagination: FC<Props> = memo(props => {
  const { handleShowMore } = props
  return (
    <ButtonShowMore variant='outlined' onClick={handleShowMore}>
      Показать еще
    </ButtonShowMore>
  )
})
