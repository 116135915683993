import { memo } from 'react'
import { Stack, Box, Avatar, Typography } from '@mui/material'

import { MainTextField } from 'components/UI/TextFields/MainTextField/MainTextField'
import { returnLocaleRole } from 'helpers/roles'
import { useAppSelector } from 'hooks/redux'
import { WorkerCardHeaderActions } from './WorkerCardHeaderActions'

export const WorkerCardHeaderInfo = memo(() => {
  const activeEmployee = useAppSelector(state => state.employeesReducer.activeEmployee)

  return (
    <>
      {activeEmployee && (
        <Stack direction={'row'} gap={2.5} mb={8.5}>
          <Box>
            <Avatar
              sx={{
                width: 116,
                height: 116,
              }}
            >
              worker
            </Avatar>
          </Box>
          <Stack>
            <Box mb={2}>
              {false ? (
                <MainTextField label='Имя сотрудника' name='name' />
              ) : (
                <Typography fontWeight={600} lineHeight={1.2} fontSize={32} color='text.primary'>
                  {activeEmployee.surname} {activeEmployee.name} {activeEmployee.patronymic}
                </Typography>
              )}
              <Typography fontSize={24} color='#37445ACC' lineHeight={1.2}>
                Должность: {returnLocaleRole(activeEmployee?.role.name)}
              </Typography>
            </Box>
            <WorkerCardHeaderActions />
          </Stack>
        </Stack>
      )}
    </>
  )
})
