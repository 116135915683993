import { EmployeeProject } from 'models/shift.model'
import { ShiftTypeCondition } from '../models/requests/shift-requests.model'

export const shiftConditionTypeValue = (payload: EmployeeProject, type: ShiftTypeCondition) => {
  switch (type) {
    case ShiftTypeCondition.All:
      return `${payload.dayTimeHourlyPayment}/${payload.nightTimeHourlyPayment}`
    case ShiftTypeCondition.Day:
      return payload.dayTimeHourlyPayment
    default:
      return payload.nightTimeHourlyPayment
  }
}
