import {
  qrAuthDeleteProjectFile,
  qrAuthDeleteProjectFiles,
  qrAuthDeleteProjectFilesAll,
  qrAuthPostProjectFilesFilter,
} from './../api/utils/projectFileApi'
import { qrAuthPostProjectFile } from '../api/utils/projectFileApi'
import { ProjectFileRequestsBodyModel, ProjectFileRequestsQueryModel } from '../models/requests/project-file-requests.model'
import { AppDispatch } from '../store/store'
import { projectFilesActions } from '../store/reducers/ProjectFilesSlice'

export const postProjectFile =
  (params: ProjectFileRequestsBodyModel.post, query: ProjectFileRequestsQueryModel.post) => async (dispatch: AppDispatch) => {
    dispatch(projectFilesActions.setStatus('init'))
    try {
      const response = await qrAuthPostProjectFile(params, query)
      dispatch(projectFilesActions.setStatus('loading'))
      return Promise.resolve(response)
    } catch (e) {
      dispatch(projectFilesActions.setStatus('error'))
      return Promise.reject(e)
    } finally {
      dispatch(projectFilesActions.setStatus('success'))
    }
  }

export const deleteProjectFile = (id: number) => async (dispatch: AppDispatch) => {
  try {
    const response = await qrAuthDeleteProjectFile(id)

    return Promise.resolve(response)
  } catch (e) {
    return Promise.reject(e)
  }
}
export const deleteProjectFiles = (id: number, projectFilesIds: number[]) => async (dispatch: AppDispatch) => {
  try {
    const response = await qrAuthDeleteProjectFiles(id, projectFilesIds)

    return Promise.resolve(response)
  } catch (e) {
    return Promise.reject(e)
  }
}
export const deleteAllProjectFiles = (id: number) => async (dispatch: AppDispatch) => {
  try {
    const response = await qrAuthDeleteProjectFilesAll(id)

    return Promise.resolve(response)
  } catch (e) {
    return Promise.reject(e)
  }
}

export const postProjectFilesFilter =
  (params: ProjectFileRequestsBodyModel.postFilter, query: ProjectFileRequestsQueryModel.postFilter) => async (dispatch: AppDispatch) => {
    try {
      const response = await qrAuthPostProjectFilesFilter(query, params)
      dispatch(projectFilesActions.projectFilesFilterFetch({ items: response.data.items, paging: response.data.paging }))
      dispatch(projectFilesActions.setStatus('loading'))
      return Promise.resolve(response)
    } catch (e) {
      dispatch(projectFilesActions.setStatus('error'))
      return Promise.reject(e)
    } finally {
      dispatch(projectFilesActions.setStatus('success'))
    }
  }
