const environment = process.env.REACT_APP_ENVIRONMENT

const returnBaseApiUrl = () => {
    if (environment === 'stage') {
        return process.env.REACT_APP_STAGE_API_URL;
    } else if (environment === 'development') {
        return process.env.REACT_APP_DEV_API_URL;
    } else {
        return process.env.REACT_APP_PROD_API_URL;
    }
}

const returnBaseUrl = () => {
    if (environment === 'stage') {
        return process.env.REACT_APP_STAGE_URI;
    } else if (environment === 'development') {
        return process.env.REACT_APP_DEV_URI;
    } else {
        return process.env.REACT_APP_PROD_URI;
    }
}

export const baseAPI_URL = returnBaseApiUrl()
export const BASE_URL = returnBaseUrl()
