import { ROLES_FOREMAN } from './../helpers/roles'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { ForemanRequestsBodyModel } from '../models/requests/employee-role-requests/foreman-requests.model'
import { qrAuthPostCreateWithRoleForeman, qrAuthPutUpdateWithRoleForeman } from '../api/utils/employeeRole/foremanApi'

export const fetchPutForeman = createAsyncThunk(
  `${ROLES_FOREMAN}/fetchPutForeman`,
  async (data: { id: number; params: ForemanRequestsBodyModel.put }, { rejectWithValue, dispatch }) => {
    const { id, params } = data
    try {
      const response = await qrAuthPutUpdateWithRoleForeman(id, params)
      return response.data.items
    } catch (error: any) {
      const err: AxiosError = error
      return rejectWithValue(err.message)
    }
  },
)

export const fetchCreateForeman = createAsyncThunk(
  `${ROLES_FOREMAN}/fetchCreateForeman`,
  async (params: ForemanRequestsBodyModel.postCreate, { rejectWithValue, dispatch }) => {
    try {
      const response = await qrAuthPostCreateWithRoleForeman(params)
      return response.data.items
    } catch (error: any) {
      const err: AxiosError = error
      return rejectWithValue(err.message)
    }
  },
)
