import { useLocation, useNavigate } from 'react-router-dom'

import { organizationActions } from 'store/reducers/OrganizationSlice'
import { ROLES_WORKER } from 'helpers/roles'
import { ADMIN_AREA_PATH, REPORT_TABLE_PATH } from 'helpers/routePaths'
import { useAppDispatch, useAppSelector } from './redux'
import { usePermissions } from './usePermissions'

export const useCheckPath = () => {
  const currentUser = useAppSelector(state => state.currentUserReducer.currentUser)
  const { checkUserPermissions } = usePermissions()

  const navigate = useNavigate()
  const location = useLocation()

  const dispatch = useAppDispatch()

  const handleMainPage = () => {
    if (location.pathname === `/${ADMIN_AREA_PATH}`) return
    if (!checkUserPermissions(ROLES_WORKER)) {
      dispatch(organizationActions.setStatus('init'))
      navigate(ADMIN_AREA_PATH)
      return
    }

    navigate(
      `/${REPORT_TABLE_PATH}?orgId=${currentUser?.employee.organization?.id}&employeeId=${currentUser?.employee.employeeProjects[0].id}`,
    )
  }

  return {
    handleMainPage,
  }
}
