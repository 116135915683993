import { memo } from 'react'
import { Stack } from '@mui/material'

import { useCheckClaims } from 'hooks/useCheckClaims'
import { StatisticsActionsChangeShiftType } from './components/StatisticsActionsChangeShiftType'
import { StatisticsActionsEdit } from './components/StatisticsActionsEdit'
import { StatisticsActionsTimeFields } from './components/StatisticsActionsTimeFields'
import { StatisticsActionsDownloadReport } from './components/StatisticsActionsDownloadReport'

export const StatisticsActions = memo(() => {
  const { checkClaims } = useCheckClaims()

  return (
    <Stack>
      <Stack direction='row' gap={6.2} mb={5}>
        <StatisticsActionsTimeFields />
        <StatisticsActionsChangeShiftType />
      </Stack>
      <Stack direction={'row'} gap={3} justifyContent='flex-end'>
        {checkClaims('Front.statistics.downloadReport') && <StatisticsActionsDownloadReport />}
        {checkClaims('Front.statistics.edit') && <StatisticsActionsEdit />}
      </Stack>
    </Stack>
  )
})
