import { TRoles } from '../helpers/roles'
import { useAppSelector } from './redux'

// Проверить есть ли у юзера такая роль или нет
export const usePermissions = () => {
  const currentUser = useAppSelector(state => state.currentUserReducer.currentUser)

  const userPositionHistory = currentUser?.employee?.employeeProjects ? currentUser.employee?.employeeProjects[0] : null

  // Проверить есть ли у юзера такая роль или нет
  const checkUserPermissions = (...roles: TRoles[]) => {
    if (!currentUser) {
      return false
    }

    const userRole = currentUser.role?.name

    if (!userRole) {
      return false
    }

    return roles.some(role => role.toLowerCase() === userRole.toLowerCase())
  }

  return {
    userPositionHistory,
    checkUserPermissions,
  }
}
