import { FormControlLabel, Switch } from '@mui/material'
import { memo } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux'
import { fetchPutProject } from '../../../../../service/Projects'

export const SwitchBlockShifts = memo(() => {
  const activeProject = useAppSelector(state => state.projectReducer.activeProject)
  const isEditProject = useAppSelector(state => state.projectReducer.isEditProject)

  const dispatch = useAppDispatch()
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    dispatch(
      fetchPutProject(Number(activeProject?.id), {
        ...activeProject?.projectInfo,
        isShiftsBlocking: checked,
        name: activeProject?.name,
      }),
    )
  }

  return (
    <FormControlLabel
      control={<Switch onChange={handleChange} checked={activeProject?.projectInfo?.isShiftsBlocking || false} />}
      label='Блокировать ли смены вне текущей недели'
      disabled={!isEditProject}
      labelPlacement='start'
      sx={{
        '&.MuiFormControlLabel-label': {
          fontSize: 18,
        },
      }}
    />
  )
})
