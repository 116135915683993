import qs from 'query-string'
import { qrAuthTracker } from './../axios'
import { StatusRequestsBodyModel, StatusRequestsQueryModel } from '../../models/requests/status-requests.model'
import { StatusResponse } from '../../models/responses/status.response'
import { AxiosResponseModel } from '../types'

// =========================   STATUS   ===============================

export const qrAuthGetStatusesByFilter = (
  query: StatusRequestsQueryModel.postFilter = {},
  params: StatusRequestsBodyModel.postFilter,
): AxiosResponseModel<StatusResponse[]> => qrAuthTracker.post(`/management/worker/status/filter?${qs.stringify(query)}`, params)
