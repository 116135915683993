import React, { LegacyRef } from 'react';
import ReactCrop, { Crop, PercentCrop, PixelCrop } from 'react-image-crop';

interface CropProProps {
  imgSrc?: string;
  crop: Crop | undefined;
  setCrop: (p: PercentCrop) => void;
  setCompletedCrop: (p: PixelCrop) => void;
  aspect: number | undefined;
  imgRef: LegacyRef<HTMLImageElement> | undefined;
  scale: number;
  onImageLoad: (p: React.SyntheticEvent<HTMLImageElement>) => void;
}

const CropPro: React.FC<CropProProps> = ({ imgSrc, crop, setCrop, setCompletedCrop, aspect, imgRef, scale, onImageLoad }) => {
  return (
    <>
      {Boolean(imgSrc) && (
        <ReactCrop crop={crop} onChange={(_, percentCrop) => setCrop(percentCrop)} onComplete={c => setCompletedCrop(c)} aspect={aspect}>
          <img ref={imgRef} alt="Crop me" src={imgSrc} style={{ transform: `scale(${scale})` }} onLoad={onImageLoad} />
        </ReactCrop>
      )}
    </>
  );
};

export default CropPro;
