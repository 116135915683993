import { FC, PropsWithChildren } from 'react'
import { Box, SxProps, Theme } from '@mui/material'

interface Props {
  isEdited: boolean
  sx?: SxProps<Theme>
}

export const BoxEdited: FC<PropsWithChildren<Props>> = props => {
  const { isEdited, children } = props
  const isEditedStyled = !isEdited
    ? {
        display: 'block',
        userSelect: 'none',
        pointerEvents: 'none',
        opacity: 0.7,
        position: 'relative',
        zIndex: 2,
      }
    : {}

  return (
    <Box
      onClick={e => {
        if (!isEdited) {
          e.preventDefault()
          e.stopPropagation()
        }
      }}
      sx={{
        ...isEditedStyled,
      }}
    >
      {children}
    </Box>
  )
}
