import { memo } from 'react'
import { Box } from '@mui/material'
import { TabPanelDocumentsUploadFile } from './components/TabPanelDocumentsUploadFile'
import { TabPanelDocumentsTable } from './components/TabPanelDocumentsTable'
import { useCheckClaims } from 'hooks/useCheckClaims'

export const TabPanelDocuments = memo(() => {
  const { checkClaims } = useCheckClaims()
  return (
    <Box>
      {checkClaims('Front.projectPassport.documents.addDocument') && <TabPanelDocumentsUploadFile />}
      <TabPanelDocumentsTable />
    </Box>
  )
})
