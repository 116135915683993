import { FC, PropsWithChildren } from 'react'
import { Box, Modal, SxProps, Theme, Typography } from '@mui/material'

import { close } from 'assets/images'
import { BoxCentered } from '../styled/BoxCentered'

interface Props {
  open: boolean
  onClose: () => void
  titleModal?: string
  sx?: SxProps<Theme>
}

export const CustomModal: FC<PropsWithChildren<Props>> = props => {
  const { open, onClose, titleModal, children, sx } = props

  return (
    <Modal open={open}>
      <BoxCentered sx={sx}>
        {titleModal && (
          <Typography
            variant='h6'
            sx={{
              fontSize: 32,
            }}
            mb={4}
            color='text.primary'
          >
            {titleModal}
          </Typography>
        )}
        <Box sx={{ width: '100%' }}>
          <img
            src={close}
            alt='close'
            width={18}
            style={{ cursor: 'pointer', position: 'absolute', right: 12, top: 10 }}
            onClick={onClose}
          />
          {children}
        </Box>
      </BoxCentered>
    </Modal>
  )
}
