import { UserResponse } from './../../models/responses/user.response'
import qs from 'query-string'
import { qrAuthTracker, qrAuthTrackerUpload } from './../axios'
import { AxiosResponseModel } from '../types'
import { UserRequestsBodyModel, UserRequestsQueryModel } from '../../models/requests/user-requests.model'

// =========================  USER  ===========================
export const qrAuthGetCurrentUser = (): AxiosResponseModel<UserResponse> => qrAuthTracker.get('/user/current')

export const qrAuthDeleteUser = (id: number) => qrAuthTracker.delete(`/user/${id}`)

export const qrAuthGetUserByFilter = (
  query: UserRequestsQueryModel.postFilter,
  params: UserRequestsBodyModel.postFilter,
): AxiosResponseModel<UserResponse[]> => qrAuthTracker.post(`/user/filter?${qs.stringify(query)}`, params)

export const qrAuthPostUser = (params: UserRequestsBodyModel.postBody): AxiosResponseModel<UserResponse> =>
  qrAuthTracker.post(`/user`, params)

export const qrAuthPutUser = (id: number, params: UserRequestsBodyModel.putBody): AxiosResponseModel<UserResponse> =>
  qrAuthTracker.put(`/user/${id}`, params)

export const qrAuthPutCurrentUser = (params: UserRequestsBodyModel.putCurrentUser): AxiosResponseModel<UserResponse> =>
  qrAuthTracker.put(`/user/current`, params)

export const qrAuthPostProfilePhoto = (id: number, params: FormData): AxiosResponseModel<UserResponse> =>
  qrAuthTrackerUpload.post(`/user/profile/${id}/photo`, params)
