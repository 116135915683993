import { Box, styled } from '@mui/material'

export const BoxCentered = styled(Box)(({ theme }) => ({
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: theme.palette.background.paper,
  width: 782,
  borderRadius: 10,
  padding: '45px 60px',
  boxShadow: '0px 30px 60px rgba(32, 56, 85, 0.15)',
  overflow: 'auto',
}))
