import React from 'react';
import { Slider, styled } from '@mui/material';


const PrettoSlider = styled(Slider)({
  color: '#52af77',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#52af77',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

interface CropSliderProProps {
  scale: number
  setScale: (p: number) => void
}

const CropSliderPro: React.FC<CropSliderProProps> = ({scale, setScale}) => {
  return (
    <>
      <PrettoSlider
        valueLabelDisplay="auto"
        aria-label="pretto slider"
        value={scale}
        step={0.1}
        min={1}
        max={3}
        aria-labelledby="Zoom"
        onChange={(e, zoom) => setScale(Number(zoom))}
      />
    </>
  );
};

export default CropSliderPro;
