import { memo, useCallback } from 'react'
import { Stack, Tooltip, IconButton, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { edit, noteText, qr } from 'assets/images'
import { CustomButton } from 'components/UI/Button/CustomButton'
import { ADMIN_AREA_PATH, REPORT_TABLE_PATH } from 'helpers/routePaths'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { getQrCodesByFilterService } from 'service/QrCodesService'
import { employeesActions } from 'store/reducers/EmployeesSlice'
import { qrCodesActions } from 'store/reducers/QrCodeSlice'
import { getPutEmployeeParams } from 'helpers/employeeParams/getPutEmployeeParams'
import { useEmployeeFetches } from 'hooks/employeeHooks/useEmployeeFetches'
import { fetchDeleteEmployeeInProject } from 'service/Employees'
import { useCheckClaims } from 'hooks/useCheckClaims'
import { rolesActions } from 'store/reducers/RolesSlice'
import { ROLES_WORKER } from 'helpers/roles'
import { UserResponse } from 'models/responses/user.response'

export const WorkerCardHeaderActions = memo(() => {
  const activeEmployee = useAppSelector(state => state.employeesReducer.activeEmployee) as UserResponse
  const statesEmployee = useAppSelector(state => state.employeesReducer.statesEmployee)
  const isEditEmployee = useAppSelector(state => state.employeesReducer.isEditEmployee)
  const isPutEmployee = useAppSelector(state => state.employeesReducer.isPutEmployee)
  const put = statesEmployee && statesEmployee.statePutEmployee
  const disabled = activeEmployee?.isDeleted
  const roleEmployee = activeEmployee?.role.name

  const { checkClaims } = useCheckClaims()

  const fetches = useEmployeeFetches()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const getQr = useCallback(() => {
    dispatch(getQrCodesByFilterService({ employeeProjectIds: [activeEmployee.employee.employeeProjects[0].id] })).then(() => {
      dispatch(qrCodesActions.setIsModalQrPrint(true))
    })
  }, [])

  const handleRemoveEmployeeInProject = () => {
    dispatch(fetchDeleteEmployeeInProject(activeEmployee?.employee.employeeProjects[0].id)).then(() => {
      navigate('/' + ADMIN_AREA_PATH)
    })
  }

  const handleSave = useCallback(() => {
    const params = getPutEmployeeParams(put)

    fetches.putEmployee &&
      dispatch(fetches.putEmployee({ id: activeEmployee.employee.employeeProjects[0].id, params })).then(() => {
        dispatch(employeesActions.setIsPutEmployee(!isPutEmployee))
        dispatch(employeesActions.setIsEditEmployee(false))
        dispatch(rolesActions.setCurrentRole(null))
      })
  }, [put, fetches, activeEmployee])

  const navigateToTable = () => {
    navigate(
      `/${REPORT_TABLE_PATH}?orgId=${activeEmployee.employee.organization?.id}&employeeId=${activeEmployee.employee.employeeProjects[0].id}`,
    )
  }

  return (
    <Stack direction={'row'} gap={5}>
      <Stack direction={'row'} gap={2}>
        {checkClaims('Front.workerCard.edit') && (
          <>
            {isEditEmployee ? (
              <Tooltip title={'сохранить'}>
                <IconButton disabled={disabled} sx={{ p: 0 }} onClick={handleSave}>
                  <img src={edit} alt={edit} />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title={'редактировать'}>
                <IconButton
                  disabled={disabled}
                  onClick={() => {
                    dispatch(rolesActions.setCurrentRole({ name: activeEmployee?.role.name }))
                    dispatch(employeesActions.setIsEditEmployee(true))
                  }}
                  sx={{ p: 0 }}
                >
                  <img src={edit} alt={edit} />
                </IconButton>
              </Tooltip>
            )}
          </>
        )}

        {roleEmployee === ROLES_WORKER && checkClaims('Front.workerCard.qr') && (
          <Tooltip title={'qr-code'}>
            <IconButton disabled={disabled} onClick={() => getQr()} sx={{ p: 0 }}>
              <img src={qr} alt={qr} />
            </IconButton>
          </Tooltip>
        )}
        {roleEmployee === ROLES_WORKER && checkClaims('Front.workerCard.statistics') && (
          <Tooltip title={'табель'}>
            <IconButton disabled={disabled} onClick={navigateToTable} sx={{ p: 0 }}>
              <img src={noteText} alt={noteText} />
            </IconButton>
          </Tooltip>
        )}
      </Stack>

      {checkClaims('Front.workerCard.delete') && (
        <>
          {!disabled ? (
            <CustomButton
              onClick={handleRemoveEmployeeInProject}
              sx={{
                fontWeight: 400,
                minHeight: 42,
                fontSize: 16,
                textTransform: 'none',
                bgcolor: '#FF5833',
              }}
            >
              Уволить
            </CustomButton>
          ) : (
            <Typography variant='h5' fontWeight={700} color='error'>
              Уволен
            </Typography>
          )}
        </>
      )}
    </Stack>
  )
})
