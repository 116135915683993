import { AppBar, Box, Toolbar } from '@mui/material'

import { HeaderActions } from './HeaderActions'
import { HeaderLogo } from './HeaderLogo'

const Header = () => {
  return (
    <Box>
      <AppBar
        position='static'
        sx={{
          minHeight: 86,
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'center',
          boxShadow: 'none',
        }}
      >
        <Toolbar sx={{ maxWidth: '1575px', width: '100%', '&.MuiToolbar-root': { p: 0 } }}>
          <Box flexGrow={1}>
            <HeaderLogo />
          </Box>
          <HeaderActions />
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export { Header }
