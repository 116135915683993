import { Validation } from './validation'

export type EmployeeStateStaffType = {
  email: string
  password: string | null
  userName: string
  name: string
  surname: string
  patronymic?: string
  phoneNumber: string
  citizenship: string
  birthDate: string
  workingMethod?: string
  dayTimeHourlyPayment?: number | null
  nightTimeHourlyPayment?: number | null
  positionId?: number | null
  organizationId?: number
  projectId?: number
}

export const validationPostEmployee = (param: EmployeeStateStaffType) => {
  let errorValidateMessages: string[] = []

  if (!Validation.email(param.email)) {
    errorValidateMessages.push('Не валидный email!')
  }
  if (!Validation.text(param.citizenship)) {
    errorValidateMessages.push('Не валидное гражданство!')
  }
  if (!Validation.text(param.password as string)) {
    errorValidateMessages.push('Не валидный пароль!')
  }
  if (!Validation.text(param.userName)) {
    errorValidateMessages.push('Не валидный логин!')
  }
  if (!Validation.text(param.name)) {
    errorValidateMessages.push('Не валидное имя!')
  }
  if (!Validation.text(param.surname)) {
    errorValidateMessages.push('Не валидная фамилия!')
  }
  if (!Validation.text(param.birthDate)) {
    errorValidateMessages.push('Не валидная дата рождения!')
  }
  if (!Validation.phoneNumber(param.phoneNumber)) {
    errorValidateMessages.push('Не валидный номер телефона!')
  }
  if (param.workingMethod === '' && !Validation.text(param.workingMethod as string)) {
    errorValidateMessages.push('Не валидный метод работы!')
  }
  if (param.positionId === null && !Validation.number(param.positionId)) {
    errorValidateMessages.push('Не валидная должность!')
  }
  if (param.dayTimeHourlyPayment === null && !Validation.number(param.dayTimeHourlyPayment)) {
    errorValidateMessages.push('Не валидная почасовая оплата в дневное время!')
  }
  if (param.nightTimeHourlyPayment === null && !Validation.number(param.nightTimeHourlyPayment)) {
    errorValidateMessages.push('Не валидная почасовая оплата в ночное время!')
  }

  return {
    errorValidateMessages,
  }
}
