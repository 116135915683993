import { StatusType } from '../../models/common/common.model'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ShiftQrResponse } from '../../models/responses/shift-qr.response'

interface IState {
  qrCodesPrint: ShiftQrResponse[]
  status: StatusType
  isModalQrPrint: boolean
}

const initialState: IState = {
  qrCodesPrint: [],
  status: 'init',
  isModalQrPrint: false,
}

export const qrCodesSlice = createSlice({
  name: 'qrCodesSlice',
  initialState,
  reducers: {
    qrCodesFetch: (state, action: PayloadAction<ShiftQrResponse[]>) => {
      state.qrCodesPrint = action.payload
    },
    setStatus: (state, action: PayloadAction<StatusType>) => {
      state.status = action.payload
    },
    setIsModalQrPrint: (state, action: PayloadAction<boolean>) => {
      state.isModalQrPrint = action.payload
    },
  },
})

export const { reducer: qrCodesReducer, actions: qrCodesActions } = qrCodesSlice
