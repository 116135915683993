import { memo, useCallback, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { fetchWorkersInProject } from 'service/Worker'
import { fetchProjectById } from 'service/Projects'
import { CustomLoader } from 'components/UI/CustomLoader/CustomLoader'
import { EmployeesCardContent } from './modules/EmployeesCardContent/EmployeesCardContent'

export const EmployeeCard = memo(() => {
  const projectId = useAppSelector(state => state.projectReducer.projectId)
  const perPage = useAppSelector(state => state.employeesReducer.perPage)
  const isAddEmployee = useAppSelector(state => state.employeesReducer.isAddEmployee)
  const status = useAppSelector(state => state.employeesReducer.status)

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchProjectById(Number(projectId))).then(
      async () => await dispatch(fetchWorkersInProject({ projectIds: [Number(projectId)] }, { perPage })),
    )
  }, [projectId, perPage, isAddEmployee])

  const renderEmployeesCard = useCallback(() => {
    if (status === 'init' || status === 'loading') return <CustomLoader />
    return <EmployeesCardContent />
  }, [status])

  return renderEmployeesCard()
})
