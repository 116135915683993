import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IItems {
  names?: string[]
  surnames?: string[]
  phoneNumbers?: string[]
  positionIds?: number[]
  roleIds?: number[]
  projectIds?: number[]
}

interface InitialState {
  filtersParams: IItems
}

const Items: IItems = {
  names: [],
  surnames: [],
  phoneNumbers: [],
  positionIds: [],
  roleIds: [],
  projectIds: [],
}

const initialState: InitialState = {
  filtersParams: Items,
}

export const filtersSlice = createSlice({
  name: 'filtersSlice',
  initialState,
  reducers: {
    setFiltersParams: (state, action: PayloadAction<IItems>) => {
      state.filtersParams = action.payload
    },
  },
})

export const { reducer: filtersReducer, actions: filtersActions } = filtersSlice
