import { ChangeEvent, useRef, useState } from 'react'

export const useUploadController = () => {
  const hiddenFileInput = useRef<HTMLInputElement | null>(null)
  const [file, setFile] = useState<File | null>(null)

  const resetFile = () => {
    if (file) {
      setFile(null)
      handleResetRef()
    }
  }

  const handleChangeFile = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (!files) return
    const element = files[0]
    setFile(element)
  }

  const handleResetRef = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.value = ''
    }
  }

  const handleClickFileUpload = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click()
    }
  }

  return {
    file,
    hiddenFileInput,
    resetFile,
    handleChangeFile,
    handleClickFileUpload,
  }
}
