import { FC, useEffect, useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, MenuItem, Modal, Typography, Box } from '@mui/material'

import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import classes from './StyleBasicModal.module.css'
import { checkExtNameFile } from '../../../helpers/files'
import { CropImagePro } from '../../Molecules/CropImage'
import { CountrySelect } from '../../Molecules/CountrySelect'
import { NavigationButton } from '../../Molecules/NavigationButton'
import { CustomButton } from '../../UI/Button/CustomButton'
import { close } from '../../../assets/images'
import { MultiValueInput } from '../../UI/MultiValueInput/MultiValueInput'
import { CustomSelect } from '../../UI/CustomSelect/CustomSelect'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { MainTabs } from '../../Tabs/MainTabs'
import { tabsActions } from 'store/reducers/TabsSlice'
import { MainTextField } from '../../UI/TextFields/MainTextField/MainTextField'
import { NumberTextField } from 'components/UI/TextFields/NumberTextField/NumberTextField'
import { PatternTextField } from 'components/UI/TextFields/PatternTextField/PatternTextField'

interface INavigationState {
  startKey: string
  endKey: string
  title: string
  to: string
}

export interface IItemElement {
  tabIndex?: number
  name: string
  label?: string
  type?: string
  inputType?: string
  variant?: 'standard' | 'filled' | 'outlined' | undefined
  inputLabel?: string
  min?: number
  max?: number
  required?: boolean
  accept?: string
  mask?: any
  placeholder?: string
  keyValue?: string
  multiple?: boolean
  accordionOptions?: IItemElement[]
  gridLength?: number
  typeFile?: string
  iconFile?: string
  format?: string
  defaultValue?: string
}

interface IBasicModal {
  redux?: boolean
  open: boolean
  setOpen: any
  title: React.ReactNode
  buttonTitle?: string
  elements: Array<IItemElement>
  handleSave: (param: any) => void
  options?: any
  emptyValues?: any
  button?: boolean
  initialState: any
  fileExtNames?: string[]
  navigationState?: INavigationState | null
  columnsCount?: number
  itemCount?: number
  isTabs?: boolean
  switchDayToNigthToggle?: boolean
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 782,
  bgcolor: '#fff',
  borderRadius: '10px',
  boxShadow: 24,
  p: '45px 60px',
  overflow: 'auto',
  scroll: 'none',
  '@media(max-height: 890px)': {
    top: '0',
    transform: 'translate(-50%, 0%)',
  },
}

export const BasicModal: FC<IBasicModal> = ({
  open,
  setOpen,
  title,
  buttonTitle = 'Сохранить',
  elements,
  handleSave,
  options,
  emptyValues,
  button = true,
  initialState,
  fileExtNames,
  navigationState,
  columnsCount,
  itemCount,
  isTabs,
  switchDayToNigthToggle,
  redux,
}) => {
  const [state, setState] = useState(initialState)

  const [openCrop, setOpenCrop] = useState(false)

  const handleClose = (p: boolean) => {
    setOpenCrop(p)
  }

  const handleSavePhoto = (name: string, file: File) => {
    if (fileExtNames) {
      const isValidFile = checkExtNameFile(fileExtNames, file)

      if (!isValidFile) return

      setState({ ...state, [name]: file })
    } else {
      setState({ ...state, [name]: file })
    }

    setOpenCrop(false)
  }

  const handleOpenCrop = () => {
    setOpenCrop(true)
  }

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>, element: IItemElement) => {
    if (e.target?.files) {
      const file = e.target.files[0]

      if (fileExtNames) {
        const isValidFile = checkExtNameFile(fileExtNames, file)

        if (!isValidFile) return

        setState({ ...state, [element.name]: e.target?.files })
      } else {
        setState({ ...state, [element.name]: e.target?.files })
      }
    }
  }

  const dispatch = useAppDispatch()

  const handleCloseModal = () => {
    redux ? dispatch(setOpen(false)) : setOpen(false)
    dispatch(tabsActions.setTabIndex(0))
  }

  useEffect(() => {
    setState(initialState)
  }, [initialState])

  const tabIndex = useAppSelector(state => state.tabsReducer.tabIndex)

  // рендеринг элементов
  const drawElement = (element: IItemElement) => {
    switch (element.type) {
      case 'input':
        return (
          <MainTextField
            type={element.inputType || 'text'}
            name={element.name}
            id={element.name}
            defaultValue={element.defaultValue}
            label={element.label}
            value={state[element.name]}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setState({ ...state, [element.name]: element.inputType === 'number' ? Number(e.target.value) : e.target.value })
            }
            className={classes.textField}
            autoComplete={`new-${element.name}`}
            required={!!element.required}
          />
        )
      case 'number':
        return (
          <NumberTextField
            name={element.name}
            placeholder={element.placeholder}
            value={state[element.name]}
            label={element.label}
            onChange={(e: any) => setState({ ...state, [element.name]: e.target.value })}
          />
        )
      case 'multiInput':
        return <MultiValueInput {...{ element, state, setState }} />
      case 'upload':
        return (
          <Button variant='text' component='label' fullWidth>
            <img src={element.iconFile} alt='image' width={26} style={{ marginRight: '1rem' }} />
            <Typography variant='h6' sx={{ textTransform: 'none' }} fontSize={18} color='primary.main'>
              {element.label}
            </Typography>
            <input
              hidden
              accept={element.typeFile}
              type='file'
              name={element.name}
              id={element.name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeFile(e, element)}
              required={!!element.required}
            />
          </Button>
        )
      case 'select':
        return (
          <CustomSelect
            id={element.name}
            multiple={element.multiple}
            name={element.name}
            value={state[element.name] || ''}
            label={element.label}
            required={!!element.required}
            fullWidth
            onChange={(e: any) => setState({ ...state, [element.name]: e.target.value })}
            children={
              options && options[element.name] && options[element.name].length > 0 ? (
                options[element.name].map((item: any) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.text}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value={emptyValues && emptyValues[element.name] ? emptyValues[element.name]?.value : ''}>
                  {emptyValues && emptyValues[element.name] ? emptyValues[element.name]?.text : 'Нет выбора'}
                </MenuItem>
              )
            }
          />
        )
      case 'pattern':
        return (
          <PatternTextField
            name={element.name}
            mask={element.mask}
            format={element.format as string}
            placeholder={element.placeholder}
            value={state[element.name]}
            label={element.label}
            onChange={(e: any) => setState({ ...state, [element.name]: e.target.value })}
          />
        )
      case 'img':
        return (
          <div>
            <img src={state[element.name]} alt={element.label} className={classes.img} />
          </div>
        )
      case 'cropImage':
        return (
          <>
            <Button
              onClick={handleOpenCrop}
              startIcon={<FileUploadOutlinedIcon />}
              sx={{ width: '100%', margin: '0 0px', textTransform: 'none', fontSize: '18px' }}
            >
              Загрузить фото
            </Button>
            <CropImagePro
              open={openCrop}
              setOpen={handleClose}
              handleSavePhoto={handleSavePhoto}
              name={element.name}
              fileExtNames={fileExtNames}
              accept={element.accept}
            />
          </>
        )
      case 'accordion':
        return (
          <Accordion>
            <AccordionSummary>
              <Typography>{element.label}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {element.accordionOptions?.map((option: IItemElement) => (
                <Grid
                  item
                  xs={12}
                  key={option.name + option.label}
                  className={elements.length < 2 ? classes.item : ''}
                  sx={{ padding: elements.length < 2 ? '0 0 15px 0' : ' 0 10px 10px 0' }}
                >
                  {drawElement(option)}
                </Grid>
              ))}
            </AccordionDetails>
          </Accordion>
        )
      case 'countries':
        return (
          <CountrySelect
            label={element.label}
            required={!!element.required}
            value={state[element.name] || ''}
            onChange={(_, value) => {
              setState({ ...state, [element.name]: value ? value[element.keyValue || ''] : '' })
            }}
          />
        )

      default:
        return <></>
    }
  }

  return (
    <Modal
      open={open}
      // onClose={handleCloseModal}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      sx={{ display: openCrop ? 'none' : 'block', overflowY: 'scroll' }}
      disableScrollLock={false}
      className={classes.modal}
    >
      <Box sx={style}>
        <Typography
          id='modal-modal-title'
          variant='h6'
          sx={{
            fontSize: '32px',
          }}
          component='h2'
          mb={5}
          color='text.primary'
        >
          {title}
        </Typography>
        <Box
          component='div'
          sx={{
            width: '100%',
          }}
        >
          <img
            src={close}
            alt='close'
            width={18}
            style={{ cursor: 'pointer', position: 'absolute', right: 12, top: 10 }}
            onClick={handleCloseModal}
          />
          {isTabs && <MainTabs />}
          <Grid container sx={{ minWidth: '100%' }} columnSpacing={4} rowSpacing={4} columns={columnsCount ? columnsCount : 12}>
            {elements.map((element: IItemElement) => (
              <Grid
                item
                xs={element.type === 'accordion' || switchDayToNigthToggle ? 12 : itemCount ? itemCount : elements.length < 2 ? 12 : 6}
                key={element.name + element.label}
                hidden={isTabs ? element.tabIndex !== tabIndex : undefined}
              >
                {drawElement(element)}
              </Grid>
            ))}
            <Grid item container justifyContent={'flex-end'} xs={itemCount ? itemCount : navigationState ? 6 : 12}>
              {button && <CustomButton variant='contained' onClick={() => handleSave(state)} children={buttonTitle} />}
            </Grid>
            {navigationState && (
              <Grid item xs={6} sx={{ paddingRight: '10px' }}>
                <NavigationButton
                  to={navigationState.to}
                  state={{
                    start: state[navigationState.startKey],
                    end: state[navigationState.endKey],
                  }}
                  title={navigationState.title}
                />
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
    </Modal>
  )
}
