import { StatisticsTableColumnsType } from './StatisticsTableColumnsTypes'

export const StatisticsTableRightColumns: readonly StatisticsTableColumnsType[] = [
  {
    name: 'Количество часов',
    width: 100,
  },
  {
    name: 'Сумма начислений за отработанное время',
    width: 181,
  },
  {
    name: 'Сумма штрафов за прогулы',
    width: 144,
  },
  {
    name: 'Сумма штрафов от супервайзера',
    width: 144,
  },
  {
    name: 'Сумма компенсаций',
    width: 144,
  },
]
