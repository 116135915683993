import { FC } from 'react'
import { FilledInputProps, SelectProps, TextFieldProps } from '@mui/material'
import { MyStyledTextField } from '../TextFields/MainTextField/MainTextField'

type CustomSelectType = SelectProps & TextFieldProps

export const CustomSelect: FC<CustomSelectType> = ({ variant, children, multiple, ...props }) => {
  return (
    <MyStyledTextField
      select
      variant={variant || 'filled'}
      InputLabelProps={{ shrink: props.value ? true : undefined }}
      InputProps={{ disableUnderline: true } as Partial<FilledInputProps>}
      SelectProps={
        {
          multiple: !!multiple,
        } as Partial<SelectProps>
      }
      {...props}
    >
      {children}
    </MyStyledTextField>
  )
}
