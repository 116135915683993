import { ProjectInfoModel } from './../project.model'
import { PositionHistory } from './../responses/user.response'
import { SearchRequestQueryEmployeeModel } from './search-requests.model'

export enum ProjectStatus {
  Open = 0,
  Suspended = 1,
  Closed = 2,
  All = 3,
}

export interface ProjectRequestsGetByFilterBody {
  ids?: number[]
  organizationIds?: number[]
  names?: string[]
  projectStatus?: ProjectStatus
}

export interface ProjectRequestsPostBody {
  name: string
  organizationId: number
  projectInfo?: ProjectInfoModel
}

export interface ProjectStatusPatch {
  id: number
  projectStatus: ProjectStatus
}

export interface ProjectRequestsPutBody {
  name: string
  isShiftsBlocking: boolean
  location: string
  legalEntity: string
  technicalTask: string
  dayShiftStartTime: string
  dayShiftEndTime: string
  nightShiftStartTime: string
  nightShiftEndTime: string
  contactInformation: string[]
  requestPersonnel: string[]
  provision: string[]
  orientationOfBuildings: string[]
  termsPaymentOfInvoices: string[]
  schedule: string
  saleRatesWithVAT: number
  saleRatesWithoutVAT: number
  fineForTruancy: number
}

export enum commonExistence {
  Exists = 0,
  Deleted = 1,
  All = 2,
}

export enum EmployeeActivity {
  Active = 0,
  NotActive = 1,
  All = 2,
}

export enum ProjectCompleteness {
  Opened = 0,
  Closed = 1,
  All = 2,
}

export interface ProjectEmployeesRequestsBody {
  ids?: number[]
  names?: string[]
  surnames?: string[]
  phoneNumbers?: string[]
  userNames?: string[]
  emails?: string[]
  roleIds?: number[]
  employeeIds?: number[]
  organizationIds?: number[]
  positionIds?: number[]
  workingMethods?: string[]
  projectIds?: number[]
  statusIds?: number[]
  addedByUserIds?: number[]
  userExistence?: commonExistence
  employeeExistence?: commonExistence
  employeeActivity?: EmployeeActivity
  employeeProjectExistence?: commonExistence
  positionHistory?: PositionHistory
  projectCompleteness?: ProjectCompleteness
}
export namespace ProjectRequestsBodyModel {
  export type postFilter = ProjectRequestsGetByFilterBody
  export type post = ProjectRequestsPostBody
  export type put = ProjectRequestsPutBody
  export type postEmployeesOnProject = ProjectEmployeesRequestsBody
}

export namespace ProjectRequestsQueryModel {
  export type postFilter = SearchRequestQueryEmployeeModel
  export type patchStatus = ProjectStatusPatch
}
