import { StatusType } from '../../models/common/common.model'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UserResponse } from '../../models/responses/user.response'

interface IAuthToken {
  accessToken: string
  refreshToken: string
}

interface InitialState {
  currentUser: UserResponse | null
  accessToken: string | null
  refreshToken: string | null
  status: StatusType
}

const user: UserResponse | null = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser') || '{}') : null

let currentUser: UserResponse | null = null

if (user) {
  currentUser = { ...user }
} else {
  localStorage.clear()
}

const initialState: InitialState = {
  currentUser: currentUser,
  accessToken: localStorage.getItem('token') ? localStorage.getItem('token') : null,
  refreshToken: null,
  status: 'init',
}

export const currentUserSlice = createSlice({
  name: 'currentUserSlice',
  initialState,
  reducers: {
    setStatus: (state, action: PayloadAction<StatusType>) => {
      state.status = action.payload
    },
    setCurrentUser: (state, action: PayloadAction<UserResponse>) => {
      state.currentUser = action.payload

      localStorage.setItem('currentUser', JSON.stringify(action.payload))
    },
    editCurrentUser: (state, action: PayloadAction<UserResponse>) => {
      if (state.currentUser) {
        const user: UserResponse = { ...state.currentUser }

        state.currentUser = {
          ...user,
          ...action.payload,
          isDeleted: user.isDeleted,
          profilePhoto: user.profilePhoto,
        }

        localStorage.setItem(
          'currentUser',
          JSON.stringify({
            ...state.currentUser,
            user: {
              ...action.payload,
              profilePhoto: state.currentUser.profilePhoto,
              isDeleted: state.currentUser.isDeleted,
            },
          }),
        )
      }
    },
    editPhotoCurrentUser: (state, action: PayloadAction<string>) => {
      if (state.currentUser) {
        const user: UserResponse = { ...state.currentUser }

        state.currentUser = { ...user, profilePhoto: action.payload }

        localStorage.setItem(
          'currentUser',
          JSON.stringify({
            ...state.currentUser,
            user: { ...state.currentUser, profilePhoto: action.payload },
          }),
        )
      }
    },
    setToken: (state, action: PayloadAction<IAuthToken>) => {
      state.accessToken = action.payload.accessToken
      state.refreshToken = action.payload.refreshToken

      localStorage.setItem('token', action.payload.accessToken)
    },
    logout: state => {
      state.status = 'init'
      state.currentUser = null
      state.accessToken = null
      state.refreshToken = null

      localStorage.clear()
    },
  },
})

export const { reducer: currentUserReducer, actions: currentUserActions } = currentUserSlice
