import { FC, useState, memo } from 'react'
import { toast } from 'react-toastify'
import { Box, Stack } from '@mui/material'
import { useActionCreatorsTyped, useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { SortByTypeBasic } from '../../SortByTypeBasic/SortByTypeBasic'
import { CustomButton } from '../../UI/Button/CustomButton'
import { employeesActions } from '../../../store/reducers/EmployeesSlice'
import { fetchGetRole } from '../../../service/RoleThunks'
import { CustomModal } from '../../UI/CustomModal/CustomModal'
import { useEmployeeCreateState } from 'hooks/employeeHooks/employeeState/useEmployeeCreateState'

export const AddPeopleChangeRoleModal: FC = memo(() => {
  const element = useEmployeeCreateState()

  const [roleOption, setRoleOption] = useState('')

  const actionsEmployee = useActionCreatorsTyped(employeesActions)
  const isModalChangeRoleAddEmployee = useAppSelector(state => state.employeesReducer.isModalChangeRoleAddEmployee)
  const optionsEmployee = useAppSelector(state => state.employeesReducer.optionsEmployee)

  const dispatch = useAppDispatch()

  const onClose = () => actionsEmployee.setIsModalChangeRoleAddEmployee(false)
  const onClick = () => actionsEmployee.setIsModalAddEmployee(true)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    if (!value) {
      toast.error(`Выберите роль`)
      return
    }

    setRoleOption(value)
    dispatch(fetchGetRole({ params: { ids: [Number(value)] } }))
  }

  return (
    <CustomModal open={isModalChangeRoleAddEmployee} onClose={onClose} titleModal='Выберите роль'>
      <Stack gap={3}>
        <Box>
          {SortByTypeBasic({
            name: 'roleId',
            type: 'select',
            value: roleOption,
            onChange: handleChange,
            label: 'Роль',
            options: optionsEmployee?.roleId,
          })}
        </Box>
        <CustomButton onClick={onClick} sx={{ alignSelf: 'flex-end' }}>
          Далее
        </CustomButton>
      </Stack>
    </CustomModal>
  )
})
