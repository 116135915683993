import { toLocaleRoles } from './../../helpers/roles'
import { StatusType } from '../../models/common/common.model'
import { LocaleRolesModel, RoleModel } from './../../models/responses/user.response'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { fetchGetRole, fetchGetRoles } from '../../service/RoleThunks'

interface IState {
  currentRole: Partial<RoleModel> | null
  roles: RoleModel[]
  localeRoles: LocaleRolesModel[]
  pageCount: number
  status: StatusType
}

const initialState: IState = {
  roles: [],
  localeRoles: [],
  pageCount: 1,
  status: 'init',
  currentRole: null,
}

export const rolesSlice = createSlice({
  name: 'rolesSlice',
  initialState,
  reducers: {
    setCurrentRole: (state, action: PayloadAction<Partial<RoleModel> | null>) => {
      state.currentRole = action.payload
    },
    setStatus: (state, action: PayloadAction<StatusType>) => {
      state.status = action.payload
    },
    rolesRefresh: state => {
      state.roles = []
      state.localeRoles = []
    },
    setPageCount: (state, action: PayloadAction<number>) => {
      state.pageCount = action.payload
    },
  },
  extraReducers: {
    [fetchGetRoles.fulfilled.type]: (state, action: PayloadAction<RoleModel[]>) => {
      state.status = 'success'
      state.roles = action.payload
      state.localeRoles = toLocaleRoles(action.payload)
    },
    [fetchGetRoles.pending.type]: state => {
      state.status = 'loading'
    },
    [fetchGetRoles.rejected.type]: (state, action: PayloadAction<string>) => {
      state.status = 'error'
    },

    [fetchGetRole.fulfilled.type]: (state, action: PayloadAction<RoleModel>) => {
      state.status = 'success'
      state.currentRole = action.payload
    },
    [fetchGetRole.pending.type]: state => {
      // state.status = 'loading'
    },
    [fetchGetRole.rejected.type]: (state, action: PayloadAction<string>) => {
      // state.status = 'error'
    },
  },
})

export const { reducer: rolesReducer, actions: rolesActions } = rolesSlice
