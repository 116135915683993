export const elementsModals = [
  {
    name: 'name',
    label: 'Наименование проекта',
    type: 'input',
    required: true,
  },
  {
    name: 'legalEntity',
    label: 'Юрлицо проекта',
    type: 'input',
  },
  {
    name: 'location',
    label: 'Локация',
    type: 'input',
  },
  {
    name: 'contactInformation',
    label: 'Контактное лицо',
    type: 'multiInput',
    multiple: true,
  },
  {
    name: 'orientationOfBuildings',
    label: 'Направления деятельности',
    type: 'multiInput',
    multiple: true,
  },
]

// {
//   name: 'uploadFile',
//   label: 'Прикрепить файл',
//   type: 'upload',
//   typeFile: '.doc,.docx,.xml,.zip,.rar,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
//   iconFile: attach,
// },
// {
//   name: 'uploadImage',
//   label: 'Загрузить фото',
//   type: 'upload',
//   typeFile: 'image/*',
//   iconFile: download,
// },
