import { ChangeEvent } from 'react'
import { useActionCreatorsTyped, useAppSelector } from '../../../hooks/redux'
import { employeesActions } from '../../../store/reducers/EmployeesSlice'

export const usePutEmployeeController = () => {
  const actions = useActionCreatorsTyped(employeesActions)

  const statesEmployee = useAppSelector(state => state.employeesReducer.statesEmployee)
  const put = statesEmployee && statesEmployee.statePutEmployee

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target

    actions.setStatesEmployee({ ...statesEmployee, statePutEmployee: { ...put, [name]: value } })
  }

  return { handleChange }
}
