import { useState, MouseEvent } from 'react'
import { Box, Stack, Typography, Menu, MenuItem } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { useLogout } from 'hooks/useLogout'
import { useAppSelector } from 'hooks/redux'
import { BASE_URL } from 'api/url'
import { USER_PROFILE_PATH } from 'helpers/routePaths'

import { noPhoto } from 'assets/images'

export const HeaderActions = () => {
  const currentUser = useAppSelector(state => state.currentUserReducer.currentUser)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const exit = useLogout()

  const navigate = useNavigate()

  const handleMenu = (event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)

  const handleClose = () => setAnchorEl(null)

  const handleUserProfile = () => {
    setAnchorEl(null)
    navigate(USER_PROFILE_PATH)
  }

  return (
    <Box>
      <Stack
        direction={'row'}
        alignItems='center'
        gap={2}
        onClick={handleMenu}
        sx={{
          cursor: 'pointer',
        }}
      >
        <img
          src={currentUser?.profilePhoto ? BASE_URL + '/' + currentUser?.profilePhoto : noPhoto}
          alt={currentUser?.userName}
          width={48}
          height={48}
          style={{
            borderRadius: '50%',
            border: '1px solid #fff',
          }}
        />
        {currentUser && (
          <Box>
            <Typography
              color='#F5F5F5'
              fontSize={20}
              sx={{
                wordWrap: 'nowrap',
              }}
            >
              {currentUser.surname + ' ' + currentUser.name + ' ' + currentUser.patronymic}
            </Typography>
            <Typography color='rgba(255, 255, 255, 0.6)' fontSize={18}>
              Личный кабинет
            </Typography>
          </Box>
        )}
      </Stack>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleUserProfile}>Личный кабинет</MenuItem>
        <MenuItem onClick={exit}>Выйти</MenuItem>
      </Menu>
    </Box>
  )
}
