import { UserResponse } from 'models/responses/user.response'
import qs from 'query-string'
import { ProjectCommonModel, ProjectModel } from 'models/project.model'
import { ProjectRequestsBodyModel, ProjectRequestsQueryModel } from 'models/requests/project-requests.model'
import { qrAuthTracker } from '../axios'
import { AxiosResponseModel } from '../types'

// ========================== PROJECT ================================

export const qrAuthGetProjectsByFilter = (
  params: ProjectRequestsBodyModel.postFilter,
  query: ProjectRequestsQueryModel.postFilter = {},
): AxiosResponseModel<ProjectModel[]> => qrAuthTracker.post(`/project/filter?${qs.stringify(query)}`, params)

export const qrAuthGetProjectById = (id: number): AxiosResponseModel<ProjectCommonModel> => qrAuthTracker.get(`/project/${id}`)

export const qrAuthGetProjectByEmployee = (id: number): AxiosResponseModel<ProjectModel[]> => qrAuthTracker.get(`/project/${id}/employee`)

export const qrAuthPostProject = (params: ProjectRequestsBodyModel.post): AxiosResponseModel<ProjectCommonModel> =>
  qrAuthTracker.post(`/project`, params)

export const qrAuthPutProject = (id: number, params: ProjectRequestsBodyModel.put): AxiosResponseModel<ProjectCommonModel> =>
  qrAuthTracker.put(`/project/${id}`, params)

export const qrAuthDeleteProject = (id: number): AxiosResponseModel<null> => qrAuthTracker.delete(`/project/${id}`)

export const qrAuthPatchProjectStatus = (params: ProjectRequestsQueryModel.patchStatus): AxiosResponseModel<null> =>
  qrAuthTracker.patch(`project/${params.id}/status?projectStatus=${params.projectStatus}`)

export const qrAuthDeleteWorkersInProject = (id: number): AxiosResponseModel<null> => qrAuthTracker.delete(`/management/project/${id}`)

export const qrAuthGetEmployeesInProject = (id: number): AxiosResponseModel<UserResponse> => qrAuthTracker.get(`/management/project/${id}`)

export const qrAuthGetEmployeesByFilter = (
  params: ProjectRequestsBodyModel.postEmployeesOnProject,
  query: ProjectRequestsQueryModel.postFilter = {},
): AxiosResponseModel<UserResponse[]> => qrAuthTracker.post(`/management/project/filter?${qs.stringify(query)}`, params)
