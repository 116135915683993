import React, { useRef, useState } from 'react'
import { Crop, PixelCrop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { Box, Modal, styled, TextField, Button, Stack } from '@mui/material'
import { useDebounceEffect } from './useDebounceEffect'
import { centerAspectCrop } from './centerAspectCrop'
import { canvasPreview } from './canvasPreview'
import CropPro from './CropPro'
import CropSliderPro from './CropSliderPro'
import { checkExtNameFile } from '../../../helpers/files'

const Wrapper = styled('div')(() => ({
  margin: '10px',
  width: 300,
}))

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#fff',
  borderRadius: '10px',
  boxShadow: 24,
  maxWidth: 900,
  p: 4,
}

interface CropImageProps {
  open: boolean
  setOpen: (p: boolean) => void
  handleSavePhoto: (name: string, p: File) => void
  name: string
  fileExtNames: string[] | undefined
  accept: string | undefined
}

const CropImagePro: React.FC<CropImageProps> = ({ open, setOpen, handleSavePhoto, name, fileExtNames, accept }) => {
  const [imgSrc, setImgSrc] = useState('')
  const previewCanvasRef = useRef<HTMLCanvasElement>(null)
  const imgRef = useRef<HTMLImageElement>(null)
  const [crop, setCrop] = useState<Crop>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const [scale, setScale] = useState(1)
  const [aspect] = useState<number | undefined>(25 / 14)
  const [fileProps, setFileProps] = useState({
    name: '',
    ext: '',
  })

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]

      if (fileExtNames) {
        const isValid = checkExtNameFile(fileExtNames, file)

        const ext = file.name.split('.').pop()

        if (!isValid || !ext) {
          return
        }

        setFileProps({
          name: file.name,
          ext: ext,
        })

        setCrop(undefined) // Makes crop preview update between images.
        const reader = new FileReader()
        reader.addEventListener('load', () => setImgSrc(reader?.result?.toString() || ''))
        reader.readAsDataURL(e.target.files[0])
      }
    }
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  useDebounceEffect(
    async () => {
      if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
        // We use canvasPreview as it's much faster than imgPreview.
        await canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale)
      }
    },
    100,
    [completedCrop, scale],
  )

  const handleSave = () => {
    if (previewCanvasRef?.current && completedCrop) {
      previewCanvasRef?.current.toBlob(async blob => {
        const file = new File([blob as Blob], fileProps.name, { type: `image/${fileProps.ext}` })

        handleSavePhoto(name, file)
      }, `image/${fileProps.ext}`)
    }
  }

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      sx={{ overflow: 'auto' }}
    >
      <Box sx={style}>
        <Stack direction={'row'} alignItems={'center'}>
          <Wrapper>
            <TextField
              type={'file'}
              name={name}
              onChange={onSelectFile}
              InputLabelProps={{ shrink: true }}
              inputProps={{ accept: accept || '' }}
              sx={{ mb: 2 }}
            />
            <CropPro
              crop={crop}
              setCrop={setCrop}
              scale={scale}
              aspect={aspect}
              imgRef={imgRef}
              onImageLoad={onImageLoad}
              setCompletedCrop={setCompletedCrop}
              imgSrc={imgSrc}
            />

            <CropSliderPro scale={scale} setScale={setScale} />
          </Wrapper>

          {Boolean(completedCrop) && (
            <canvas
              ref={previewCanvasRef}
              style={{
                objectFit: 'contain',
                width: completedCrop?.width,
                height: completedCrop?.height,
              }}
            />
          )}
        </Stack>
        {Boolean(completedCrop) && (
          <Wrapper>
            <Button onClick={() => setOpen(false)}>Отмена</Button>
            <Button onClick={handleSave}>Сохранить</Button>
          </Wrapper>
        )}
      </Box>
    </Modal>
  )
}

export { CropImagePro }
