import { memo, useCallback } from 'react'
import { Box, Card, IconButton, Stack, Tooltip, Typography } from '@mui/material'

import { useCheckClaims } from 'hooks/useCheckClaims'
import { plus } from 'assets/images'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { employeesActions } from 'store/reducers/EmployeesSlice'
import { EmployeeCardContentItem } from './components/EmployeesСardItem'
import { ButtonShowMorePagination } from 'features/MainPage/components/ButtonShowMorePagination/ButtonShowMorePagination'
import { ProjectStatus } from 'models/requests/project-requests.model'

export const EmployeesCardContent = memo(() => {
  const currentOrganization = useAppSelector(state => state.organizationReducer.currentOrganization)
  const activeProject = useAppSelector(state => state.projectReducer.activeProject)
  const employees = useAppSelector(state => state.employeesReducer.employees)
  const perPage = useAppSelector(state => state.employeesReducer.perPage)
  const totalItems = useAppSelector(state => state.employeesReducer.totalItems)

  const { checkClaims } = useCheckClaims()

  const dispatch = useAppDispatch()

  const handleShowMore = useCallback(() => dispatch(employeesActions.setPerPage(perPage * 2)), [perPage])

  return (
    <Box>
      <Stack mb={2.5} alignItems='center' direction={'row'} gap={1.25}>
        <Typography fontSize={32} color='text.primary'>
          Сотрудники
        </Typography>
        {checkClaims('Front.employeeCard.addEmployee') && activeProject.projectStatus !== ProjectStatus.Closed && (
          <Tooltip title='Добавить сотрудника'>
            <IconButton sx={{ p: 0 }} onClick={() => dispatch(employeesActions.setIsModalChangeRoleAddEmployee(true))}>
              <img src={plus} alt={plus} />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
      <Card>
        <Box mb={2.6}>
          <Typography fontWeight={600} fontSize={12}>
            Проект: {activeProject?.name}
          </Typography>
          <Typography fontWeight={600} fontSize={12}>
            Организация: {currentOrganization?.name}
          </Typography>
        </Box>
        <Stack
          gap={2.5}
          sx={{
            overflowY: 'auto',
            height: 650,
          }}
        >
          {employees && employees.length ? (
            <>
              {employees.map((worker, idx) => (
                <EmployeeCardContentItem key={idx} worker={worker} />
              ))}
              {perPage < totalItems && <ButtonShowMorePagination handleShowMore={handleShowMore} />}
            </>
          ) : (
            <Typography>Нет сотрудников</Typography>
          )}
        </Stack>
      </Card>
    </Box>
  )
})
