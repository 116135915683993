import { Box, Tab, Tabs } from '@mui/material'

import { tabsActions } from 'store/reducers/TabsSlice'
import { ROLES_COMPANY_LEADER } from 'helpers/roles'
import { useAppDispatch, useAppSelector } from 'hooks/redux'

export const MainTabs = () => {
  const tabIndex = useAppSelector(state => state.tabsReducer.tabIndex)
  const currentRole = useAppSelector(state => state.rolesReducer.currentRole)

  const dispatch = useAppDispatch()

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(tabsActions.setTabIndex(newValue))
  }

  const renderTabs = () => {
    if (currentRole?.name === ROLES_COMPANY_LEADER) return
    return <Tab label='Рабочая информация' />
  }

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
      <Tabs value={tabIndex} onChange={handleChange}>
        <Tab label='Авторизация' />
        <Tab label='Личные данные' />
        {renderTabs()}
      </Tabs>
    </Box>
  )
}
