import { memo } from 'react'
import { Box } from '@mui/material'
import { ProjectPassportHeaderInfo } from './components/ProjectPassportHeaderInfo'

export const ProjectPassportHeader = memo(() => {
  return (
    <Box mb={7.5}>
      <ProjectPassportHeaderInfo />
    </Box>
  )
})
