import { memo } from 'react'
import { Stack } from '@mui/material'

import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { shiftsActions } from 'store/reducers/ShiftsSlice'
import { statusActions } from 'store/reducers/StatusSlice'
import { ButtonStyled } from '../styled/StatisticsActionsEdit.styled'
import { useStatisticsActionsDisabled } from '../controllers/useStatisticsActionsDisabled'

export const StatisticsActionsEdit = memo(() => {
  const isEditStatuses = useAppSelector(state => state.statusReducer.isEditStatuses)

  const dispatch = useAppDispatch()

  const { onDisabled } = useStatisticsActionsDisabled()

  const handleEdit = () => dispatch(statusActions.setIsEditStatuses(true))
  const handleCancel = () => {
    dispatch(statusActions.setIsEditStatuses(false))
    dispatch(shiftsActions.setIsUpdateShift())
  }

  return (
    <Stack direction={'row'} gap={5} alignSelf='flex-end'>
      {isEditStatuses ? (
        <ButtonStyled onClick={handleCancel} variant='contained'>
          Завершить редактирование
        </ButtonStyled>
      ) : (
        <ButtonStyled disabled={onDisabled()} onClick={handleEdit} variant='contained'>
          Редактировать
        </ButtonStyled>
      )}
    </Stack>
  )
})
