import { PeriodicPayment } from './../../models/requests/periodic-payment-requests.model'
import { fetchGetPeriodicPayment } from './../../service/PeriodicPaymentThunks'
import { PeriodicPaymentResponse } from '../../models/responses/periodic-payment.response'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { StatusType } from '../../models/common/common.model'
import { fetchAddPeriodicPayment } from '../../service/PeriodicPaymentThunks'

interface IState {
  periodicPaymentItems: PeriodicPaymentResponse[] | null
  isAddPeriodicPaymentModal: boolean
  isViewPeriodicPaymentModal: boolean
  status: StatusType
  periodicPaymentType: PeriodicPayment | null
}

const initialState: IState = {
  periodicPaymentType: null,
  periodicPaymentItems: null,
  status: 'init',
  isAddPeriodicPaymentModal: false,
  isViewPeriodicPaymentModal: false,
}

export const periodicPaymentSlice = createSlice({
  name: 'periodicPaymentSlice',
  initialState,
  reducers: {
    setPeriodicPaymentType: (state, action: PayloadAction<PeriodicPayment>) => {
      state.periodicPaymentType = action.payload
    },
    setPeriodicPaymentItems: (state, action: PayloadAction<PeriodicPaymentResponse[]>) => {
      state.periodicPaymentItems = action.payload
    },
    setIsAddPeriodicPaymentModal: (state, action: PayloadAction<boolean>) => {
      state.isAddPeriodicPaymentModal = action.payload
    },
    setIsViewPeriodicPaymentModal: (state, action: PayloadAction<boolean>) => {
      state.isViewPeriodicPaymentModal = action.payload
    },
  },
  extraReducers: {
    [fetchAddPeriodicPayment.fulfilled.type]: state => {
      state.status = 'success'
    },
    [fetchAddPeriodicPayment.pending.type]: state => {
      state.status = 'loading'
    },
    [fetchAddPeriodicPayment.rejected.type]: (state, action: PayloadAction<string>) => {
      state.status = 'error'
    },

    [fetchGetPeriodicPayment.fulfilled.type]: (state, action: PayloadAction<PeriodicPaymentResponse[]>) => {
      state.status = 'success'
      state.periodicPaymentItems = action.payload
    },
    [fetchGetPeriodicPayment.pending.type]: state => {
      state.status = 'loading'
    },
    [fetchGetPeriodicPayment.rejected.type]: (state, action: PayloadAction<string>) => {
      state.status = 'error'
      state.periodicPaymentItems = []
    },
  },
})

export const { reducer: periodicPaymentReducer, actions: periodicPaymentActions } = periodicPaymentSlice
