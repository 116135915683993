import { FC, useState } from 'react'
import { Box, Button, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material'
import { toast } from 'react-toastify'
import { CustomButton } from 'components/UI/Button/CustomButton'
import { useUploadController } from 'hooks/useUploadController'
import { AssignmentFile } from 'models/requests/project-file-requests.model'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { postProjectFile } from 'service/ProjectFile'
import { useParams } from 'react-router-dom'
import { projectFilesActions } from 'store/reducers/ProjectFilesSlice'
import { CustomModal } from 'components/UI/CustomModal/CustomModal'

interface Props {
  open: boolean
  handleClose: any
}

export const TabPanelDocumentsUploadFileModal: FC<Props> = ({ open, handleClose }) => {
  const { hiddenFileInput, handleChangeFile, file, handleClickFileUpload, resetFile } = useUploadController()

  const isFetchProjectFiles = useAppSelector(state => state.projectFilesReducer.isFetchProjectFiles)
  const status = useAppSelector(state => state.projectFilesReducer.status)

  const params = useParams()
  const projectId = Number(params.projectId)

  const [typeFile, setTypeFile] = useState<AssignmentFile>(AssignmentFile.TKP)

  const handleChangeRadioGroup = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTypeFile(Number(event.target.value))
  }

  const dispatch = useAppDispatch()

  const handleCancel = () => {
    resetFile()
    handleClose(true)
  }

  const addProjectFile = () => {
    const formData = new FormData()
    formData.append('projectFile', file as File)

    if (!projectId) {
      toast.error('Произошла ошибка загрузки файла')
      return
    }

    dispatch(postProjectFile(formData, { projectId, assignment: typeFile })).then(() => {
      toast.success('Файл успешно добавлен', {
        autoClose: 1000,
      })
      dispatch(projectFilesActions.setIsFetchProjectFiles(!isFetchProjectFiles))
      resetFile()
    })
  }

  return (
    <CustomModal sx={{ width: 'auto' }} open={open} onClose={handleClose}>
      <Stack>
        <Box mb={2}>
          <Typography fontWeight={600} fontSize={32} mb={2}>
            Выберите тип документа
          </Typography>
          <RadioGroup value={typeFile} onChange={handleChangeRadioGroup}>
            <FormControlLabel value={AssignmentFile.TKP} control={<Radio />} label='ТКП и КП' />
            <FormControlLabel value={AssignmentFile.Contracts} control={<Radio />} label='Договоры и ДС' />
            <FormControlLabel value={AssignmentFile.Other} control={<Radio />} label='Другое' />
          </RadioGroup>
        </Box>
        <Box mb={4}>
          <Typography fontWeight={600} fontSize={32} mb={2}>
            Выберите документ
          </Typography>
          <Stack direction={'row'} alignItems='center' gap={3}>
            <CustomButton disabled={status !== 'success'} onClick={file ? resetFile : handleClickFileUpload} variant='contained'>
              {file?.name ? 'Удалить файл' : 'Выбрать файл'}
            </CustomButton>
            <Typography fontSize={18} fontWeight={700}>
              {file?.name}
            </Typography>
          </Stack>
          <input ref={hiddenFileInput} onChange={handleChangeFile} type='file' hidden />
        </Box>
        <Stack direction='row' gap={4} justifyContent='flex-end'>
          <Button variant='text' sx={{ textTransform: 'capitalize' }} onClick={handleCancel}>
            Отмена
          </Button>
          <CustomButton disabled={!file || status !== 'success'} sx={{ textTransform: 'capitalize' }} onClick={addProjectFile}>
            {status === 'init' || status === 'loading' ? 'Загрузка ...' : 'Выбрать'}
          </CustomButton>
        </Stack>
      </Stack>
    </CustomModal>
  )
}
