import { FC, memo, useCallback, useState } from 'react'
import { toast } from 'react-toastify'
import { BasicModal } from 'components/Modals/BasicModal/BasicModal'
import { fetchAddProject } from 'service/Projects'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { projectActions } from 'store/reducers/ProjectSlice'
import { elementsModals } from './helpers/elementsModals'
import { ProjectRequestsBodyModel } from 'models/requests/project-requests.model'
import { Validation } from 'helpers/validation'
import { getCreateProjectParams } from 'helpers/projectParams/getCreateProjectParams'

export const AddProjectModal: FC = memo(() => {
  const currentOrganization = useAppSelector(state => state.organizationReducer.currentOrganization)
  const isModalOpen = useAppSelector(state => state.projectReducer.isModalOpen)
  const isFetchProjects = useAppSelector(state => state.projectReducer.isFetchProjects)
  const stateModal = useAppSelector(state => state.projectReducer.stateModal)

  const [initalState] = useState(stateModal)

  const dispatch = useAppDispatch()

  const handleAddProject = useCallback(
    (param: ProjectRequestsBodyModel.post) => {
      if (!currentOrganization) return

      if (!Validation.text(param.name)) {
        toast.error('Не валидное имя проекта')
        return
      }

      const params = getCreateProjectParams(param)

      dispatch(fetchAddProject({ ...params, organizationId: currentOrganization.id })).then(() => {
        dispatch(projectActions.projectReset())
        dispatch(projectActions.setIsFetchProjects(isFetchProjects))
        toast.success(`Проект ${param.name} добавлен`)
      })
      dispatch(projectActions.setIsModalOpen(false))
    },
    [currentOrganization],
  )

  return (
    <BasicModal
      open={isModalOpen}
      setOpen={projectActions.setIsModalOpen}
      title='Добавить проект'
      options={{
        roleId: [],
        positionId: [],
        projectId: [],
      }}
      elements={elementsModals}
      handleSave={handleAddProject}
      initialState={initalState}
      redux
    />
  )
})
