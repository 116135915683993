import { ChangeEvent } from 'react'
import { Box } from '@mui/material'

import { SortByTypeBasic } from 'components/SortByTypeBasic/SortByTypeBasic'
import { ShiftTypeCondition } from 'models/requests/shift-requests.model'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { shiftsActions } from 'store/reducers/ShiftsSlice'

export const StatisticsActionsChangeShiftType = () => {
  const dispatch = useAppDispatch()
  const type = useAppSelector(state => state.shiftsReducer.type)
  const isEditStatuses = useAppSelector(state => state.statusReducer.isEditStatuses)

  const handleChangeShiftType = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(shiftsActions.setStatus('init'))
    dispatch(shiftsActions.setShiftConditionType(Number(e.target.value)))
  }

  return (
    <Box height={54}>
      {SortByTypeBasic({
        name: 'type',
        label: 'Смены',
        type: 'select',
        value: type,
        disabled: isEditStatuses,
        onChange: handleChangeShiftType,
        options: [
          { value: ShiftTypeCondition.Day, text: 'Дневная' },
          { value: ShiftTypeCondition.Night, text: 'Ночная' },
          // { value: ShiftTypeCondition.All, text: 'Дневная/ночная' },
        ],
      })}
    </Box>
  )
}
