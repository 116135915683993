import { useEffect, useCallback, memo } from 'react'
import { ParsedQuery, parse } from 'query-string'
import { useLocation } from 'react-router-dom'
import { Typography } from '@mui/material'
import { toast } from 'react-toastify'

import { StatisticsActions } from './modules/StatisticsActions/StatisticsActions'
import { StatisticsInfo } from './modules/StatisticsInfo/StatisticsInfo'
import { StatisticsTable } from './modules/StatisticsTable/StatisticsTable'
import { useActionCreatorsTyped, useAppDispatch, useAppSelector } from 'hooks/redux'
import { ReportTime } from 'helpers/timeHelpers'
import { shiftsActions } from 'store/reducers/ShiftsSlice'
import { ShiftRequestsBodyModel } from 'models/requests/shift-requests.model'
import { getShiftsByFilter } from 'service/Shifts'
import { CustomLoader } from 'components/UI/CustomLoader/CustomLoader'
import { getStatuses } from 'service/Status'
import { StatisticsShiftsModals } from './modules/StatisticsShiftsModals/StatisticsShiftsModals'
import { useCheckClaims } from 'hooks/useCheckClaims'
import { fetchProjectById } from 'service/Projects'

export const Statistics = memo(() => {
  const period = useAppSelector(state => state.shiftsReducer.period)
  const type = useAppSelector(state => state.shiftsReducer.type)
  const status = useAppSelector(state => state.shiftsReducer.status)
  const page = useAppSelector(state => state.shiftsReducer.page)
  const isUpdateShift = useAppSelector(state => state.shiftsReducer.isUpdateShift)
  const shifts = useAppSelector(state => state.shiftsReducer.shifts)
  const currentOrganization = useAppSelector(state => state.organizationReducer.currentOrganization)

  const location = useLocation()

  const { checkClaims } = useCheckClaims()

  const searchParams: ParsedQuery<string> = parse(location.search)

  const dispatch = useAppDispatch()

  const actionsShifts = useActionCreatorsTyped(shiftsActions)

  useEffect(() => {
    const getShifts = async () => {
      if (
        !period.start ||
        !period.end ||
        !ReportTime.checkBetweenMinAndMax(period.start, period.end) ||
        !ReportTime.check(period.start, period.end)
      ) {
        toast.error(`Неправильная дата указана!`)
        return
      }

      actionsShifts.setIntervalData(ReportTime.getBetweenDays(period.start, period.end))

      let params: ShiftRequestsBodyModel.postFilter = {
        organizationIds: currentOrganization ? [currentOrganization.id] : [Number(searchParams?.orgId)],
        employeeProjectIds: searchParams.employeeId ? [Number(searchParams.employeeId)] : undefined,
        projectIds: searchParams.projectId ? [Number(searchParams.projectId)] : undefined,
        period,
        completeness: 0,
        type,
      }

      await dispatch(getShiftsByFilter({ page }, params))
    }
    getShifts()
  }, [type, isUpdateShift, period, location.pathname])

  useEffect(() => {
    checkClaims('Back.status.PostByFilter') && dispatch(getStatuses())
    searchParams.projectId && dispatch(fetchProjectById(Number(searchParams.projectId)))
  })

  const renderStatistics = useCallback(() => {
    if (status === 'init' || status === 'loading') return <CustomLoader />
    return shifts && shifts.length ? (
      <>
        <StatisticsInfo />
        <StatisticsTable />
      </>
    ) : (
      <Typography variant='h4'>Нет данных</Typography>
    )
  }, [status, shifts])

  return (
    <>
      <Typography fontSize={24} mb={3}>
        Статистика
      </Typography>
      {shifts && shifts.length ? <StatisticsActions /> : null}
      {renderStatistics()}
      <StatisticsShiftsModals />
    </>
  )
})
