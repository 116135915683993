import { qrAuthPutCurrentUser } from '../api/utils/userApi'
import { AppDispatch } from '../store/store'
import { currentUserActions, currentUserSlice } from '../store/reducers/CurrentUserSlice'
import { UserRequestsBodyModel } from '../models/requests/user-requests.model'

export const editCurrentUserService = (params: UserRequestsBodyModel.putCurrentUser) => async (dispatch: AppDispatch) => {
  try {
    dispatch(currentUserActions.setStatus('loading'))

    const response = await qrAuthPutCurrentUser(params)

    // const data = response.data.items

    // const user: ICurrentUser = {
    //   id: data.id,
    //   name: data.name,
    //   surname: data.surname,
    //   patronymic: data.patronymic,
    //   userName: data.userName,
    //   email: data.email,
    //   phoneNumber: data.phoneNumber,
    //   role: data.role,
    //   isDeleted: false,
    //   profilePhoto: data.profilePhoto || null,
    // }

    dispatch(currentUserSlice.actions.editCurrentUser(response.data.items))

    return Promise.resolve(response)
  } catch (e) {
    dispatch(currentUserActions.setStatus('error'))
    return Promise.reject(e)
  } finally {
    dispatch(currentUserActions.setStatus('success'))
  }
}
