interface CountryType {
  code: string;
  label: string;
  phone: string;
  suggested?: boolean;
  localeRU: string;
}


// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
export const countries: readonly CountryType[] = [
  {
    code: 'AD',
    label: 'Andorra',
    localeRU: 'Андорра',
    phone: '376'
  },
  {
    code: 'AE',
    label: 'United Arab Emirates',
    localeRU: 'Объединенные Арабские Эмираты',
    phone: '971'
  },
  {
    code: 'AF',
    label: 'Afghanistan',
    localeRU: 'Афганистан',
    phone: '93'
  },
  {
    code: 'AG',
    label: 'Antigua and Barbuda',
    localeRU: 'Антигуа и Барбуда',
    phone: '1-268'
  },
  {
    code: 'AI',
    label: 'Anguilla',
    localeRU: 'Ангилья',
    phone: '1-264'
  },
  {
    code: 'AL',
    label: 'Albania',
    localeRU: 'Албания',
    phone: '355'
  },
  {
    code: 'AM',
    label: 'Armenia',
    localeRU: 'Армения',
    phone: '374'
  },
  {
    code: 'AO',
    label: 'Angola',
    localeRU: 'Ангола',
    phone: '244'
  },
  {
    code: 'AQ',
    label: 'Antarctica',
    localeRU: 'Антарктика',
    phone: '672'
  },
  {
    code: 'AR',
    label: 'Argentina',
    localeRU: 'Аргентина',
    phone: '54'
  },
  {
    code: 'AS',
    label: 'American Samoa',
    localeRU: 'Американское Самоа',
    phone: '1-684'
  },
  {
    code: 'AT',
    label: 'Austria',
    localeRU: 'Австрия',
    phone: '43'
  },
  {
    code: 'AU',
    label: 'Australia',
    localeRU: 'Австралия',
    phone: '61'
  },
  {
    code: 'AW',
    label: 'Aruba',
    localeRU: 'Аруба',
    phone: '297'
  },
  {
    code: 'AX',
    label: 'Alland Islands',
    localeRU: 'Аландские острова',
    phone: '358'
  },
  {
    code: 'AZ',
    label: 'Azerbaijan',
    localeRU: 'Азербайджан',
    phone: '994'
  },
  {
    code: 'BA',
    label: 'Bosnia and Herzegovina',
    localeRU: 'Босния и Герцеговина',
    phone: '387'
  },
  {
    code: 'BB',
    label: 'Barbados',
    localeRU: 'Барбадос',
    phone: '1-246'
  },
  {
    code: 'BD',
    label: 'Bangladesh',
    localeRU: 'Бангладеш',
    phone: '880'
  },
  {
    code: 'BE',
    label: 'Belgium',
    localeRU: 'Бельгия',
    phone: '32'
  },
  {
    code: 'BF',
    label: 'Burkina Faso',
    localeRU: 'Буркина-Фасо',
    phone: '226'
  },
  {
    code: 'BG',
    label: 'Bulgaria',
    localeRU: 'Болгария',
    phone: '359'
  },
  {
    code: 'BH',
    label: 'Bahrain',
    localeRU: 'Бахрейн',
    phone: '973'
  },
  {
    code: 'BI',
    label: 'Burundi',
    localeRU: 'Бурунди',
    phone: '257'
  },
  {
    code: 'BJ',
    label: 'Benin',
    localeRU: 'Бенин',
    phone: '229'
  },
  {
    code: 'BL',
    label: 'Saint Barthelemy',
    localeRU: 'Сен-Бартельми',
    phone: '590'
  },
  {
    code: 'BM',
    label: 'Bermuda',
    localeRU: 'Бермуды',
    phone: '1-441'
  },
  {
    code: 'BN',
    label: 'Brunei Darussalam',
    localeRU: 'Бруней-Даруссалам',
    phone: '673'
  },
  {
    code: 'BO',
    label: 'Bolivia',
    localeRU: 'Боливия',
    phone: '591'
  },
  {
    code: 'BR',
    label: 'Brazil',
    localeRU: 'Бразилия',
    phone: '55'
  },
  {
    code: 'BS',
    label: 'Bahamas',
    localeRU: 'Багамы',
    phone: '1-242'
  },
  {
    code: 'BT',
    label: 'Bhutan',
    localeRU: 'Бутан',
    phone: '975'
  },
  {
    code: 'BV',
    label: 'Bouvet Island',
    localeRU: 'Остров Буве',
    phone: '47'
  },
  {
    code: 'BW',
    label: 'Botswana',
    localeRU: 'Ботсвана',
    phone: '267'
  },
  {
    code: 'BY',
    label: 'Belarus',
    localeRU: 'Беларусь',
    phone: '375'
  },
  {
    code: 'BZ',
    label: 'Belize',
    localeRU: 'Белиз',
    phone: '501'
  },
  {
    code: 'CA',
    label: 'Canada',
    localeRU: 'Канада',
    phone: '1'
  },
  {
    code: 'CC',
    label: 'Cocos (Keeling) Islands',
    localeRU: 'Кокосовые (Килинг) острова',
    phone: '61'
  },
  {
    code: 'CD',
    label: 'Congo, Democratic Republic of the',
    localeRU: 'Конго, Демократическая Республика',
    phone: '243'
  },
  {
    code: 'CF',
    label: 'Central African Republic',
    localeRU: 'Центрально-Африканская Республика',
    phone: '236'
  },
  {
    code: 'CG',
    label: 'Congo, Republic of the',
    localeRU: 'Республика Конго',
    phone: '242'
  },
  {
    code: 'CH',
    label: 'Switzerland',
    localeRU: 'Швейцария',
    phone: '41'
  },
  {
    code: 'CI',
    label: 'Cote d\'Ivoire',
    localeRU: 'Кот-д’Ивуар',
    phone: '225'
  },
  {
    code: 'CK',
    label: 'Cook Islands',
    localeRU: 'Острова Кука',
    phone: '682'
  },
  {
    code: 'CL',
    label: 'Chile',
    localeRU: 'Чили',
    phone: '56'
  },
  {
    code: 'CM',
    label: 'Cameroon',
    localeRU: 'Камерун',
    phone: '237'
  },
  {
    code: 'CN',
    label: 'China',
    localeRU: 'Китай',
    phone: '86'
  },
  {
    code: 'CO',
    label: 'Colombia',
    localeRU: 'Колумбия',
    phone: '57'
  },
  {
    code: 'CR',
    label: 'Costa Rica',
    localeRU: 'Коста-Рика',
    phone: '506'
  },
  {
    code: 'CU',
    label: 'Cuba',
    localeRU: 'Куба',
    phone: '53'
  },
  {
    code: 'CV',
    label: 'Cape Verde',
    localeRU: 'Кабо-Верде',
    phone: '238'
  },
  {
    code: 'CW',
    label: 'Curacao',
    localeRU: 'Кюрасао',
    phone: '599'
  },
  {
    code: 'CX',
    label: 'Christmas Island',
    localeRU: 'Остров Рождества',
    phone: '61'
  },
  {
    code: 'CY',
    label: 'Cyprus',
    localeRU: 'Кипр',
    phone: '357'
  },
  {
    code: 'CZ',
    label: 'Czech Republic',
    localeRU: 'Чешская Республика',
    phone: '420'
  },
  {
    code: 'DE',
    label: 'Germany',
    localeRU: 'Германия',
    phone: '49'
  },
  {
    code: 'DJ',
    label: 'Djibouti',
    localeRU: 'Джибути',
    phone: '253'
  },
  {
    code: 'DK',
    label: 'Denmark',
    localeRU: 'Дания',
    phone: '45'
  },
  {
    code: 'DM',
    label: 'Dominica',
    localeRU: 'Доминика',
    phone: '1-767'
  },
  {
    code: 'DO',
    label: 'Dominican Republic',
    localeRU: 'Доминиканская Республика',
    phone: '1-809'
  },
  {
    code: 'DZ',
    label: 'Algeria',
    localeRU: 'Алжир',
    phone: '213'
  },
  {
    code: 'EC',
    label: 'Ecuador',
    localeRU: 'Эквадор',
    phone: '593'
  },
  {
    code: 'EE',
    label: 'Estonia',
    localeRU: 'Эстония',
    phone: '372'
  },
  {
    code: 'EG',
    label: 'Egypt',
    localeRU: 'Египет',
    phone: '20'
  },
  {
    code: 'EH',
    label: 'Western Sahara',
    localeRU: 'Западная Сахара',
    phone: '212'
  },
  {
    code: 'ER',
    label: 'Eritrea',
    localeRU: 'Эритрея',
    phone: '291'
  },
  {
    code: 'ES',
    label: 'Spain',
    localeRU: 'Испания',
    phone: '34'
  },
  {
    code: 'ET',
    label: 'Ethiopia',
    localeRU: 'Эфиопия',
    phone: '251'
  },
  {
    code: 'FI',
    label: 'Finland',
    localeRU: 'Финляндия',
    phone: '358'
  },
  {
    code: 'FJ',
    label: 'Fiji',
    localeRU: 'Фиджи',
    phone: '679'
  },
  {
    code: 'FK',
    label: 'Falkland Islands (Malvinas)',
    localeRU: 'Фолклендские (Мальвинские) острова',
    phone: '500'
  },
  {
    code: 'FM',
    label: 'Micronesia, Federated States of',
    localeRU: 'Микронезия, Федеративные Штаты',
    phone: '691'
  },
  {
    code: 'FO',
    label: 'Faroe Islands',
    localeRU: 'Фарерские острова',
    phone: '298'
  },
  {
    code: 'FR',
    label: 'France',
    localeRU: 'Франция',
    phone: '33'
  },
  {
    code: 'GA',
    label: 'Gabon',
    localeRU: 'Габон',
    phone: '241'
  },
  {
    code: 'GB',
    label: 'United Kingdom',
    localeRU: 'Соединенное Королевство',
    phone: '44'
  },
  {
    code: 'GD',
    label: 'Grenada',
    localeRU: 'Гренада',
    phone: '1-473'
  },
  {
    code: 'GE',
    label: 'Georgia',
    localeRU: 'Грузия',
    phone: '995'
  },
  {
    code: 'GF',
    label: 'French Guiana',
    localeRU: 'Французская Гвиана',
    phone: '594'
  },
  {
    code: 'GG',
    label: 'Guernsey',
    localeRU: 'Гернси',
    phone: '44'
  },
  {
    code: 'GH',
    label: 'Ghana',
    localeRU: 'Гана',
    phone: '233'
  },
  {
    code: 'GI',
    label: 'Gibraltar',
    localeRU: 'Гибралтар',
    phone: '350'
  },
  {
    code: 'GL',
    label: 'Greenland',
    localeRU: 'Гренландия',
    phone: '299'
  },
  {
    code: 'GM',
    label: 'Gambia',
    localeRU: 'Гамбия',
    phone: '220'
  },
  {
    code: 'GN',
    label: 'Guinea',
    localeRU: 'Гвинея',
    phone: '224'
  },
  {
    code: 'GP',
    label: 'Guadeloupe',
    localeRU: 'Гваделупа',
    phone: '590'
  },
  {
    code: 'GQ',
    label: 'Equatorial Guinea',
    localeRU: 'Экваториальная Гвинея',
    phone: '240'
  },
  {
    code: 'GR',
    label: 'Greece',
    localeRU: 'Греция',
    phone: '30'
  },
  {
    code: 'GS',
    label: 'South Georgia and the South Sandwich Islands',
    localeRU: 'Южная Георгия и Южные Сандвичевы острова',
    phone: '500'
  },
  {
    code: 'GT',
    label: 'Guatemala',
    localeRU: 'Гватемала',
    phone: '502'
  },
  {
    code: 'GU',
    label: 'Guam',
    localeRU: 'Гуам',
    phone: '1-671'
  },
  {
    code: 'GW',
    label: 'Guinea-Bissau',
    localeRU: 'Гвинея-Бисау',
    phone: '245'
  },
  {
    code: 'GY',
    label: 'Guyana',
    localeRU: 'Гайана',
    phone: '592'
  },
  {
    code: 'HK',
    label: 'Hong Kong',
    localeRU: 'Гонконг',
    phone: '852'
  },
  {
    code: 'HM',
    label: 'Heard Island and McDonald Islands',
    localeRU: 'Остров Херд и острова Макдональдс',
    phone: '672'
  },
  {
    code: 'HN',
    label: 'Honduras',
    localeRU: 'Гондурас',
    phone: '504'
  },
  {
    code: 'HR',
    label: 'Croatia',
    localeRU: 'Хорватия',
    phone: '385'
  },
  {
    code: 'HT',
    label: 'Haiti',
    localeRU: 'Гаити',
    phone: '509'
  },
  {
    code: 'HU',
    label: 'Hungary',
    localeRU: 'Венгрия',
    phone: '36'
  },
  {
    code: 'ID',
    label: 'Indonesia',
    localeRU: 'Индонезия',
    phone: '62'
  },
  {
    code: 'IE',
    label: 'Ireland',
    localeRU: 'Ирландия',
    phone: '353'
  },
  {
    code: 'IM',
    label: 'Isle of Man',
    localeRU: 'Остров Мэн',
    phone: '44'
  },
  {
    code: 'IN',
    label: 'India',
    localeRU: 'Индия',
    phone: '91'
  },
  {
    code: 'IO',
    label: 'British Indian Ocean Territory',
    localeRU: 'Британская территория Индийского океана',
    phone: '246'
  },
  {
    code: 'IQ',
    label: 'Iraq',
    localeRU: 'Ирак',
    phone: '964'
  },
  {
    code: 'IR',
    label: 'Iran, Islamic Republic of',
    localeRU: 'Иранская Исламская Республика',
    phone: '98'
  },
  {
    code: 'IS',
    label: 'Iceland',
    localeRU: 'Исландия',
    phone: '354'
  },
  {
    code: 'IT',
    label: 'Italy',
    localeRU: 'Италия',
    phone: '39'
  },
  {
    code: 'JE',
    label: 'Jersey',
    localeRU: 'Джерси',
    phone: '44'
  },
  {
    code: 'JM',
    label: 'Jamaica',
    localeRU: 'Ямайка',
    phone: '1-876'
  },
  {
    code: 'JO',
    label: 'Jordan',
    localeRU: 'Иордания',
    phone: '962'
  },
  {
    code: 'JP',
    label: 'Japan',
    localeRU: 'Япония',
    phone: '81'
  },
  {
    code: 'KE',
    label: 'Kenya',
    localeRU: 'Кения',
    phone: '254'
  },
  {
    code: 'KG',
    label: 'Kyrgyzstan',
    localeRU: 'Киргизия',
    phone: '996'
  },
  {
    code: 'KH',
    label: 'Cambodia',
    localeRU: 'Камбоджа',
    phone: '855'
  },
  {
    code: 'KI',
    label: 'Kiribati',
    localeRU: 'Кирибати',
    phone: '686'
  },
  {
    code: 'KM',
    label: 'Comoros',
    localeRU: 'Коморы',
    phone: '269'
  },
  {
    code: 'KN',
    label: 'Saint Kitts and Nevis',
    localeRU: 'Сент-Китс и Невис',
    phone: '1-869'
  },
  {
    code: 'KP',
    label: 'Korea, Democratic People\'s Republic of',
    localeRU: 'Корея, Народно-Демократическая Республика',
    phone: '850'
  },
  {
    code: 'KR',
    label: 'Korea, Republic of',
    localeRU: 'Южняя Корея',
    phone: '82'
  },
  {
    code: 'KW',
    label: 'Kuwait',
    localeRU: 'Кувейт',
    phone: '965'
  },
  {
    code: 'KY',
    label: 'Cayman Islands',
    localeRU: 'Каймановы острова',
    phone: '1-345'
  },
  {
    code: 'KZ',
    label: 'Kazakhstan',
    localeRU: 'Казахстан',
    phone: '7'
  },
  {
    code: 'LA',
    label: 'Lao People\'s Democratic Republic',
    localeRU: 'Лаосская Народно-Демократическая Республика',
    phone: '856'
  },
  {
    code: 'LB',
    label: 'Lebanon',
    localeRU: 'Ливан',
    phone: '961'
  },
  {
    code: 'LC',
    label: 'Saint Lucia',
    localeRU: 'Сент-Люсия',
    phone: '1-758'
  },
  {
    code: 'LI',
    label: 'Liechtenstein',
    localeRU: 'Лихтенштейн',
    phone: '423'
  },
  {
    code: 'LK',
    label: 'Sri Lanka',
    localeRU: 'Шри-Ланка',
    phone: '94'
  },
  {
    code: 'LR',
    label: 'Liberia',
    localeRU: 'Либерия',
    phone: '231'
  },
  {
    code: 'LS',
    label: 'Lesotho',
    localeRU: 'Лесото',
    phone: '266'
  },
  {
    code: 'LT',
    label: 'Lithuania',
    localeRU: 'Литва',
    phone: '370'
  },
  {
    code: 'LU',
    label: 'Luxembourg',
    localeRU: 'Люксембург',
    phone: '352'
  },
  {
    code: 'LV',
    label: 'Latvia',
    localeRU: 'Латвия',
    phone: '371'
  },
  {
    code: 'LY',
    label: 'Libya',
    localeRU: 'Ливия',
    phone: '218'
  },
  {
    code: 'MA',
    label: 'Morocco',
    localeRU: 'Марокко',
    phone: '212'
  },
  {
    code: 'MC',
    label: 'Monaco',
    localeRU: 'Монако',
    phone: '377'
  },
  {
    code: 'MD',
    label: 'Moldova, Republic of',
    localeRU: 'Республика Молдова',
    phone: '373'
  },
  {
    code: 'ME',
    label: 'Montenegro',
    localeRU: 'Черногория',
    phone: '382'
  },
  {
    code: 'MF',
    label: 'Saint Martin (French part)',
    localeRU: 'Сен-Мартен (французская часть)',
    phone: '590'
  },
  {
    code: 'MG',
    label: 'Madagascar',
    localeRU: 'Мадагаскар',
    phone: '261'
  },
  {
    code: 'MH',
    label: 'Marshall Islands',
    localeRU: 'Маршалловы острова',
    phone: '692'
  },
  {
    code: 'MK',
    label: 'Macedonia',
    localeRU: 'Македония',
    phone: '389'
  },
  {
    code: 'ML',
    label: 'Mali',
    localeRU: 'Мали',
    phone: '223'
  },
  {
    code: 'MM',
    label: 'Myanmar',
    localeRU: 'Мьянма',
    phone: '95'
  },
  {
    code: 'MN',
    label: 'Mongolia',
    localeRU: 'Монголия',
    phone: '976'
  },
  {
    code: 'MO',
    label: 'Macao',
    localeRU: 'Макао',
    phone: '853'
  },
  {
    code: 'MP',
    label: 'Northern Mariana Islands',
    localeRU: 'Северные Марианские острова',
    phone: '1-670'
  },
  {
    code: 'MQ',
    label: 'Martinique',
    localeRU: 'Мартиника',
    phone: '596'
  },
  {
    code: 'MR',
    label: 'Mauritania',
    localeRU: 'Мавритания',
    phone: '222'
  },
  {
    code: 'MS',
    label: 'Montserrat',
    localeRU: 'Монтсеррат',
    phone: '1-664'
  },
  {
    code: 'MT',
    label: 'Malta',
    localeRU: 'Мальта',
    phone: '356'
  },
  {
    code: 'MU',
    label: 'Mauritius',
    localeRU: 'Маврикий',
    phone: '230'
  },
  {
    code: 'MV',
    label: 'Maldives',
    localeRU: 'Мальдивы',
    phone: '960'
  },
  {
    code: 'MW',
    label: 'Malawi',
    localeRU: 'Малави',
    phone: '265'
  },
  {
    code: 'MX',
    label: 'Mexico',
    localeRU: 'Мексика',
    phone: '52'
  },
  {
    code: 'MY',
    label: 'Malaysia',
    localeRU: 'Малайзия',
    phone: '60'
  },
  {
    code: 'MZ',
    label: 'Mozambique',
    localeRU: 'Мозамбик',
    phone: '258'
  },
  {
    code: 'NA',
    label: 'Namibia',
    localeRU: 'Намибия',
    phone: '264'
  },
  {
    code: 'NC',
    label: 'New Caledonia',
    localeRU: 'Новая Каледония',
    phone: '687'
  },
  {
    code: 'NE',
    label: 'Niger',
    localeRU: 'Нигер',
    phone: '227'
  },
  {
    code: 'NF',
    label: 'Norfolk Island',
    localeRU: 'Остров Норфолк',
    phone: '672'
  },
  {
    code: 'NG',
    label: 'Nigeria',
    localeRU: 'Нигерия',
    phone: '234'
  },
  {
    code: 'NI',
    label: 'Nicaragua',
    localeRU: 'Никарагуа',
    phone: '505'
  },
  {
    code: 'NL',
    label: 'Netherlands',
    localeRU: 'Нидерланды',
    phone: '31'
  },
  {
    code: 'NO',
    label: 'Norway',
    localeRU: 'Норвегия',
    phone: '47'
  },
  {
    code: 'NP',
    label: 'Nepal',
    localeRU: 'Непал',
    phone: '977'
  },
  {
    code: 'NR',
    label: 'Nauru',
    localeRU: 'Науру',
    phone: '674'
  },
  {
    code: 'NU',
    label: 'Niue',
    localeRU: 'Ниуэ',
    phone: '683'
  },
  {
    code: 'NZ',
    label: 'New Zealand',
    localeRU: 'Новая Зеландия',
    phone: '64'
  },
  {
    code: 'OM',
    label: 'Oman',
    localeRU: 'Оман',
    phone: '968'
  },
  {
    code: 'PA',
    label: 'Panama',
    localeRU: 'Панама',
    phone: '507'
  },
  {
    code: 'PE',
    label: 'Peru',
    localeRU: 'Перу',
    phone: '51'
  },
  {
    code: 'PF',
    label: 'French Polynesia',
    localeRU: 'Французская Полинезия',
    phone: '689'
  },
  {
    code: 'PG',
    label: 'Papua New Guinea',
    localeRU: 'Папуа - Новая Гвинея',
    phone: '675'
  },
  {
    code: 'PH',
    label: 'Philippines',
    localeRU: 'Филиппины',
    phone: '63'
  },
  {
    code: 'PK',
    label: 'Pakistan',
    localeRU: 'Пакистан',
    phone: '92'
  },
  {
    code: 'PL',
    label: 'Poland',
    localeRU: 'Польша',
    phone: '48'
  },
  {
    code: 'PM',
    label: 'Saint Pierre and Miquelon',
    localeRU: 'Сен-Пьер и Микелон',
    phone: '508'
  },
  {
    code: 'PN',
    label: 'Pitcairn',
    localeRU: 'Питкэрн',
    phone: '870'
  },
  {
    code: 'PR',
    label: 'Puerto Rico',
    localeRU: 'Пуэрто-Рико',
    phone: '1'
  },
  {
    code: 'PS',
    label: 'Palestine, State of',
    localeRU: 'Палестин',
    phone: '970'
  },
  {
    code: 'PT',
    label: 'Portugal',
    localeRU: 'Португалия',
    phone: '351'
  },
  {
    code: 'PW',
    label: 'Palau',
    localeRU: 'Палау',
    phone: '680'
  },
  {
    code: 'PY',
    label: 'Paraguay',
    localeRU: 'Парагвай',
    phone: '595'
  },
  {
    code: 'QA',
    label: 'Qatar',
    localeRU: 'Катар',
    phone: '974'
  },
  {
    code: 'RE',
    label: 'Reunion',
    localeRU: 'Реюньон',
    phone: '262'
  },
  {
    code: 'RO',
    label: 'Romania',
    localeRU: 'Румыния',
    phone: '40'
  },
  {
    code: 'RS',
    label: 'Serbia',
    localeRU: 'Сербия',
    phone: '381'
  },
  {
    code: 'RU',
    label: 'Russian Federation',
    localeRU: 'Российская Федерация',
    phone: '7'
  },
  {
    code: 'RW',
    label: 'Rwanda',
    localeRU: 'Руанда',
    phone: '250'
  },
  {
    code: 'SA',
    label: 'Saudi Arabia',
    localeRU: 'Саудовская Аравия',
    phone: '966'
  },
  {
    code: 'SB',
    label: 'Solomon Islands',
    localeRU: 'Соломоновы острова',
    phone: '677'
  },
  {
    code: 'SC',
    label: 'Seychelles',
    localeRU: 'Сейшелы',
    phone: '248'
  },
  {
    code: 'SD',
    label: 'Sudan',
    localeRU: 'Судан',
    phone: '249'
  },
  {
    code: 'SE',
    label: 'Sweden',
    localeRU: 'Швеция',
    phone: '46'
  },
  {
    code: 'SG',
    label: 'Singapore',
    localeRU: 'Сингапур',
    phone: '65'
  },
  {
    code: 'SH',
    label: 'Saint Helena',
    localeRU: 'Святая Елена',
    phone: '290'
  },
  {
    code: 'SI',
    label: 'Slovenia',
    localeRU: 'Словения',
    phone: '386'
  },
  {
    code: 'SJ',
    label: 'Svalbard and Jan Mayen',
    localeRU: 'Шпицберген и Ян-Майен',
    phone: '47'
  },
  {
    code: 'SK',
    label: 'Slovakia',
    localeRU: 'Словакия',
    phone: '421'
  },
  {
    code: 'SL',
    label: 'Sierra Leone',
    localeRU: 'Сьерра-Леоне',
    phone: '232'
  },
  {
    code: 'SM',
    label: 'San Marino',
    localeRU: 'Сан-Марино',
    phone: '378'
  },
  {
    code: 'SN',
    label: 'Senegal',
    localeRU: 'Сенегал',
    phone: '221'
  },
  {
    code: 'SO',
    label: 'Somalia',
    localeRU: 'Сомали',
    phone: '252'
  },
  {
    code: 'SR',
    label: 'Suriname',
    localeRU: 'Суринам',
    phone: '597'
  },
  {
    code: 'SS',
    label: 'South Sudan',
    localeRU: 'Южный Судан',
    phone: '211'
  },
  {
    code: 'ST',
    label: 'Sao Tome and Principe',
    localeRU: 'Сан-Томе и Принсипи',
    phone: '239'
  },
  {
    code: 'SV',
    label: 'El Salvador',
    localeRU: 'Эль Сальвадор',
    phone: '503'
  },
  {
    code: 'SX',
    label: 'Sint Maarten (Dutch part)',
    localeRU: 'Синт-Мартен (голландская часть)',
    phone: '1-721'
  },
  {
    code: 'SY',
    label: 'Syrian Arab Republic',
    localeRU: 'Сирийская Арабская Республика',
    phone: '963'
  },
  {
    code: 'SZ',
    label: 'Swaziland',
    localeRU: 'Свазиленд',
    phone: '268'
  },
  {
    code: 'TC',
    label: 'Острова Теркс и Кайкос',
    localeRU: '',
    phone: '1-649'
  },
  {
    code: 'TD',
    label: 'Chad',
    localeRU: 'Чад',
    phone: '235'
  },
  {
    code: 'TF',
    label: 'French Southern Territories',
    localeRU: 'Южные Французские Территории',
    phone: '262'
  },
  {
    code: 'TG',
    label: 'Togo',
    localeRU: 'Того',
    phone: '228'
  },
  {
    code: 'TH',
    label: 'Thailand',
    localeRU: 'Таиланд',
    phone: '66'
  },
  {
    code: 'TJ',
    label: 'Tajikistan',
    localeRU: 'Таджикистан',
    phone: '992'
  },
  {
    code: 'TK',
    label: 'Tokelau',
    localeRU: 'Токелау',
    phone: '690'
  },
  {
    code: 'TL',
    label: 'Timor-Leste',
    localeRU: 'Тимор-Лешти',
    phone: '670'
  },
  {
    code: 'TM',
    label: 'Turkmenistan',
    localeRU: 'Туркменистан',
    phone: '993'
  },
  {
    code: 'TN',
    label: 'Tunisia',
    localeRU: 'Тунис',
    phone: '216'
  },
  {
    code: 'TO',
    label: 'Tonga',
    localeRU: 'Тонга',
    phone: '676'
  },
  {
    code: 'TR',
    label: 'Turkey',
    localeRU: 'Турция',
    phone: '90'
  },
  {
    code: 'TT',
    label: 'Trinidad and Tobago',
    localeRU: 'Тринидад и Тобаго',
    phone: '1-868'
  },
  {
    code: 'TV',
    label: 'Tuvalu',
    localeRU: 'Тувалу',
    phone: '688'
  },
  {
    code: 'TW',
    label: 'Taiwan, Province of China',
    localeRU: 'Тайвань',
    phone: '886'
  },
  {
    code: 'TZ',
    label: 'United Republic of Tanzania',
    localeRU: 'Объединенная Республика Танзания',
    phone: '255'
  },
  {
    code: 'UA',
    label: 'Ukraine',
    localeRU: 'Украина',
    phone: '380'
  },
  {
    code: 'UG',
    label: 'Uganda',
    localeRU: 'Уганда',
    phone: '256'
  },
  {
    code: 'US',
    label: 'United States',
    localeRU: 'Соединенные Штаты',
    phone: '1',
  },
  {
    code: 'UY',
    label: 'Uruguay',
    localeRU: 'Уругвай',
    phone: '598'
  },
  {
    code: 'UZ',
    label: 'Uzbekistan',
    localeRU: 'Узбекистан',
    phone: '998'
  },
  {
    code: 'VA',
    label: 'Holy See (Vatican City State)',
    localeRU: 'Ватикан',
    phone: '379'
  },
  {
    code: 'VC',
    label: 'Saint Vincent and the Grenadines',
    localeRU: 'Святой Винсент и Гренадины',
    phone: '1-784'
  },
  {
    code: 'VE',
    label: 'Venezuela',
    localeRU: 'Венесуэла',
    phone: '58'
  },
  {
    code: 'VG',
    label: 'British Virgin Islands',
    localeRU: 'Британские Виргинские острова',
    phone: '1-284'
  },
  {
    code: 'VI',
    label: 'US Virgin Islands',
    localeRU: 'Виргинские острова США',
    phone: '1-340'
  },
  {
    code: 'VN',
    label: 'Vietnam',
    localeRU: 'Вьетнам',
    phone: '84'
  },
  {
    code: 'VU',
    label: 'Vanuatu',
    localeRU: 'Вануату',
    phone: '678'
  },
  {
    code: 'WF',
    label: 'Wallis and Futuna',
    localeRU: 'Уоллис и Футуна',
    phone: '681'
  },
  {
    code: 'WS',
    label: 'Samoa',
    localeRU: 'Самоа',
    phone: '685'
  },
  {
    code: 'XK',
    label: 'Kosovo',
    localeRU: 'Косово',
    phone: '383'
  },
  {
    code: 'YE',
    label: 'Yemen',
    localeRU: 'Йемен',
    phone: '967'
  },
  {
    code: 'YT',
    label: 'Mayotte',
    localeRU: 'Майотта',
    phone: '262'
  },
  {
    code: 'ZA',
    label: 'South Africa',
    localeRU: 'Южная Африка',
    phone: '27'
  },
  {
    code: 'ZM',
    label: 'Zambia',
    localeRU: 'Замбия',
    phone: '260'
  },
  {
    code: 'ZW',
    label: 'Zimbabwe',
    localeRU: 'Зимбабве',
    phone: '263'
  }
];