import { qrAuthGetRoles } from '../api/utils/roleApi'
import { RoleRequestsBodyModel, RoleRequestsQueryModel } from '../models/requests/role-requests.model'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

export const fetchGetRoles = createAsyncThunk(
  `roles/fetchGetRoles`,
  async (data: { params?: RoleRequestsBodyModel.postFilter; query?: RoleRequestsQueryModel.postFilter }, { rejectWithValue, dispatch }) => {
    const { params, query } = data
    try {
      const response = await qrAuthGetRoles(params, query)
      return response.data.items
    } catch (error: any) {
      const err: AxiosError = error
      return rejectWithValue(err.message)
    }
  },
)

export const fetchGetRole = createAsyncThunk(
  `roles/fetchGetRole`,
  async (data: { params: RoleRequestsBodyModel.postFilter; query?: RoleRequestsQueryModel.postFilter }, { rejectWithValue, dispatch }) => {
    const { params, query } = data
    try {
      const response = await qrAuthGetRoles(params, query)
      return response.data.items[0]
    } catch (error: any) {
      const err: AxiosError = error
      return rejectWithValue(err.message)
    }
  },
)
