import React, { FC, useEffect } from 'react'
import { toast } from 'react-toastify'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'

import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { Footer } from '../../components/CustomLayout/Footer/Footer'
import { useCheckPath } from '../../hooks/useCheckPath'
import { backLogin } from '../../assets/images'
import { qrLoginParams } from '../../api/types'
import { userSignIn } from '../../service/SignIn'
import { MainTextField } from 'components/UI/TextFields/MainTextField/MainTextField'

export const LoginPage: FC = () => {
  const dispatch = useAppDispatch()

  const currentUser = useAppSelector(state => state.currentUserReducer.currentUser)
  const accessToken = useAppSelector(state => state.currentUserReducer.accessToken)

  const { handleMainPage } = useCheckPath()

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)

    const loginAndPassword: qrLoginParams = {
      username: data.get('email') as string,
      password: data.get('password') as string,
    }

    if (!loginAndPassword.username || !loginAndPassword.password) {
      toast.error('Заполните все поля!')
      return
    }

    dispatch(userSignIn(loginAndPassword)).catch((e: any) => console.log('error:', e))
  }

  useEffect(() => {
    if (!currentUser || !currentUser.id || !accessToken) return

    handleMainPage()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, accessToken])

  return (
    <Grid container component='main' sx={{ height: '100vh' }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={8}
        sx={{
          backgroundImage: `url(${backLogin})`,
          backgroundRepeat: 'no-repeat',
          backgroundColor: t => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <Grid
        item
        xs={12}
        sm={8}
        md={4}
        component={Paper}
        elevation={6}
        square
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            flex: '1 1 auto',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component='h1' variant='h5'>
              Войти
            </Typography>
            <Box component='form' noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <MainTextField margin='normal' required fullWidth id='email' label='Логин' name='email' autoComplete='email' autoFocus />
              <MainTextField
                margin='normal'
                required
                fullWidth
                name='password'
                label='Пароль'
                type='password'
                id='password'
                autoComplete='current-password'
              />
              <Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
                Войти
              </Button>
            </Box>
          </Box>
        </Box>
        <Footer sx={{ mt: 5 }} />
      </Grid>
    </Grid>
  )
}
