import { UserResponse } from './../../models/responses/user.response'
import { StatusType } from '../../models/common/common.model'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IState {
  workers: Array<UserResponse>
  activeWorker: UserResponse | null
  pageCount: number
  workerInfo: any | null
  cardWorkers: boolean
  status: StatusType
  workerId: number | null
}

const initialState: IState = {
  workers: [],
  pageCount: 1,
  workerInfo: null,
  activeWorker: null,
  cardWorkers: false,
  status: 'init',
  workerId: null,
}

export const workersSlice = createSlice({
  name: 'workersSlice',
  initialState,
  reducers: {
    workersDataFetch: (state, action: PayloadAction<UserResponse[]>) => {
      state.workers = [...state.workers, ...action.payload]
    },
    setWorkerId: (state, action: PayloadAction<number>) => {
      state.workerId = action.payload
    },
    setWorkerInfo: (state, action: PayloadAction<any>) => {
      state.workerInfo = action.payload
    },
    setStatus: (state, action: PayloadAction<StatusType>) => {
      state.status = action.payload
    },
    workersReset: state => {
      state.workers = []
      state.status = 'init'
    },
    setPageCount: (state, action: PayloadAction<number>) => {
      state.pageCount = action.payload
    },
    setActiveWorker: (state, action: PayloadAction<UserResponse>) => {
      state.activeWorker = action.payload
    },
  },
})

export const { reducer: workersReducer, actions: workersActions } = workersSlice
