import * as React from 'react'
import Box from '@mui/material/Box'
import Autocomplete from '@mui/material/Autocomplete'
import { countries } from './list'
import { MyStyledTextField } from '../../UI/TextFields/MainTextField/MainTextField'

interface CountrySelectProps {
  label?: string
  required?: boolean
  onChange: (e: any, v: any) => void
  value: any
}

export const CountrySelect: React.FC<CountrySelectProps> = ({ label, required, onChange, value }) => {
  return (
    <Autocomplete
      id='country-select-demo'
      options={countries}
      fullWidth
      autoHighlight
      getOptionLabel={option => option.localeRU}
      value={countries.find(country => country.localeRU === value) || null}
      onChange={onChange}
      renderOption={(props, option) => (
        <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          <img
            loading='lazy'
            width='20'
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=''
          />
          {option.localeRU} ({option.code}) +{option.phone}
        </Box>
      )}
      renderInput={params => (
        <MyStyledTextField
          {...params}
          label={label}
          variant='filled'
          required={!!required}
          InputProps={{
            ...params.InputProps,
            autoComplete: 'new-password-citizenship', // disable autocomplete and autofill
            disableUnderline: true,
          }}
        />
      )}
    />
  )
}
