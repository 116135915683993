import { FC, memo, useCallback, useEffect } from 'react'

import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { fetchProjectByEmployee, fetchProjectFilter } from 'service/Projects'
import { ProjectCardContent } from './modules/ProjectsCardContent/ProjectCardContent'
import { CustomLoader } from 'components/UI/CustomLoader/CustomLoader'
import { useCheckClaims } from 'hooks/useCheckClaims'
import { usePermissions } from 'hooks/usePermissions'
import { ROLES_FOREMAN, ROLES_PROJECT_LEADER } from 'helpers/roles'
import { ProjectStatus } from 'models/requests/project-requests.model'

export const ProjectCard: FC = memo(() => {
  const dispatch = useAppDispatch()

  const currentOrganization = useAppSelector(state => state.organizationReducer.currentOrganization)
  const perPage = useAppSelector(state => state.projectReducer.perPage)
  const isFetchProjects = useAppSelector(state => state.projectReducer.isFetchProjects)
  const status = useAppSelector(state => state.projectReducer.status)
  const currentUser = useAppSelector(state => state.currentUserReducer.currentUser)

  const { checkClaims } = useCheckClaims()
  const { checkUserPermissions } = usePermissions()

  useEffect(() => {
    if (!currentOrganization) return
    if (checkClaims('Back.project.GetByEmployeeIdBatch') && checkUserPermissions(ROLES_PROJECT_LEADER, ROLES_FOREMAN) && currentUser) {
      dispatch(fetchProjectByEmployee(currentUser.employee.id))
    } else {
      checkClaims('Back.project.PostByFilter') &&
        dispatch(fetchProjectFilter({ perPage }, { organizationIds: [currentOrganization.id], projectStatus: ProjectStatus.All }))
    }
  }, [perPage, isFetchProjects])

  const renderProjectsCard = useCallback(() => {
    if (status === 'init' || status === 'loading' || !currentOrganization) return <CustomLoader />
    return <ProjectCardContent />
  }, [status])

  return renderProjectsCard()
})
