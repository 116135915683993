import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import 'dayjs/plugin/updateLocale'
import 'dayjs/plugin/localeData'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import utc from 'dayjs/plugin/utc'

dayjs.extend(isSameOrAfter)
dayjs.extend(utc)

export const ReportTime = {
  year: dayjs().year(),
  month: dayjs().month(),
  day: dayjs().date(),

  getNow(): string {
    return dayjs().format('YYYY-MM-DD')
  },

  getFirstMonthDay(): string {
    return dayjs().date(1).toISOString().split('T')[0]
  },

  getLastMonthDay(): string {
    return dayjs().endOf('month').toISOString().split('T')[0]
  },

  getInputFormatDate(data: string): string {
    if (!data) return ''
    return dayjs(data).format('YYYY-MM-DD')
  },

  getDotFormatDate(data: string): string {
    if (!data) return ''
    return dayjs(data).format('DD.MM.YY')
  },

  getTime(data: string): string {
    return dayjs(data).local().format('HH:mm')
  },

  getBetweenDays(start: string, end: string) {
    let day = dayjs(start).date()
    let month = dayjs(start).month() + 1
    let year = dayjs(start).year()

    const arr: string[] = []

    let startData = dayjs(`${year}-${month}-${day}`).format('YYYY-MM-DD')

    arr.push(startData)

    while (dayjs(startData).isBefore(dayjs(end)) && day < 32) {
      startData = dayjs(startData).add(1, 'day').format('YYYY-MM-DD')

      arr.push(startData)
    }

    return arr
  },

  getDay(data: string) {
    return dayjs(data).date()
  },

  getYear(data: string) {
    return dayjs(data).year()
  },

  getNextDayDateTimeLocal(data: string): string {
    return dayjs(data).add(1, 'day').local().format()
  },

  getDateTimeLocal(data: string): string {
    return dayjs(data).local().format()
  },

  check(start: string, end: string): boolean {
    return dayjs(start).isBefore(dayjs(end))
  },

  getHourNumber(data: string) {
    return Number(data.split(':')[0])
  },

  checkBetweenMinAndMax(start: string, end: string): boolean {
    const startYear = this.getYear(start)
    const endYear = this.getYear(end)

    if (!startYear && !endYear) {
      return false
    }

    const between = endYear - startYear

    return between <= 1 && between >= 0
  },

  isEqualCheckByDay(first: string, second: string): boolean {
    return dayjs(first).isSame(second, 'day')
  },
}
