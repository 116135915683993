import { useState, ChangeEvent, useEffect, memo, Fragment } from 'react'
import { toast } from 'react-toastify'
import { Stack } from '@mui/material'

import { Filter, SortByTypeBasic } from 'components/SortByTypeBasic/SortByTypeBasic'
import { CustomButton } from 'components/UI/Button/CustomButton'
import { CustomModal } from 'components/UI/CustomModal/CustomModal'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { shiftsActions } from 'store/reducers/ShiftsSlice'
import { postEmployeeShift } from 'service/Shifts'
import { ReportTime } from 'helpers/timeHelpers'
import { ShiftTypeCondition } from 'models/requests/shift-requests.model'
import { ShiftModel } from 'models/shift.model'

export const StatisticsShiftsChangeStatusModal = memo(() => {
  const statuses = useAppSelector(state => state.statusReducer.statuses)
  const isShiftModal = useAppSelector(state => state.shiftsReducer.isShiftModal)
  const isShiftEditModal = useAppSelector(state => state.shiftsReducer.isShiftEditModal)
  const type = useAppSelector(state => state.shiftsReducer.type)
  const startDateShiftStatus = useAppSelector(state => state.shiftsReducer.startDateShiftStatus)
  const stateShiftEmployee = useAppSelector(state => state.shiftsReducer.stateShiftEmployee)
  const employeeId = useAppSelector(state => state.employeesReducer.employeeId)
  const shiftEmployee = useAppSelector(state => state.shiftsReducer.shiftEmployee) as ShiftModel
  const { dayShiftEndTime, dayShiftStartTime, nightShiftEndTime, nightShiftStartTime } = shiftEmployee.employeeProject.project.projectInfo

  const [statusId, setStatusId] = useState<number | null>(null)
  
  const [date, setDate] = useState<{ startDate: string | null; finishDate: string | null }>({
    startDate: null,
    finishDate: null,
  })

  useEffect(() => {
    if (isShiftEditModal && stateShiftEmployee) {
      switch (stateShiftEmployee.status.id) {
        case 6:
          setDate({
            startDate: ReportTime.getTime(stateShiftEmployee.shiftStartedAt as string),
            finishDate: ReportTime.getTime(stateShiftEmployee.shiftEndedAt as string),
          })
          break
        case 2:
        case 4:
          setDate({
            startDate: ReportTime.getInputFormatDate(stateShiftEmployee.shiftStartedAt as string),
            finishDate: ReportTime.getInputFormatDate(stateShiftEmployee.shiftEndedAt as string),
          })
          break
        default:
          setDate({
            startDate: ReportTime.getInputFormatDate(stateShiftEmployee.shiftStartedAt as string),
            finishDate: null,
          })
          break
      }
      setStatusId(stateShiftEmployee?.status.id)
    }
  }, [isShiftEditModal, stateShiftEmployee])

  const dispatch = useAppDispatch()

  const onClose = () => {
    dispatch(shiftsActions.setIsShiftModal(false))
    dispatch(shiftsActions.setIsShiftEditModal(false))
    setStatusId(null)
    setDate({ startDate: null, finishDate: null })
  }

  const switchTypeShiftTime = () => {
    switch (type) {
      case ShiftTypeCondition.Day:
        return `(${dayShiftStartTime} - ${dayShiftEndTime})`
      default:
        return `(${nightShiftStartTime} - ${nightShiftEndTime})`
    }
  }

  const handleSubmit = () => {
    if (statusId === 6) {
      const startCondition =
        date.startDate &&
        ReportTime.getHourNumber(date.startDate) <
          ReportTime.getHourNumber(type === ShiftTypeCondition.Day ? dayShiftStartTime : nightShiftStartTime)
      const endCondition =
        date.finishDate &&
        ReportTime.getHourNumber(date.finishDate) >
          ReportTime.getHourNumber(type === ShiftTypeCondition.Day ? dayShiftEndTime : nightShiftEndTime)

      if (startCondition || endCondition) {
        toast.error(`Время задаваемой смены не должно выходить за рамки настроек проекта ${switchTypeShiftTime()}`)
        return
      }
    }

    dispatch(
      postEmployeeShift({
        type,
        shift: {
          statusId: statusId as number,
          employeeProjectId: employeeId ? employeeId : undefined,
          startDate: statusId === 6 ? ReportTime.getDateTimeLocal(`${startDateShiftStatus + date.startDate}`) : (date.startDate as string),
          finishDate:
            statusId === 6
              ? type === ShiftTypeCondition.Night
                ? ReportTime.getNextDayDateTimeLocal(`${startDateShiftStatus + date.finishDate}`)
                : ReportTime.getDateTimeLocal(`${startDateShiftStatus + date.finishDate}`)
              : date.finishDate,
        },
      }),
    ).then(() => {
      dispatch(shiftsActions.setIsUpdateShift())
      onClose()
    })
  }

  const handleChangeShift = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setDate(prev => ({ ...prev, [name]: value }))
  }

  const handleChangeStatusId = (e: ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value)
    if (value === 6) {
      setDate({
        startDate: type === ShiftTypeCondition.Day ? dayShiftStartTime : nightShiftStartTime,
        finishDate: type === ShiftTypeCondition.Day ? dayShiftEndTime : nightShiftEndTime,
      })
    } else {
      setDate({ startDate: startDateShiftStatus, finishDate: null })
    }
    setStatusId(value)
  }

  useEffect(() => {
    switch (statusId) {
      case 2:
      case 4:
        setOptionsShifts([
          ...elements,
          {
            name: 'finishDate',
            defaultValue: ReportTime.getInputFormatDate(date.finishDate as string),
            label: 'Дата окончания',
            type: 'input',
            inputType: 'date',
            onChange: handleChangeShift,
          },
        ])
        break
      case 6:
        setOptionsShifts([
          ...elements,
          {
            name: 'startDate',
            label: 'Начало',
            defaultValue: date.startDate,
            type: 'input',
            inputType: 'time',
            onChange: handleChangeShift,
          },
          {
            name: 'finishDate',
            label: 'Конец',
            defaultValue: date.finishDate,
            type: 'input',
            inputType: 'time',
            onChange: handleChangeShift,
          },
        ])
        break
      default:
        setOptionsShifts(elements)
        break
    }
  }, [statusId, date])

  const elements = [
    {
      name: 'statusId',
      label: 'Статус *',
      type: 'select',
      value: statusId,
      required: true,
      onChange: handleChangeStatusId,
      options: statuses.map(status => {
        return { value: status.id, text: status.name }
      }),
    },
  ]

  const [optionsShifts, setOptionsShifts] = useState<Filter[]>(elements)

  return (
    <CustomModal onClose={onClose} titleModal={isShiftEditModal ? 'Редактирование смены' : 'Создание смены'} open={isShiftModal}>
      <Stack gap={3}>
        {optionsShifts.map(optionsShift => (
          <Fragment key={optionsShift.name}>{SortByTypeBasic(optionsShift)}</Fragment>
        ))}
        {statusId && (
          <CustomButton onClick={handleSubmit} sx={{ alignSelf: 'flex-end' }}>
            Подтвердить
          </CustomButton>
        )}
      </Stack>
    </CustomModal>
  )
})
