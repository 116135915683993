import { StatisticsTableColumnsType } from './StatisticsTableColumnsTypes'

export const StatisticsTableLeftColumns: readonly StatisticsTableColumnsType[] = [
  {
    name: 'Аватар',
    width: 60,
  },
  {
    name: 'ФИО сотрудника',
    width: 309,
  },
  {
    name: 'Должность',
    width: 300,
  },
  {
    name: 'Почасовая оплата',
    width: 100,
  },
  {
    name: 'Дата выхода',
    width: 74,
  },
]
