import { useState, FC, memo, useMemo, useCallback } from 'react'
import { MoreHoriz } from '@mui/icons-material'
import { Box, IconButton, Menu, Typography } from '@mui/material'
import { useCheckClaims } from 'hooks/useCheckClaims'
import { OrganizationResponse } from 'models/responses/organization.response'
import { useOrganizationCardActionsController } from '../../controllers/useOrganizationCardActionsController'

export const OrganizationCardContentItemMenu: FC<{ org: OrganizationResponse }> = memo(({ org }) => {
  const { deleteCompany, handleStatistics, handleEditOrg } = useOrganizationCardActionsController()
  const { checkClaims } = useCheckClaims()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const actions = useMemo(
    () => [
      checkClaims('Front.organizationCard.item.Modal.Edit') && {
        toolbarName: 'Редактировать',
        onClick: () => handleEditOrg(org),
      },
      checkClaims('Front.organizationCard.item.Modal.ShowStatistics') && {
        toolbarName: 'Показать статистику',
        onClick: () => handleStatistics(org),
      },
      checkClaims('Front.organizationCard.item.Modal.Remove') && {
        toolbarName: 'Удалить',
        onClick: () => deleteCompany(org),
      },
    ],
    [],
  )

  const handleClose = useCallback(() => setAnchorEl(null), [])
  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget), [])

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreHoriz />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            minWidth: 170,
            padding: '8px 18px',
            boxShadow: '0px 30px 60px rgba(32, 56, 85, 0.15)',
            borderRadius: '1rem',
          },
        }}
      >
        {actions.map((action: any, idx: number) => (
          <Box
            key={idx}
            onClick={() => {
              action.onClick()
              handleClose()
            }}
          >
            <Typography
              variant='h6'
              sx={{ color: 'text.secondary', transition: 'color 0.3s ease', cursor: 'pointer', '&:hover': { color: 'text.primary' } }}
            >
              {action.toolbarName}
            </Typography>
          </Box>
        ))}
      </Menu>
    </>
  )
})
