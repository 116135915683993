import { ProjectModel } from './../models/project.model'
import { LocaleRolesModel, RoleModel } from '../models/responses/user.response'

export const ROLES_SUPER_ADMIN = 'super_admin'
export const ROLES_COMPANY_LEADER = 'company_leader'
export const ROLES_PROJECT_LEADER = 'project_leader'
export const ROLES_FOREMAN = 'foreman'
export const ROLES_WORKER = 'worker'

export const LOCALE_ROLES_SUPER_ADMIN = 'cупер администратор'
export const LOCALE_ROLES_COMPANY_LEADER = 'руководитель компании'
export const LOCALE_ROLES_PROJECT_LEADER = 'руководитель проекта'
export const LOCALE_ROLES_FOREMAN = 'прораб'
export const LOCALE_ROLES_WORKER = 'работник'

// Локализация ролей
export const toLocaleRoles = (roles: RoleModel[]): LocaleRolesModel[] => {
  const locales = roles.map(role => {
    switch (role.name) {
      case ROLES_SUPER_ADMIN:
        return { ...role, name: 'Супер администратор', normalizedName: 'СУПЕР АДМИНИСТРАТОР' }
      case ROLES_COMPANY_LEADER:
        return { ...role, name: 'Руководитель компании', normalizedName: 'РУКОВОДИТЕЛЬ КОМПАНИИ' }
      case ROLES_PROJECT_LEADER:
        return { ...role, name: 'Руководитель проекта', normalizedName: 'РУКОВОДИТЕЛЬ ПРОЕКТА' }
      case ROLES_FOREMAN:
        return { ...role, name: 'Прораб', normalizedName: 'ПРОРАБ' }
      case ROLES_WORKER:
        return { ...role, name: 'Работник', normalizedName: 'РАБОТНИК' }
      default:
        return { ...role }
    }
  })

  return locales
}

export const filteredSortToSelect = (roles: RoleModel[], activeProject: ProjectModel) => {
  let res: Array<{ value: number; text: string }> = []
  roles.forEach(role => {
    if (role.name === ROLES_SUPER_ADMIN || role.name === ROLES_COMPANY_LEADER) return false
    if (
      (activeProject.isHaveForeman && role.name === ROLES_FOREMAN) ||
      (activeProject.isHaveProjectLeader && role.name === ROLES_PROJECT_LEADER)
    )
      return false
    res.push({
      value: role.id,
      text: returnLocaleRole(role.name),
    })
  })
  return res
}

export const returnLocaleRole = (role: string) => {
  switch (role) {
    case ROLES_SUPER_ADMIN:
      return 'Супер администратор'
    case ROLES_COMPANY_LEADER:
      return 'Руководитель компании'
    case ROLES_PROJECT_LEADER:
      return 'Руководитель проекта'
    case ROLES_FOREMAN:
      return 'Прораб'
    case ROLES_WORKER:
      return 'Работник'
    default:
      return ''
  }
}

export type TRoles = 'super_admin' | 'foreman' | 'worker' | 'company_leader' | 'project_leader'
