import { UserResponse } from '../../../models/responses/user.response'
import { ShiftQrResponse } from '../../../models/responses/shift-qr.response'
import { ShiftRequestsBodyModel } from '../../../models/requests/shift-requests.model'
import { qrAuthTracker } from '../../axios'
import { AxiosResponseModel } from '../../types'
import { WorkerRequestsBodyModel } from '../../../models/requests/employee-role-requests/worker-requests.model'

// ========================= WORKER =====================================

// Создать сотрудника с ролью "WORKER" и добавить в проект.
export const qrAuthPostCreateWithRoleWorker = (params: WorkerRequestsBodyModel.postCreate): AxiosResponseModel<UserResponse> =>
  qrAuthTracker.post(`/management/worker`, params)

// Добавить существующего сотрудника с ролью "WORKER" в проект.
export const qrAuthPostExistWithRoleWorker = (params: any): AxiosResponseModel<UserResponse> =>
  qrAuthTracker.post(`/management/worker/exist`, params)

// Обновить сотрудника с ролью "WORKER" по идентификатору.
export const qrAuthPutUpdateWithRoleWorker = (id: number, params: WorkerRequestsBodyModel.put): AxiosResponseModel<UserResponse> =>
  qrAuthTracker.put(`/management/worker/${id}`, params)

// Изменить роль сотрудника на "WORKER" по идентификатору.
export const qrAuthPatchChangeRoleWorker = (id: number): AxiosResponseModel<null> => qrAuthTracker.patch(`/management/worker/${id}/role`)

// Перенести сотрудника с ролью "WORKER" в другую организацию.
export const qrAuthPostTransferWithRoleWorker = (id: number, params: any): AxiosResponseModel<UserResponse> =>
  qrAuthTracker.post(`/management/worker/${id}/transfer`, params)

// Получить Qr-коды сотрудников с ролью "WORKER" по фильтру.
export const qrAuthPostQrCodeFilterWithRoleWorker = (
  params: ShiftRequestsBodyModel.postFilterQrCode,
): AxiosResponseModel<ShiftQrResponse[]> => qrAuthTracker.post(`/management/worker/qr-code/filter`, params)
