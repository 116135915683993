import { FilterQueryCommonModel } from '../common/common.model'
import { ProjectStatus } from './project-requests.model'
export enum AssignmentFile {
  Other,
  Contracts,
  TKP,
}
interface ProjectFilePost {
  projectId: number
  assignment: AssignmentFile
}

interface ProjectFilesFilterPost {
  ids?: number[]
  employeeProjectIds?: number[]
  projectIds?: number[]
  fileNames?: string[]
  projectStatus?: ProjectStatus
}

export namespace ProjectFileRequestsBodyModel {
  export type post = FormData
  export type postFilter = ProjectFilesFilterPost
}

export namespace ProjectFileRequestsQueryModel {
  export type post = ProjectFilePost
  export type postFilter = FilterQueryCommonModel
}
