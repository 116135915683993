import { UserResponse } from './../../../models/responses/user.response'
import { AxiosResponseModel } from '../../types'
import { qrAuthTracker } from '../../axios'
import { ProjectLeaderRequestsBodyModel } from '../../../models/requests/employee-role-requests/project-leader-requests.model'

// ========================= PROJECT LEADER =====================================

// Создать сотрудника организации с ролью "PROJECT_LEADER".
export const qrAuthPostCreateWithRoleProjectLeader = (
  params: ProjectLeaderRequestsBodyModel.postCreate,
): AxiosResponseModel<UserResponse> => qrAuthTracker.post(`/management/project-leader`, params)

// Добавить существующего сотрудника с ролью "PROJECT_LEADER" в проект.
export const qrAuthPostExistWithRoleProjectLeader = (params: any): AxiosResponseModel<any> =>
  qrAuthTracker.post(`/management/project-leader/exist`, params)

// Обновить сотрудника с ролью "PROJECT_LEADER" по идентификатору.
export const qrAuthPutUpdateWithRoleProjectLeader = (
  id: number,
  params: ProjectLeaderRequestsBodyModel.put,
): AxiosResponseModel<UserResponse> => qrAuthTracker.put(`/management/project-leader/${id}`, params)

// Изменить роль сотрудника на "PROJECT_LEADER" по идентификатору.
export const qrAuthPatchChangeRoleProjectLeader = (id: number): AxiosResponseModel<any> =>
  qrAuthTracker.patch(`/management/project-leader/${id}/role`)

// Перенести сотрудника с ролью "PROJECT_LEADER" в другую организацию.
export const qrAuthPostTransferWithRoleProjectLeader = (id: number, params: any): AxiosResponseModel<any> =>
  qrAuthTracker.patch(`/management/project-leader/${id}/transfer`, params)
