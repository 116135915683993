import { ChangeEvent } from 'react'
import { toast } from 'react-toastify'

import { Validation } from 'helpers/validation'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { projectActions } from 'store/reducers/ProjectSlice'

export const usePutProjectPassportController = () => {
  const activeProject = useAppSelector(state => state.projectReducer.activeProject)
  const projectInfo = useAppSelector(state => state.projectReducer.projectInfo)
  const status = useAppSelector(state => state.projectReducer.status)

  const dispatch = useAppDispatch()

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type } = e.target

    if (typeof value === 'number' && !Validation.number(Number(value))) {
      toast.error('Введите валидное число')
      return
    }

    projectInfo && dispatch(projectActions.setProjectInfo({ ...projectInfo, [name]: type === 'number' ? Number(value) : value }))
  }

  return {
    handleChange,
    projectInfo,
    status,
    activeProject,
  }
}
