import { useAppSelector } from '../../../hooks/redux'
import { qrCodesActions } from '../../../store/reducers/QrCodeSlice'
import { CustomLoader } from '../../UI/CustomLoader/CustomLoader'
import { BasicModal } from '../BasicModal/BasicModal'

const elementsQrWorker = [
  {
    name: 'qr',
    label: 'Qr картинка',
    type: 'img',
  },
]

export const QrPrintModal = () => {
  const isModalQrPrint = useAppSelector(state => state.qrCodesReducer.isModalQrPrint)
  const qrCodesPrint = useAppSelector(state => state.qrCodesReducer.qrCodesPrint)
  const status = useAppSelector(state => state.qrCodesReducer.status)

  if (status === 'init' || status === 'loading') return <CustomLoader />

  return (
    <BasicModal
      open={isModalQrPrint}
      setOpen={qrCodesActions.setIsModalQrPrint}
      redux
      title={'QR-Code'}
      // title={<Link to={`/printQrAll/${currentOrganization?.id}?qr=${workerId}`}>Распечатать</Link>}
      elements={elementsQrWorker}
      handleSave={() => {}}
      button={false}
      initialState={{ qr: 'data:image/jpeg;base64, ' + qrCodesPrint[0].qrBase64 }}
    />
  )
}
