import { ReportTime } from '../../../../../../helpers/timeHelpers'
import { useAppSelector } from '../../../../../../hooks/redux'
import { ShiftTypeCondition } from '../../../../../../models/requests/shift-requests.model'
import { BoxHeadCalendarStyled } from '../../styled/StatisticsTable.styled'

export const StatisticsTableHeadCalendarTypeCondition = (intervalItem: string) => {
  const type = useAppSelector(state => state.shiftsReducer.type)

  switch (type) {
    case ShiftTypeCondition.All:
      return (
        <>
          <BoxHeadCalendarStyled>{ReportTime.getDay(intervalItem)}</BoxHeadCalendarStyled>
          <BoxHeadCalendarStyled typecondition={true.toString()}>{ReportTime.getDay(intervalItem)}</BoxHeadCalendarStyled>
        </>
      )
    case ShiftTypeCondition.Day:
      return <BoxHeadCalendarStyled>{ReportTime.getDay(intervalItem)}</BoxHeadCalendarStyled>
    default:
      return <BoxHeadCalendarStyled typecondition={true.toString()}>{ReportTime.getDay(intervalItem)}</BoxHeadCalendarStyled>
  }
}
