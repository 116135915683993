import qs from 'query-string'
import { qrAuthTracker } from './../axios'
import { PositionRequestsBodyModel, PositionRequestsQueryModel } from '../../models/requests/position-requests.model'
import { AxiosResponseModel } from '../types'
import { PositionResponse } from '../../models/responses/position.response'

//==========================  POSITION  ===============================

export const qrAuthGetPositions = (
  params: PositionRequestsBodyModel.postFilter,
  query: PositionRequestsQueryModel.postFilter = {},
): AxiosResponseModel<PositionResponse[]> => qrAuthTracker.post(`/organization/position/filter?${qs.stringify(query)}`, params)
