import { qrAuthGetPositions } from './../api/utils/positionApi'
import { PositionRequestsBodyModel, PositionRequestsQueryModel } from '../models/requests/position-requests.model'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

export const fetchAllPositions = createAsyncThunk(
  `position/fetchAllPositions`,
  async (data: { params?: PositionRequestsBodyModel.postFilter; query?: PositionRequestsQueryModel.postFilter }, { rejectWithValue }) => {
    const { params, query } = data
    try {
      const response = await qrAuthGetPositions({ ...params }, { ...query, sortColumn: 'name', sortDirection: 'asc', perPage: 1000 })
      return response.data.items
    } catch (error: any) {
      const err: AxiosError = error
      return rejectWithValue(err.message)
    }
  },
)
