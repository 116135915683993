import { Box, Stack } from '@mui/material'
import { SortByTypeBasic } from 'components/SortByTypeBasic/SortByTypeBasic'
import { CustomButton } from 'components/UI/Button/CustomButton'
import { CustomModal } from 'components/UI/CustomModal/CustomModal'
import { useCheckClaims } from 'hooks/useCheckClaims'
import { useReport } from 'hooks/useReport'

export const ReportModal = () => {
  const { isModalDownloadReport, elementsByReport, handleDownloadReport, navigateStatistics, onClose } = useReport()
  const { checkClaims } = useCheckClaims()

  return (
    <CustomModal open={isModalDownloadReport} titleModal='Просмотреть отчет' onClose={onClose}>
      <Stack gap={3}>
        <Stack direction={'row'} gap={4}>
          {elementsByReport.map(filter => (
            <Box key={filter.name} sx={{ width: '100%' }}>
              {SortByTypeBasic(filter)}
            </Box>
          ))}
        </Stack>
        <Stack direction={'row'} alignSelf='flex-end' gap={3}>
          {checkClaims('Front.statistics.downloadReport') && <CustomButton onClick={handleDownloadReport}>Скачать отчет</CustomButton>}
          <CustomButton onClick={navigateStatistics}>Просмотреть</CustomButton>
        </Stack>
      </Stack>
    </CustomModal>
  )
}
