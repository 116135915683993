import qs from 'query-string'
import { qrAuthTracker } from './../axios'
import { RoleRequestsBodyModel, RoleRequestsQueryModel } from '../../models/requests/role-requests.model'
import { AxiosResponseModel } from '../types'
import { RoleModel } from '../../models/responses/user.response'

// ========================= ROLE ======================================

export const qrAuthGetRoles = (
  params: RoleRequestsBodyModel.postFilter = {},
  query: RoleRequestsQueryModel.postFilter = {},
): AxiosResponseModel<RoleModel[]> => qrAuthTracker.post(`/roles/filter?${qs.stringify(query)}`, params)
