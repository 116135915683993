import { TableBody, TableRow } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { checkFloatingPoint } from 'helpers/checkFloatingPoint'
import { useCheckClaims } from 'hooks/useCheckClaims'
import { shiftConditionTypeValue } from 'helpers/shiftConditionTypeValue'
import { ReportTime } from 'helpers/timeHelpers'
import { useAppSelector } from 'hooks/redux'
import { PeriodicPayment } from 'models/requests/periodic-payment-requests.model'
import { ShiftModel } from 'models/shift.model'
import { TableBodyCellStyled } from '../../styled/StatisticsTable.styled'
import { StatisticsTableBodyAmountOfFinesTableCell } from './StatisticsTableBodyAmountOfFinesTableCell'
import { StatisticsTableBodyCalendar } from './StatisticsTableBodyCalendar'
import { StatisticsTableBodyChangeValueInput } from './StatisticsTableBodyChangeValueInput'
import { ADMIN_AREA_PATH } from 'helpers/routePaths'
import { BASE_URL } from 'api/url'

import { noPhoto } from 'assets/images'

export const StatisticsTableBody = () => {
  const shifts = useAppSelector(state => state.shiftsReducer.shifts)
  const isEditStatuses = useAppSelector(state => state.statusReducer.isEditStatuses)
  const type = useAppSelector(state => state.shiftsReducer.type)

  const { checkClaims } = useCheckClaims()

  const navigate = useNavigate()

  return (
    <TableBody>
      {shifts.map((shift: ShiftModel, idx: number) => (
        <TableRow key={shift.employeeProject.id}>
          <TableBodyCellStyled sx={{ color: '#0066FF' }}>{idx + 1}</TableBodyCellStyled>
          <TableBodyCellStyled>
            <img
              src={
                shift.employeeProject.employee.user?.profilePhoto
                  ? BASE_URL + '/' + shift.employeeProject.employee.user.profilePhoto
                  : noPhoto
              }
              height={40}
              width={40}
              alt={'profilePhoto'}
            />
          </TableBodyCellStyled>
          <TableBodyCellStyled
            onClick={() =>
              navigate(
                `/${ADMIN_AREA_PATH}/${shift.employeeProject.employee.organization.id}/project/${shift.employeeProject.project.id}/employee/${shift.employeeProject.employee.id}`,
              )
            }
            sx={{
              cursor: 'pointer',
              transition: 'color .3s ease',
              '&:hover': {
                color: 'primary.main',
              },
            }}
          >
            {`${shift.employeeProject.employee?.user.surname} ${shift.employeeProject.employee?.user.name} ${
              shift.employeeProject.employee?.user?.patronymic || ''
            }`}
          </TableBodyCellStyled>
          <TableBodyCellStyled> {shift.employeeProject.position?.name || '-'}</TableBodyCellStyled>
          <TableBodyCellStyled>
            {isEditStatuses && checkClaims('Front.statistics.edit.hourlyPayment') ? (
              <StatisticsTableBodyChangeValueInput shift={shift} />
            ) : (
              shiftConditionTypeValue(shift.employeeProject, type)
            )}
          </TableBodyCellStyled>
          <TableBodyCellStyled>{ReportTime.getDotFormatDate(shift.employeeProject.startDate) || '-'}</TableBodyCellStyled>
          <StatisticsTableBodyCalendar shift={shift} />
          <TableBodyCellStyled sx={{ borderLeft: '1px solid #E8E7E7' }}>
            {checkFloatingPoint(shift.employeeProject.numberOfHours)
              ? shift.employeeProject.numberOfHours.toFixed(2)
              : shift.employeeProject.numberOfHours}
          </TableBodyCellStyled>
          <TableBodyCellStyled>{shift.employeeProject.amountForHoursWorked}</TableBodyCellStyled>
          <TableBodyCellStyled>{shift.employeeProject.amountOfFinesForTruancies}</TableBodyCellStyled>
          {[PeriodicPayment.SupervisorsFine, PeriodicPayment.Compensation].map(item => (
            <StatisticsTableBodyAmountOfFinesTableCell key={item} shift={shift} periodicPaymentType={item} />
          ))}
          <TableBodyCellStyled>{shift.employeeProject.totalAmountPayable}</TableBodyCellStyled>
        </TableRow>
      ))}
    </TableBody>
  )
}
