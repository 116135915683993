import React, { useState, FC, useEffect } from 'react'
import { Box, Chip, ListItem, Paper } from '@mui/material'

import { MainTextField } from '../TextFields/MainTextField/MainTextField'
import { useAppDispatch } from 'hooks/redux'

export const MultiValueInput: FC<any> = ({ element, state, setState, redux = false, ...props }) => {
  const [value, setValue] = useState<string>('')
  const [chipData, setChipData] = useState<string[]>([])
  const dispatch = useAppDispatch()

  useEffect(() => {
    setChipData(state[element.name] || [])
  }, [])

  const onKeyPress = (e: React.KeyboardEvent) => {
    if (e.keyCode === 13) {
      value !== '' && !chipData.includes(value) && setChipData(prev => [...prev, value])
      redux
        ? dispatch(setState({ ...state, [element.name]: [...chipData, value] }))
        : setState({ ...state, [element.name]: [...chipData, value] })
      setValue('')
    }
  }

  const handleDelete = (chipToDelete: string) => () => {
    setChipData(chips => chips.filter(chip => chip !== chipToDelete))
  }

  return (
    <Box>
      <MainTextField
        id={element.name}
        label={element.label}
        name={element.name}
        type='text'
        fullWidth
        value={value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}
        onKeyDown={onKeyPress}
        required={!!element.required}
        placeholder={'Для ввода значения нажмите Enter'}
      />
      <Paper
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          listStyle: 'none',
          m: 0,
        }}
        component='ul'
      >
        {chipData && chipData.length
          ? chipData.map((data: string, idx: number) => {
              return (
                <ListItem key={data + idx} sx={{ p: 0.5, width: 'auto' }}>
                  <Chip label={data} />
                </ListItem>
              )
            })
          : null}
      </Paper>
    </Box>
  )
}
