import { UserResponse } from '../../../models/responses/user.response'
import qs from 'query-string'
import { AxiosResponseModel } from '../../types'
import { qrAuthTracker } from '../../axios'
import { CompanyLeaderRequestsBodyModel } from '../../../models/requests/employee-role-requests/company-leader-requests.model'

// ========================= COMPANY LEADER =====================================

// Создать сотрудника организации с ролью "COMPANY_LEADER".
export const qrAuthPostCreateWithRoleCompanyLeader = (
  params: CompanyLeaderRequestsBodyModel.postCreate,
): AxiosResponseModel<UserResponse> => qrAuthTracker.post(`/management/company-leader`, params)

// Обновить сотрудника с ролью "COMPANY_LEADER" по идентификатору.
export const qrAuthPutUpdateWithRoleCompanyLeader = (
  id: number,
  params: CompanyLeaderRequestsBodyModel.put,
): AxiosResponseModel<UserResponse> => qrAuthTracker.put(`/management/company-leader/${id}`, params)

// Изменить роль сотрудника на "COMPANY_LEADER" по идентификатору.
export const qrAuthPatchChangeRoleCompanyLeader = (params: any): AxiosResponseModel<null> =>
  qrAuthTracker.patch(`/management/company-leader/role?${qs.stringify(params)}`)

// Перенести сотрудника с ролью "COMPANY_LEADER" в другую организацию.
export const qrAuthPostTransferWithRoleCompanyLeader = (id: number, params: any): AxiosResponseModel<UserResponse> =>
  qrAuthTracker.patch(`/management/company-leader/${id}/transfer`, params)
