import { useEffect, useState } from 'react'
import { ROLES_FOREMAN, ROLES_WORKER, ROLES_PROJECT_LEADER } from 'helpers/roles'
import { usePutEmployeeController } from 'pages/WorkerCard/controllers/usePutEmployeeController'
import { useAppSelector } from 'hooks/redux'
import { PositionResponse } from 'models/responses/position.response'

export const usePutEmployeeElements = () => {
  const statesEmployee = useAppSelector(state => state.employeesReducer.statesEmployee)
  const activeEmployee = useAppSelector(state => state.employeesReducer.activeEmployee)
  const activeEmployeeRole = activeEmployee && activeEmployee.role.name

  const positions = useAppSelector(state => state.positionReducer.positions)
  const putStateEmployee = statesEmployee.statePutEmployee

  const [elements, setElements] = useState<any[]>([])

  const { handleChange } = usePutEmployeeController()

  useEffect(() => {
    const elementsCommonEmployee = [
      {
        name: 'userName',
        type: 'input',
        value: putStateEmployee?.userName,
        label: 'Логин',
        onChange: handleChange,
      },
      {
        name: 'password',
        type: 'input',
        value: putStateEmployee?.password,
        label: 'Пароль',
        onChange: handleChange,
      },
      {
        name: 'phoneNumber',
        label: 'Номер телефона',
        type: 'pattern',
        value: putStateEmployee?.phoneNumber,
        format: '+7 ### ### ## ##',
        mask: '_',
        placeholder: '+7 000 000 00 00',
        onChange: handleChange,
      },
      // {
      //   name: 'citizenship',
      //   label: 'Гражданство сотрудника',
      //   type: 'countries',
      //   keyValue: 'localeRU',
      //   required: true,
      //   tabIndex: 1,
      // },
      {
        name: 'name',
        type: 'input',
        value: putStateEmployee?.name,
        label: 'Имя',
        onChange: handleChange,
      },
      {
        name: 'surname',
        label: 'Фамилия',
        value: putStateEmployee?.surname,
        type: 'input',
        onChange: handleChange,
      },
      {
        name: 'patronymic',
        value: putStateEmployee?.patronymic,
        label: 'Отчество',
        type: 'input',
        onChange: handleChange,
      },
    ]
    const elementsForeman = [
      ...elementsCommonEmployee,
      {
        name: 'workingMethod',
        type: 'input',
        value: putStateEmployee?.workingMethod,
        label: 'Метод работы',
        onChange: handleChange,
      },
    ]

    const elementsWorker = [
      ...elementsCommonEmployee,
      {
        name: 'workingMethod',
        type: 'input',
        value: putStateEmployee?.workingMethod,
        label: 'Метод работы',
        onChange: handleChange,
      },
      {
        name: 'positionId',
        type: 'select',
        value: putStateEmployee?.positionId,
        label: 'Должность',
        options: positions.map((item: PositionResponse) => {
          return { value: Number(item.id), text: item.name }
        }),
        onChange: handleChange,
      },
      {
        name: 'dayTimeHourlyPayment',
        type: 'number',
        value: Number(putStateEmployee?.dayTimeHourlyPayment),
        label: 'Почасовая оплата в дневное время',
        onChange: handleChange,
      },
      {
        name: 'nightTimeHourlyPayment',
        type: 'number',
        value: Number(putStateEmployee?.nightTimeHourlyPayment),
        label: 'Почасовая оплата в ночное время',
        onChange: handleChange,
      },
    ]

    switch (activeEmployeeRole) {
      case ROLES_FOREMAN:
        setElements(elementsForeman)
        break
      case ROLES_WORKER:
        setElements(elementsWorker)
        break
      case ROLES_PROJECT_LEADER:
        setElements(elementsForeman)
        break
      default:
        setElements(elementsCommonEmployee)
        break
    }
  }, [activeEmployee, putStateEmployee, positions])

  return elements
}
