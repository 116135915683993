import { FC } from 'react'
import { styled } from '@mui/material/styles'
import { Button, ButtonProps } from '@mui/material'

export const MyStyledButton = styled(Button)({
  minWidth: 135,
  minHeight: 49,
  fontWeight: 500,
  fontSize: 15,
})

export const CustomButton: FC<ButtonProps> = ({ children, variant, ...props }) => {
  return (
    <MyStyledButton variant={variant || 'contained'} {...props}>
      {children}
    </MyStyledButton>
  )
}
