import React, { useRef } from 'react'
import { toast } from 'react-toastify'
import { styled } from '@mui/material'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import { noPhoto } from '../../assets/images'
import classes from './StyleUserProfile.module.css'
import { checkExtNameFile, convertBase64, profilePhotoExtNames } from '../../helpers/files'

const Image = styled('img')(() => ({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
}))

interface ImagePreviewProps {
  src: string | null
  alt?: string
  setProfilePhoto: (p: File | null) => void
  setSrc: (p: string | null) => void
}

const ImagePreview: React.FC<ImagePreviewProps> = ({ src, alt, setProfilePhoto, setSrc }) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const handleClickUpload = () => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0]

      const isValidFile = checkExtNameFile(profilePhotoExtNames, file)

      if (!isValidFile) return

      setProfilePhoto(file)

      convertBase64(file)
        .then(res => setSrc(res as string))
        .catch(() => {
          toast.error(`Ошибка при загрузки файла!`)
        })
    } else {
      setProfilePhoto(null)
    }
  }

  return (
    <div className={`${classes.imgWrapper} ${classes.profilePhotoWrapper}`}>
      {src ? <Image src={src} alt={alt || ''} onError={() => setSrc(null)} /> : <Image src={noPhoto} alt={alt || ''} />}

      <input
        type='file'
        className={classes.hideInput}
        accept={profilePhotoExtNames.map(ext => `.${ext}`).join(',')}
        ref={inputRef}
        onChange={handleChange}
      />

      <div className={classes.uploadButton} onClick={handleClickUpload}>
        <FileUploadOutlinedIcon />
        <span>Загрузить</span>
      </div>
    </div>
  )
}

export default ImagePreview
