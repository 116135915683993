import React, { FC, useEffect, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import { toast } from 'react-toastify'

import classes from './StyleUserProfile.module.css'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { Validation } from '../../helpers/validation'
import { editCurrentUserService } from '../../service/UserProfile'
import { CustomLoader } from '../../components/UI/CustomLoader/CustomLoader'
import ImagePreview from './ImagePreview'
import { postUserProfilePhoto } from '../../service/Users'
import { BASE_URL } from '../../api/url'
import { returnLocaleRole } from '../../helpers/roles'
import { usePermissions } from '../../hooks/usePermissions'
import { MainTextField } from 'components/UI/TextFields/MainTextField/MainTextField'

interface IItemProps {
  label: string
  name: string
  type?: string
  value: any
  onChange: (param: React.ChangeEvent<HTMLInputElement>) => void
}

// чтобы сто раз не писать грид
const Item: FC<IItemProps> = ({ label, name, type = 'text', value, onChange }) => (
  <Grid item xs={6}>
    <MainTextField type={type} label={label} name={name} value={value} onChange={onChange} autoComplete={`new-${name}`} fullWidth />
  </Grid>
)

const UserProfile = () => {
  const currentUser = useAppSelector(state => state.currentUserReducer.currentUser)
  const status = useAppSelector(state => state.currentUserReducer.status)

  const { userPositionHistory } = usePermissions()

  const dispatch = useAppDispatch()

  const [imageSrc, setImageSrc] = useState<null | string>(null)
  const [profilePhoto, setProfilePhoto] = useState<null | File>(null)

  const [userState, setUserState] = useState({
    name: '',
    surname: '',
    patronymic: '',
    email: '',
    role: '',
  })

  const handleSave = () => {
    if (!Validation.email(userState.email)) {
      toast.error('Не валидный email', { toastId: 'email no valid' })
      return
    }

    if (!Validation.text(userState.name)) {
      toast.error('Не валидное имя', { toastId: 'name no valid' })
      return
    }

    if (!Validation.text(userState.surname)) {
      toast.error('Не валидная фамилия', { toastId: 'surname no valid' })
      return
    }

    if (!profilePhoto || !currentUser?.id) {
      dispatch(editCurrentUserService(userState))
      return
    }

    const formData = new FormData()
    formData.append('profilePhoto', profilePhoto)

    dispatch(postUserProfilePhoto(currentUser.id, formData)).finally(() => {
      dispatch(editCurrentUserService(userState))
    })
  }

  useEffect(() => {
    if (!currentUser || !currentUser.id) return

    setUserState({
      name: currentUser.name ? currentUser.name : '',
      surname: currentUser.surname ? currentUser.surname : '',
      patronymic: currentUser?.patronymic ? currentUser?.patronymic : '',
      email: currentUser.email ? currentUser.email : '',
      role: currentUser.role.name,
    })

    if (currentUser.profilePhoto) {
      setImageSrc(BASE_URL + '/' + currentUser.profilePhoto)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  if (status === 'loading') return <CustomLoader />

  return (
    <>
      <div>
        <div className={classes.wrapper}>
          {userPositionHistory?.id && userPositionHistory.qrCode?.qrBase64 ? (
            <div className={classes.imgWrapper}>
              <img src={`data:image/jpeg;base64, ${userPositionHistory.qrCode?.qrBase64}`} alt='QR код' className={classes.img} />
            </div>
          ) : null}
          <ImagePreview src={imageSrc} alt='Фото профиля' setProfilePhoto={setProfilePhoto} setSrc={setImageSrc} />
        </div>
        <Typography variant='h5' mb={3}>
          Роль - {returnLocaleRole(userState.role)}
        </Typography>
        <Grid container spacing={3} sx={{ maxWidth: '800px' }}>
          <Item label={'Имя'} name={'name'} value={userState.name} onChange={e => setUserState({ ...userState, name: e.target.value })} />
          <Item
            label={'Фамилия'}
            name={'surname'}
            value={userState.surname}
            onChange={e => setUserState({ ...userState, surname: e.target.value })}
          />
          <Item
            label={'Отчество'}
            name={'patronymic'}
            value={userState.patronymic}
            onChange={e => setUserState({ ...userState, patronymic: e.target.value })}
          />
          <Item
            type={'email'}
            label={'Email'}
            name={'email'}
            value={userState.email}
            onChange={e => setUserState({ ...userState, email: e.target.value })}
          />
        </Grid>
        <div className={classes.buttonWrapper}>
          <Button onClick={handleSave} variant={'contained'}>
            Сохранить
          </Button>
        </div>
      </div>
    </>
  )
}

export { UserProfile }
