import { ChangeEvent, useState, Fragment, memo } from 'react'
import { toast } from 'react-toastify'
import { Stack } from '@mui/material'

import { SortByTypeBasic } from 'components/SortByTypeBasic/SortByTypeBasic'
import { CustomModal } from 'components/UI/CustomModal/CustomModal'
import { useActionCreatorsTyped, useAppDispatch, useAppSelector } from 'hooks/redux'
import { fetchAddPeriodicPayment } from 'service/PeriodicPaymentThunks'
import { periodicPaymentActions } from 'store/reducers/PeriodicPaymentSlice'
import { StatisticsButton } from '../../styled/StatisticsButton'
import { PeriodicPayment } from 'models/requests/periodic-payment-requests.model'
import { shiftsActions } from 'store/reducers/ShiftsSlice'

export const StatisticsAddAmountOfFinesModal = memo(() => {
  const isAddPeriodicPaymentModal = useAppSelector(state => state.periodicPaymentReducer.isAddPeriodicPaymentModal)
  const periodicPaymentType = useAppSelector(state => state.periodicPaymentReducer.periodicPaymentType)
  const employeeId = useAppSelector(state => state.employeesReducer.employeeId)
  const type = useAppSelector(state => state.shiftsReducer.type)

  const actionsPeriodicPayment = {
    ...periodicPaymentActions,
    fetchAddPeriodicPayment,
  }

  const dispatch = useAppDispatch()

  const [statePeriodicPayment, setStatePeriodicPayment] = useState({
    amount: null,
    comment: null,
  })

  const actions = useActionCreatorsTyped(actionsPeriodicPayment)

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type } = e.target

    setStatePeriodicPayment(prev => ({ ...prev, [name]: type === 'number' ? Number(value) : value }))
  }

  const handleSave = () => {
    if (!employeeId || periodicPaymentType === null) return

    if ((!statePeriodicPayment.amount && statePeriodicPayment.amount === null) || statePeriodicPayment.amount < 1) {
      toast.error(`Введите валидное значения ${periodicPaymentType === PeriodicPayment.SupervisorsFine ? 'штрафа' : 'компенсации'}`)
      return
    }

    actions
      .fetchAddPeriodicPayment({
        id: employeeId,
        params: [
          {
            amount: statePeriodicPayment.amount,
            comment: statePeriodicPayment.comment,
            periodicPaymentType,
            timeType: type,
          },
        ],
      })
      .then(() => {
        setStatePeriodicPayment({ amount: null, comment: null })
        toast.success(`${periodicPaymentType === PeriodicPayment.SupervisorsFine ? 'Добавлен штраф' : 'Добавлена компенсация'}`)
        onClose()
        dispatch(shiftsActions.setIsUpdateShift())
      })
  }

  const onClose = () => actions.setIsAddPeriodicPaymentModal(false)

  const elements = [
    {
      name: 'amount',
      type: 'number',
      value: statePeriodicPayment.amount,
      onChange: handleChange,
      label: 'Сумма',
    },
    {
      name: 'comment',
      type: 'input',
      value: statePeriodicPayment.comment,
      onChange: handleChange,
      label: 'Комментарии',
      multiline: true,
    },
  ]

  return (
    <CustomModal
      sx={{ maxWidth: 400, padding: 4 }}
      titleModal={periodicPaymentType === PeriodicPayment.SupervisorsFine ? 'Сумма штрафа' : 'Сумма компенсации'}
      open={isAddPeriodicPaymentModal}
      onClose={onClose}
    >
      <Stack>
        <Stack gap={3} mb={3}>
          {elements.map(filter => (
            <Fragment key={filter.name}>{SortByTypeBasic(filter)}</Fragment>
          ))}
        </Stack>
        <Stack gap={2} direction='row' justifyContent={'flex-end'}>
          <StatisticsButton onClick={handleSave}>Сохранить</StatisticsButton>
          <StatisticsButton
            onClick={onClose}
            sx={{ fontWeight: 400, minHeight: 42, minWidth: 118, textTransform: 'none' }}
            variant='outlined'
          >
            Отмена
          </StatisticsButton>
        </Stack>
      </Stack>
    </CustomModal>
  )
})
