import { createTheme } from '@mui/material/styles'

// assets
import AvertaRegular from '../assets/fonts/Averta-Regular.woff2'
import AvertaSemiBold from '../assets/fonts/Averta-SemiBold.woff2'
import AvertaBold from '../assets/fonts/Averta-Bold.woff2'

export const theme = createTheme({
  typography: {
    fontFamily: 'Averta, sans-serif',
    h6: {
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: 1.3,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Averta';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Averta'), local('Averta-Regular'), url(${AvertaRegular}) format("woff2");
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        },
        @font-face {
          font-family: 'Averta';
          font-style: normal;
          font-display: swap;
          font-weight: 600;
          src: local('Averta'), local('Averta-SemiBold'), url(${AvertaSemiBold}) format("woff2");
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        },
        @font-face {
          font-family: 'Averta';
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          src: local('Averta'), local('Averta-Bold'), url(${AvertaBold}) format("woff2");
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        },
        *,
        *::before,
        *::after {
          margin: 0;
          padding: 0;
          list-style: none;
          text-decoration: none;
          box-sizing: border-box;
        }
        html, body {
          height: 100%;
        },
        ::-webkit-scrollbar {
          width: 10px;
          background-color: rgba(218, 221, 226, 0.6);
        }
        ::-webkit-scrollbar-track {
          border-radius: 4px;
          background-color: rgba(218, 221, 226, 0.6);
          border-left: 4px solid white;
          border-right: 4px solid white;
        }
        ::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background-color: #b0bacc;
          border-left: 3px solid white;
          border-right: 3px solid white;
        }
        #root {
          min-height: 100%;
          display: flex;
          flex-direction: column;
          overflow-x: hidden;
        }
      `,
    },
    MuiCard: {
      styleOverrides: {
        root: {
          background: '#FFFFFF',
          boxShadow: '0px 30px 60px rgba(32, 56, 85, 0.15)',
          borderRadius: 8,
          padding: '15px 34px',
          height: '100%',
        },
      },
    },
  },
  palette: {
    background: {
      default: '#F5F5F5',
    },
    primary: {
      main: '#5D86EF',
    },
    text: {
      primary: '#37445A',
      // secondary: '#F5F5F5',
    },
  },
})
