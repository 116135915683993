import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { StatusType } from '../../models/common/common.model'
import { OrganizationResponse } from '../../models/responses/organization.response'

interface IOrganizationState {
  id: number | string
  name: string
  managersFullName: string
  foundingOrganizationId: string | number | null
}

interface InitialState {
  currentOrganization: OrganizationResponse | null
  organizations: OrganizationResponse[] | null
  childrenOrganizations: OrganizationResponse[] | null
  organizationId: number | null
  status: StatusType
  pageOrg: number
  perPageOrg: number
  lastPageOrg: number
  totalItemsOrg: number
  pageChildrenOrg: number
  perPageChildrenOrg: number
  totalItemsChildrenOrg: number
  lastPageChildrenOrg: number
  isModalAddCompany: boolean
  isEdit: boolean
  stateModalEdit: IOrganizationState | null
  isFetchOrganization: boolean
}

const initialState: InitialState = {
  isFetchOrganization: false,
  currentOrganization: null,
  organizations: [],
  childrenOrganizations: [],
  organizationId: localStorage.getItem('organizationId') ? JSON.parse(localStorage.getItem('organizationId') || 'null') : null,
  pageOrg: 1,
  perPageChildrenOrg: 10,
  perPageOrg: 10,
  totalItemsChildrenOrg: 1,
  totalItemsOrg: 1,
  pageChildrenOrg: 1,
  lastPageOrg: 1,
  lastPageChildrenOrg: 1,
  status: 'init',
  isModalAddCompany: false,
  isEdit: false,
  stateModalEdit: {
    id: '',
    name: '',
    managersFullName: '',
    foundingOrganizationId: '',
  },
}

export const organizationSlice = createSlice({
  name: 'organizationSlice',
  initialState,
  reducers: {
    organizationAll: (state, action: PayloadAction<OrganizationResponse[]>) => {
      state.organizations = action.payload.filter((item: OrganizationResponse) => !item.isDeleted)
    },
    organizationChildrenAll: (state, action: PayloadAction<OrganizationResponse[]>) => {
      state.childrenOrganizations = action.payload.filter((item: OrganizationResponse) => !item.isDeleted)
    },
    setOrganizationId: (state, action: PayloadAction<number | null>) => {
      state.organizationId = action.payload
      localStorage.setItem('organizationId', JSON.stringify(action.payload))
    },
    setIsFetchOrganization: (state, action: PayloadAction<boolean>) => {
      state.isFetchOrganization = !action.payload
    },
    setCurrentOrganization: (state, action: PayloadAction<OrganizationResponse | null>) => {
      state.currentOrganization = action.payload
    },
    setStatus: (state, action: PayloadAction<StatusType>) => {
      state.status = action.payload
    },
    organizationResetData: state => {
      localStorage.setItem('organizationId', 'null')
      state.organizationId = null
      state.organizations = null
      state.currentOrganization = null
      state.childrenOrganizations = null
      state.status = 'init'
    },
    setPageOrg: (state, action: PayloadAction<number>) => {
      state.pageOrg = action.payload
    },
    setPerPageOrg: (state, action: PayloadAction<number>) => {
      state.perPageOrg = action.payload
    },
    setTotalItemsOrg: (state, action: PayloadAction<number>) => {
      state.totalItemsOrg = action.payload
    },
    setTotalItemsChildrenOrg: (state, action: PayloadAction<number>) => {
      state.totalItemsChildrenOrg = action.payload
    },
    setPerPageChildrenOrg: (state, action: PayloadAction<number>) => {
      state.perPageChildrenOrg = action.payload
    },
    setChildrenPageOrg: (state, action: PayloadAction<number>) => {
      state.pageChildrenOrg = action.payload
    },
    setLastPageOrg: (state, action: PayloadAction<number>) => {
      state.lastPageOrg = action.payload
    },
    setLastChildrenPageOrg: (state, action: PayloadAction<number>) => {
      state.lastPageChildrenOrg = action.payload
    },
    setIsModal: (state, action: PayloadAction<boolean>) => {
      state.isModalAddCompany = action.payload
    },
    setIsEdit: (state, action: PayloadAction<boolean>) => {
      state.isEdit = action.payload
    },
    setStateModalEdit: (state, action: PayloadAction<IOrganizationState | null>) => {
      state.stateModalEdit = action.payload
    },
  },
})

export const { reducer: organizationReducer, actions: organizationActions } = organizationSlice
