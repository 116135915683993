import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { createRoot } from 'react-dom/client'
import { ThemeProvider } from '@mui/material'

import { setupStore } from './store/store'
import { Toaster } from './components/UI/Toaster/Toaster'
import { theme } from './theme/theme'
import { Modals } from './components/Modals/Modals'

import { App } from './App'

const store = setupStore()

const root = createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <BrowserRouter>
        <Toaster />
        <Modals />
        <App />
      </BrowserRouter>
    </Provider>
  </ThemeProvider>,
)
