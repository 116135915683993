import { checkFloatingPoint } from 'helpers/checkFloatingPoint'
import { IShift } from 'models/shift.model'
import { BoxBodyCalendarStyled } from '../styled/StatisticsTable.styled'

export const StatisticsTableSwitchStatusCalendar = (shiftItem: IShift) => {
  let background: string = ''
  let color: string = ''
  let content: string = ''

  switch (shiftItem?.status.designation) {
    case 'П':
      background = 'rgba(116, 104, 228, 0.2)'
      color = '#7468E4'
      content = shiftItem.status.designation
      break
    case 'О':
      background = 'rgba(254, 182, 72, 0.2)'
      color = '#FEB648'
      content = shiftItem.status.designation
      break
    case 'В':
      background = 'rgba(164, 172, 188, 0.2)'
      color = '#A4ACBC'
      content = shiftItem.status.designation
      break
    case 'Б':
      background = 'rgba(93, 134, 239, 0.2)'
      color = '#5D86EF'
      content = shiftItem.status.designation
      break
    case 'НС':
      background = 'rgba(109, 195, 0, 0.2)'
      color = '#6DC300'
      content = `${
        checkFloatingPoint(shiftItem.totalHoursForShift) ? shiftItem.totalHoursForShift.toFixed(1) : shiftItem.totalHoursForShift || 0
      }ч`
      break
    case 'У':
      background = 'rgba(255, 88, 51, 0.2)'
      color = '#FF5833'
      content = shiftItem.status.designation
      break
    default:
      content = '-'
      break
  }

  return <BoxBodyCalendarStyled sx={{ background, color }}>{content}</BoxBodyCalendarStyled>
}
