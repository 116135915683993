import { FC, memo, useEffect, useState, useCallback } from 'react'
import { Box, Stack, Tooltip } from '@mui/material'
import { toast } from 'react-toastify'

import { useCheckClaims } from 'hooks/useCheckClaims'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { IShift, ShiftModel } from 'models/shift.model'
import { ReportTime } from 'helpers/timeHelpers'
import { TableBodyCellStyled } from '../../styled/StatisticsTable.styled'
import { shiftsActions } from 'store/reducers/ShiftsSlice'
import { employeesActions } from 'store/reducers/EmployeesSlice'
import { checkFloatingPoint } from 'helpers/checkFloatingPoint'
import { StatisticsTableSwitchStatusCalendar } from '../StatisticsTableSwitchStatusCalendar'

interface Props {
  shift: ShiftModel
}

interface ShiftItem {
  data: string
  shiftItem: IShift
}

export const StatisticsTableBodyCalendar: FC<Props> = memo(props => {
  const { shift } = props

  const intervalData = useAppSelector(state => state.shiftsReducer.intervalData)
  const isEditStatuses = useAppSelector(state => state.statusReducer.isEditStatuses)

  const { checkClaims } = useCheckClaims()

  const dispatch = useAppDispatch()

  const [shiftItems, setShiftItems] = useState<ShiftItem[]>([])

  useEffect(() => {
    let obj = {}

    intervalData.forEach((data: string) => {
      obj = {
        ...obj,
        [data]: { data, shiftItem: null },
      }
    })

    if (shift.shifts) {
      intervalData.forEach(data => {
        shift?.shifts?.forEach(shiftItem => {
          if (ReportTime.isEqualCheckByDay(data, shiftItem.shiftStartedAt)) {
            obj = {
              ...obj,
              [data]: { data, shiftItem },
            }
          }
        })
      })
    }

    setShiftItems(Object.values(obj))
  }, [shift, intervalData])

  const addShift = useCallback(
    (item: ShiftItem) => {
      if (!shift.employeeProject?.project?.projectInfo) {
        toast.error('Задайте в настройка проекта время смен')
        return
      }

      if (item.shiftItem) {
        dispatch(shiftsActions.setStateShiftEmployee(item.shiftItem))
        dispatch(shiftsActions.setIsShiftEditModal(true))
      }
      
      dispatch(shiftsActions.setStartDateShiftStatus(item.data))
      dispatch(employeesActions.setEmployeeId(shift.employeeProject.id))
      dispatch(shiftsActions.setShiftEmployee(shift))
      dispatch(shiftsActions.setIsShiftModal(true))
    },
    [shift],
  )

  const onTitleTooltip = useCallback(
    (item: IShift) => {
      if (!item && isEditStatuses && checkClaims('Front.statistics.edit.createShift')) return 'Создать смену'

      switch (item?.status.designation) {
        case 'П':
          return `${item.status.name} (${item.amountForShift ? item.amountForShift : 0})`
        case 'НС':
          return `(${ReportTime.getTime(item.shiftStartedAt)} - ${ReportTime.getTime(item.shiftEndedAt)}) ${
            checkFloatingPoint(item.amountForShift) ? item.amountForShift.toFixed(2) : item.amountForShift || 0
          } ₽`
        default:
          return item?.status?.name || false
      }
    },
    [isEditStatuses],
  )

  return (
    <Stack direction='row'>
      {shiftItems.map((item, idx) => {
        return (
          <TableBodyCellStyled
            key={item.data}
            sx={{
              display: 'flex',
              padding: 0,
              borderRight: shiftItems.length - 1 !== idx ? '1px solid #E8E7E7' : 'none',
            }}
          >
            <Tooltip title={onTitleTooltip(item.shiftItem)}>
              <Box
                onClick={() => (isEditStatuses ? addShift(item) : undefined)}
                sx={
                  isEditStatuses
                    ? {
                        cursor: 'pointer',
                        '&:hover': {
                          backgroundColor: '#adfdbb',
                        },
                      }
                    : undefined
                }
              >
                {StatisticsTableSwitchStatusCalendar(item.shiftItem)}
              </Box>
            </Tooltip>
          </TableBodyCellStyled>
        )
      })}
    </Stack>
  )
})
