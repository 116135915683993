import { ChangeEvent, useCallback, useState } from 'react'
import { Button, Stack } from '@mui/material'

import { MainTextField } from 'components/UI/TextFields/MainTextField/MainTextField'
import { useActionCreatorsTyped, useAppSelector } from 'hooks/redux'
import { shiftsActions } from 'store/reducers/ShiftsSlice'
import { ReportTime } from 'helpers/timeHelpers'

export const StatisticsActionsTimeFields = () => {
  const period = useAppSelector(state => state.shiftsReducer.period)
  const isEditStatuses = useAppSelector(state => state.statusReducer.isEditStatuses)

  const actions = useActionCreatorsTyped(shiftsActions)

  const [initialPeriod, setInitialPeriod] = useState(period)

  const handleChangePeriod = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value, name } = e.target

      setInitialPeriod(prev => ({ ...prev, [name]: ReportTime.getInputFormatDate(value) }))
    },
    [initialPeriod],
  )

  const handleClick = () => actions.setPeriod(initialPeriod)

  return (
    <Stack gap={2}>
      <Stack direction={'row'} gap={6.2}>
        <MainTextField
          name='start'
          disabled={isEditStatuses}
          defaultValue={ReportTime.getInputFormatDate(period.start)}
          sx={{ minWidth: 227, height: 54 }}
          label='Начало периода'
          type='date'
          onChange={handleChangePeriod}
        />
        <MainTextField
          name='end'
          disabled={isEditStatuses}
          defaultValue={ReportTime.getInputFormatDate(period.end)}
          sx={{ minWidth: 227, height: 54 }}
          label='Конец периода'
          type='date'
          onChange={handleChangePeriod}
        />
      </Stack>
      <Button onClick={handleClick} disabled={isEditStatuses} variant='contained' sx={{ alignSelf: 'flex-start' }}>
        Сформировать
      </Button>
    </Stack>
  )
}
