import React from 'react'
import { Button, ButtonProps } from '@mui/material'
import { useNavigate } from 'react-router-dom'

interface NavigationButtonProps extends ButtonProps {
  to: string
  state?: any
  title: string
}

export const NavigationButton: React.FC<NavigationButtonProps> = ({ to, title, state, ...props }) => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(to, { state })
  }

  return (
    <Button onClick={handleClick} variant='contained' fullWidth {...props}>
      {title}
    </Button>
  )
}
