import { memo, useMemo } from 'react'
import { Box } from '@mui/material'

import { CustomLoader } from 'components/UI/CustomLoader/CustomLoader'
import { useAppSelector } from 'hooks/redux'
import { projectActions } from 'store/reducers/ProjectSlice'
import { usePutProjectPassportController } from 'pages/ProjectPassport/controllers/usePutProjectPassportController'
import { SortByTypeBasic } from 'components/SortByTypeBasic/SortByTypeBasic'
import { BoxEdited } from 'components/UI/BoxEdited'

export const TabPanelBasicInformation = memo(() => {
  const { status, projectInfo, handleChange } = usePutProjectPassportController()

  const isEditProject = useAppSelector(state => state.projectReducer.isEditProject)

  const elements = useMemo(
    () => [
      {
        name: 'legalEntity',
        type: 'input',
        value: projectInfo?.legalEntity,
        label: 'Юридическое лицо',
        onChange: handleChange,
      },
      {
        name: 'requestPersonnel',
        type: 'multiInput',
        value: projectInfo?.requestPersonnel,
        label: 'Заявка на персонал',
        onChange: handleChange,
      },
      {
        name: 'schedule',
        type: 'input',
        value: projectInfo?.schedule,
        label: 'График работы',
        onChange: handleChange,
      },
      {
        name: 'location',
        type: 'input',
        value: projectInfo?.location,
        label: 'Местонахождение проекта',
        onChange: handleChange,
      },
      {
        name: 'saleRatesWithVAT',
        type: 'number',
        value: projectInfo?.saleRatesWithVAT,
        label: 'Продажная ставка с НДС',
        onChange: handleChange,
      },
      {
        name: 'dayShiftStartTime',
        type: 'input',
        value: projectInfo?.dayShiftStartTime,
        label: 'Время начала дневных смен',
        inputType: 'time',
        onChange: handleChange,
      },
      {
        name: 'technicalTask',
        type: 'input',
        value: projectInfo?.technicalTask,
        onChange: handleChange,
        label: 'Техническое задание',
      },
      {
        name: 'saleRatesWithoutVAT',
        type: 'number',
        value: projectInfo?.saleRatesWithoutVAT,
        label: 'Продажная ставка без НДС',
        onChange: handleChange,
      },
      {
        name: 'dayShiftEndTime',
        type: 'input',
        value: projectInfo?.dayShiftEndTime,
        onChange: handleChange,
        label: 'Время окончания дневных смен',
        inputType: 'time',
      },
      {
        name: 'contactInformation',
        type: 'multiInput',
        value: projectInfo?.contactInformation,
        onChange: handleChange,
        label: 'Контактные данные',
      },
      {
        name: 'provision',
        type: 'multiInput',
        value: projectInfo?.provision,
        label: 'Обеспечение (от заказчика)',
        onChange: handleChange,
      },
      {
        name: 'nightShiftStartTime',
        type: 'input',
        value: projectInfo?.nightShiftStartTime,
        label: 'Время начала ночных смен',
        inputType: 'time',
        onChange: handleChange,
      },
      {
        name: 'orientationOfBuildings',
        type: 'multiInput',
        value: projectInfo?.orientationOfBuildings,
        onChange: handleChange,
        label: 'Направление деятельности проекта',
      },
      {
        name: 'termsPaymentOfInvoices',
        type: 'multiInput',
        value: projectInfo?.termsPaymentOfInvoices,
        label: 'Условия оплаты счетов',
        onChange: handleChange,
      },
      {
        name: 'nightShiftEndTime',
        type: 'input',
        value: projectInfo?.nightShiftEndTime,
        onChange: handleChange,
        label: 'Время окончания ночных смен',
        inputType: 'time',
      },
      {},
      {},
      {
        name: 'fineForTruancy',
        type: 'number',
        value: projectInfo?.fineForTruancy,
        onChange: handleChange,
        label: 'Штраф за прогул',
      },
    ],
    [projectInfo],
  )

  if (status === 'init' || status === 'loading' || !projectInfo) return <CustomLoader />

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 340px)',
        columnGap: 8.8,
        rowGap: 4,
      }}
    >
      {projectInfo &&
        elements.map(filter => (
          <BoxEdited isEdited={isEditProject} key={filter.name}>
            {SortByTypeBasic(filter, projectInfo, projectActions.setProjectInfo, true)}
          </BoxEdited>
        ))}
    </Box>
  )
})
