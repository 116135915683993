import { AppDispatch } from '../store/store'
import { currentUserSlice } from '../store/reducers/CurrentUserSlice'
import { qrAuthDeleteUser, qrAuthPostProfilePhoto } from '../api/utils/userApi'

export const postUserProfilePhoto = (id: number, params: FormData) => async (dispatch: AppDispatch) => {
  try {
    const response = await qrAuthPostProfilePhoto(id, params)

    dispatch(currentUserSlice.actions.editPhotoCurrentUser(response.data.items.profilePhoto || ''))

    return Promise.resolve(response)
  } catch (e) {
    return Promise.reject(e)
  }
}

export const deleteUser = (id: number) => async (dispatch: AppDispatch) => {
  try {
    await qrAuthDeleteUser(id)
  } catch (err: any) {
    return Promise.reject(err)
  } finally {
  }
}
