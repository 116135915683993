import { memo, useCallback, FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material'

import { briefcaseGreen, noteText, userSquare } from 'assets/images'
import { useActionCreatorsTyped, useAppDispatch, useAppSelector } from 'hooks/redux'
import { projectActions } from 'store/reducers/ProjectSlice'
import { employeesActions } from 'store/reducers/EmployeesSlice'
import { useCheckClaims } from 'hooks/useCheckClaims'
import { Statuses } from 'components/UI/Statuses'
import { ProjectModel } from 'models/project.model'
import { fetchProjectById } from 'service/Projects'
import { TypographyMainStyled } from 'components/UI/styled/TypographyMain'

interface Props {
  project: ProjectModel
}

export const ProjectCardContentItem: FC<Props> = memo(props => {
  const { project } = props

  const projectId = useAppSelector(state => state.projectReducer.projectId)

  const actionsProject = useActionCreatorsTyped(projectActions)
  const actionsEmployee = useActionCreatorsTyped(employeesActions)

  const { checkClaims } = useCheckClaims()

  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  const navigateToPassportProject = useCallback((id: number) => navigate(`project/${id}`), [])

  const openCardEmployee = useCallback(
    async (idProject: number) => {
      if (projectId === idProject) return
      await dispatch(fetchProjectById(idProject)).then(() => {
        actionsEmployee.employeesReset()
        actionsProject.setProjectId(idProject)
      })
    },
    [projectId],
  )

  const openDownloadReportModal = useCallback(() => {
    actionsProject.setProjectId(project.id)
    actionsProject.setIsModalDownloadReport(true)
  }, [])

  return (
    <Box>
      <Stack direction={'row'} alignItems='center' justifyContent={'space-between'}>
        <Stack direction='row' alignItems='center' gap={2}>
          <TypographyMainStyled
            sx={{
              color: projectId === project.id ? 'primary.main' : 'text.primary',
              wordBreak: 'break-word',
            }}
            isHover={projectId !== project.id}
            onClick={() => openCardEmployee(project.id)}
          >
            {project.name}
          </TypographyMainStyled>
          <Stack direction='row' gap={1}>
            {checkClaims('Front.projectCard.showPassportProject') && (
              <Tooltip title='Паспорт проекта'>
                <IconButton sx={{ p: 0 }} onClick={() => navigateToPassportProject(project.id)}>
                  <img src={briefcaseGreen} alt={briefcaseGreen} />
                </IconButton>
              </Tooltip>
            )}
            {checkClaims('Front.projectCard.showStatistics') && (
              <Tooltip title='Табель учета'>
                <IconButton sx={{ p: 0 }} onClick={openDownloadReportModal}>
                  <img src={noteText} alt={noteText} />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        </Stack>
        <Statuses status={project.projectStatus} />
      </Stack>
      <Stack direction='row' gap={3}>
        <Tooltip title='Кол-во сотрудников'>
          <Stack direction='row' gap={1} alignItems='center'>
            <img src={userSquare} alt={userSquare} />
            <Typography color='rgba(55, 68, 90, 0.7)'>{project.employeesNumber}</Typography>
          </Stack>
        </Tooltip>
      </Stack>
    </Box>
  )
})
