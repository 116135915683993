import { useCallback } from 'react'

import { useAppSelector } from './redux'

export const useCheckClaims = () => {
  const claims = useAppSelector(state => state.currentUserReducer.currentUser)?.role.roleClaims

  const checkClaims = useCallback((text: string): boolean | null => (claims ? claims.includes(text) : null), [])

  return { checkClaims }
}
