import { FC, useCallback, useMemo, memo } from 'react'
import { Box, Stack } from '@mui/material'

import { useOrganizationCardActionsController } from '../../controllers/useOrganizationCardActionsController'
import { useAppDispatch } from 'hooks/redux'
import { projectActions } from 'store/reducers/ProjectSlice'
import { organizationActions } from 'store/reducers/OrganizationSlice'
import { briefcase, hierarchy, userSquare } from 'assets/images'
import { OrganizationResponse } from 'models/responses/organization.response'
import { useCheckClaims } from 'hooks/useCheckClaims'
import { OrganizationCardContentItemMenu } from './OrganizationCardContentItemMenu'
import { OrganizationCardContentItemInfoType } from 'features/MainPage/modules/OrganizationCard/types/OrganizationCardTypes'
import { OrganizationCardContentItemInfo } from './OrganizationCardContentItemInfo'
import { TypographyMainStyled } from 'components/UI/styled/TypographyMain'

interface IProps {
  org: OrganizationResponse
}

export const OrganizationCardContentItem: FC<IProps> = memo(({ org }) => {
  const { checkClaims } = useCheckClaims()
  const { getOrganization } = useOrganizationCardActionsController()

  const dispatch = useAppDispatch()

  const getOrg = useCallback(() => {
    dispatch(projectActions.projectReset())
    dispatch(projectActions.setProjectId(null))
    dispatch(organizationActions.setOrganizationId(org.id))
    getOrganization(org.id)
  }, [])

  const elementsInfo: OrganizationCardContentItemInfoType[] = useMemo(
    () => [
      { image: hierarchy, tooltipTitle: 'Кол-во доч. организаций', contentInfo: 0 },
      { image: briefcase, tooltipTitle: 'Кол-во проектов', contentInfo: org.activeProjects },
      { image: userSquare, tooltipTitle: 'Кол-во сотрудников', contentInfo: org.employees },
    ],
    [],
  )
  return (
    <Box>
      <Stack direction={'row'} gap={1.5} alignItems='center'>
        <TypographyMainStyled onClick={getOrg}>{org.name}</TypographyMainStyled>
        {checkClaims('Front.organizationCard.item.Modal') && <OrganizationCardContentItemMenu org={org} />}
      </Stack>
      <Stack direction='row' gap={3}>
        {elementsInfo.map(({ contentInfo, image, tooltipTitle }) => {
          return <OrganizationCardContentItemInfo key={tooltipTitle} {...{ contentInfo, image, tooltipTitle }} />
        })}
      </Stack>
    </Box>
  )
})
