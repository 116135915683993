import { ROLES_COMPANY_LEADER } from './../helpers/roles'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { CompanyLeaderRequestsBodyModel } from 'models/requests/employee-role-requests/company-leader-requests.model'
import { qrAuthPostCreateWithRoleCompanyLeader } from 'api/utils/employeeRole/companyLeaderApi'

export const fetchCreateCompanyLeader = createAsyncThunk(
  `${ROLES_COMPANY_LEADER}/fetchCreateCompanyLeader`,
  async (params: CompanyLeaderRequestsBodyModel.postCreate, { rejectWithValue, dispatch }) => {
    try {
      const response = await qrAuthPostCreateWithRoleCompanyLeader(params)
      return response.data.items
    } catch (error: any) {
      const err: AxiosError = error
      return rejectWithValue(err.message)
    }
  },
)
