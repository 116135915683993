import { SearchRequestsQueryModel } from './../models/requests/search-requests.model'
import { AppDispatch } from '../store/store'
import { employeesActions } from '../store/reducers/EmployeesSlice'
import { qrAuthGetChildOrganization } from '../api/utils/organizationApi'
import { qrAuthDeleteWorkersInProject, qrAuthGetProjectsByFilter } from '../api/utils/projectApi'
import { SearchRequestsBodyModel } from '../models/requests/search-requests.model'
import { qrAuthGetSearchEmployees } from '../api/utils/searchApi'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { getWorkerInProject } from './Worker'

// Костыль (потом надо будет убрать)
// export const getEmployeesForSelect = (params: EmployeeRequestsBodyModel.postFilter) => async (dispatch: AppDispatch) => {
//   try {
//     const response = await qrAuthGetEmployeesByFilter({ page: 1, perPage: 100000 }, params)

//     return Promise.resolve(response)
//   } catch (err: any) {
//     return Promise.reject(err)
//   }
// }

// export const getEmployees =
//   (query: EmployeeRequestsQueryModel.postFilter = {}, params: EmployeeRequestsBodyModel.postFilter) =>
//   async (dispatch: AppDispatch) => {
//     try {
//       dispatch(employeesActions.setStatus('loading'))

//       const response = await qrAuthGetEmployeesByFilter(query, params)

//       let arrayForemans: EmployeeModel[] = []
//       let data: EmployeeModel[] = []

//       response.data.items.map((employee: EmployeeModel) => {
//         if (employee.user.role && employee.user.role.name && employee.user.role.name.toLowerCase() === ROLES_FOREMAN.toLowerCase()) {
//           arrayForemans = [...arrayForemans, employee]
//         } else {
//           data = [...data, employee]
//         }

//         return employee
//       })

//       dispatch(employeesActions.setEmployees([...arrayForemans, ...data]))
//       dispatch(employeesActions.setPageCount(response.data?.paging?.lastPage || 1))

//       return Promise.resolve(response)
//     } catch (err: any) {
//       dispatch(employeesActions.setStatus('error'))
//       return Promise.reject(err)
//     } finally {
//       dispatch(employeesActions.setStatus('success'))
//     }
//   }

export const getSearchEmployees =
  (query: SearchRequestsQueryModel.postEmployee = {}, params: SearchRequestsBodyModel.postEmployee = {}) =>
  async (dispatch: AppDispatch) => {
    dispatch(employeesActions.setStatus('init'))
    try {
      dispatch(employeesActions.setStatus('loading'))

      const response = await qrAuthGetSearchEmployees(
        {
          ...query,
          includeProject: true,
          includeWorker: true,
          includeStatus: true,
          includePayment: true,
          includePosition: true,
          includeQrCode: true,
        },
        params,
      )

      // let arrayForemans: SearchEmployeeModel[] = []
      // let data: SearchEmployeeModel[] = []

      // response.data.items.map((item: SearchEmployeeModel) => {
      //   if (item.role?.name && item.role?.name && item.role?.name.toLowerCase() === ROLES_FOREMAN.toLowerCase()) {
      //     arrayForemans = [...arrayForemans, item]
      //   } else {
      //     data = [...data, item]
      //   }

      //   return item
      // })

      // dispatch(employeesActions.setSearchEmployees([...arrayForemans, ...data]))
      dispatch(employeesActions.setSearchEmployees(response.data.items))
      dispatch(employeesActions.setPageCount(response.data?.paging?.lastPage || 1))

      return Promise.resolve(response)
    } catch (err: any) {
      dispatch(employeesActions.setStatus('error'))
      return Promise.reject(err)
    } finally {
      dispatch(employeesActions.setStatus('success'))
    }
  }

export const getEmployeesProject = (orgId: number) => async (dispatch: AppDispatch) => {
  try {
    let setOrgIds: any[] = [orgId]
    const { data } = await qrAuthGetChildOrganization(Number(orgId), {}, {})

    setOrgIds.length <= 1 && data.items.map(item => setOrgIds.push(item.id))

    const response = await qrAuthGetProjectsByFilter({ organizationIds: setOrgIds }, {})

    dispatch(employeesActions.setAllEmployees([...response.data.items]))
  } catch (err: any) {
    return Promise.reject(err)
  }
}

export const fetchDeleteEmployeeInProject = createAsyncThunk(
  `projectEmployee/fetchDeleteEmployeeInProject`,
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await qrAuthDeleteWorkersInProject(id)
      return response.data.items
    } catch (error: any) {
      const err: AxiosError = error
      return rejectWithValue(err.message)
    }
  },
)

// export const editEmployeeForOrganization = (id: number, data: EmployeeRequestsBodyModel.put) => async (dispatch: AppDispatch) => {
//   try {
//     const res = await qrAuthPutEmployeeToProject(id, data)

//     return Promise.resolve(res)
//   } catch (err: any) {
//     return Promise.reject(err)
//   }
// }

// export const editEmployee = (id: number, data: EmployeeRequestsBodyModel.putUser) => async (dispatch: AppDispatch) => {
//   try {
//     const res = await qrAuthPutEmployee(id, data)

//     return Promise.resolve(res)
//   } catch (err: any) {
//     return Promise.reject(err)
//   }
// }

// export const addEmployeeOrgAmin = (data: EmployeeRequestsBodyModel.post) => async (dispatch: AppDispatch) => {
//   try {
//     const res = await qrAuthPostEmployee(data)

//     return Promise.resolve(res)
//   } catch (err: any) {
//     return Promise.reject(err)
//   }
// }

// export const addEmployeeForOrganization = (data: EmployeeRequestsBodyModel.postAddToProject) => async (dispatch: AppDispatch) => {
//   try {
//     const res = await qrAuthPostEmployeeAddToProject(data)

//     return Promise.resolve(res)
//   } catch (err: any) {
//     return Promise.reject(err)
//   }
// }

// export const postEmployeeRoleWorker = (params: EmployeeRequestsBodyModel.postWorker) => async (dispatch: AppDispatch) => {
//   try {
//     const response = await qrAuthPostEmployeeWorker(params)

//     return Promise.resolve(response)
//   } catch (e) {
//     return Promise.reject(e)
//   }
// }

// export const postTransferEmployeeToProject =
//   (id: number, params: EmployeeRequestsBodyModel.postMoveToProject) => async (dispatch: AppDispatch) => {
//     try {
//       const response = await qrAuthMoveEmployeeToProject(id, params)

//       return Promise.resolve(response)
//     } catch (e) {
//       return Promise.reject(e)
//     }
//   }
