import { memo, useCallback } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { Statuses } from 'components/UI/Statuses'

import { ReportTime } from 'helpers/timeHelpers'
import { useAppSelector } from 'hooks/redux'

export const StatisticsInfo = memo(() => {
  const shifts = useAppSelector(state => state.shiftsReducer.shifts)
  const { end, start } = useAppSelector(state => state.shiftsReducer.period)
  const activeProject = useAppSelector(state => state.projectReducer.activeProject)

  const renderContent = useCallback(() => {
    if (shifts && shifts.length) {
      const { project, employee } = shifts[0].employeeProject
      return (
        <Stack>
          <Typography fontSize={24}>Организация: {employee.organization.name}</Typography>
          <Stack direction='row' alignItems={'center'} gap={2}>
            <Typography fontSize={24}>Проект: {project.name}</Typography>
            <Statuses status={activeProject.projectStatus} />
          </Stack>
        </Stack>
      )
    }
    return null
  }, [shifts])

  return (
    <Box mb={3}>
      <Stack direction='row' gap={2} justifyContent='space-between'>
        {renderContent()}
        <Typography fontSize={24} alignSelf='flex-end'>
          {ReportTime.getDotFormatDate(start)} - {ReportTime.getDotFormatDate(end)}
        </Typography>
      </Stack>
    </Box>
  )
})
