import { memo } from 'react'
import { Avatar, Box, Stack, Typography } from '@mui/material'

import { CustomLoader } from 'components/UI/CustomLoader/CustomLoader'
import { MainTextField } from 'components/UI/TextFields/MainTextField/MainTextField'
import { useAppSelector } from 'hooks/redux'
import { usePutProjectPassportController } from '../../../controllers/usePutProjectPassportController'
import { ProjectPassportHeaderActions } from './ProjectPassportHeaderActions'
import { Statuses } from 'components/UI/Statuses'

export const ProjectPassportHeaderInfo = memo(() => {
  const { activeProject, handleChange, projectInfo } = usePutProjectPassportController()
  const isEditProject = useAppSelector(state => state.projectReducer.isEditProject)
  const status = useAppSelector(state => state.projectReducer.status)

  if (status === 'init' || status === 'loading' || !activeProject || !projectInfo) return <CustomLoader />

  return (
    <Stack direction={'row'} gap={2.5}>
      <Box>
        <Avatar
          sx={{
            width: 116,
            height: 116,
          }}
        >
          H
        </Avatar>
      </Box>
      <Stack justifyContent={'space-between'} gap={2}>
        <Box>
          <Typography fontSize={24} color='#37445ACC' lineHeight={1.2}>
            Проект:
          </Typography>
          {isEditProject ? (
            <MainTextField sx={{ minWidth: 300 }} onChange={handleChange} value={projectInfo.name} label='Название проекта' name='name' />
          ) : (
            <Stack direction='row' gap={1.8} alignItems='center'>
              <Typography fontWeight={600} lineHeight={1.2} fontSize={32} color='text.primary'>
                {projectInfo.name}
              </Typography>
              <Statuses status={activeProject?.projectStatus} />
            </Stack>
          )}
        </Box>
        <ProjectPassportHeaderActions />
      </Stack>
    </Stack>
  )
})
