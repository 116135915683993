// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
import { centerCrop, makeAspectCrop } from 'react-image-crop';

export function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number,
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}