import qs from 'query-string'
import { qrAuthTracker } from './../axios'

import { OrganizationResponse } from '../../models/responses/organization.response'
import { AxiosResponseModel } from '../types'
import { OrganizationRequestsBodyModel, OrganizationRequestsQueryModel } from '../../models/requests/organization-requests.model'

// =================== ORGANIZATION  ============================

// Получение одной организации
export const qrAuthGetOrganizationById = (id: number): AxiosResponseModel<OrganizationResponse> => qrAuthTracker.get(`/organization/${id}`)

// Создание
export const qrAuthPostOrganization = (params: OrganizationRequestsBodyModel.post): AxiosResponseModel<OrganizationResponse> =>
  qrAuthTracker.post(`/organization`, params)

// Получение по фильтру
export const qrAuthGetOrganizationByFilter = (
  params: OrganizationRequestsBodyModel.postFilter,
  query: OrganizationRequestsQueryModel.postFilter = {},
): AxiosResponseModel<OrganizationResponse[]> => qrAuthTracker.post(`/organization/filter?${qs.stringify(query)}`, params)

// Удаление
export const qrAuthDeleteOrganization = (id: number): AxiosResponseModel<null> => qrAuthTracker.delete(`/organization/${id}`)

// Редактирование
export const qrAuthEditOrganization = (id: number, params: OrganizationRequestsBodyModel.put): AxiosResponseModel<OrganizationResponse> =>
  qrAuthTracker.put(`/organization/${id}`, params)

// Получение дочерних организаций
export const qrAuthGetChildOrganization = (
  id: number,
  params: OrganizationRequestsBodyModel.postFilterSubsidiaries,
  query: OrganizationRequestsQueryModel.postFilterSubsidiaries = {},
): AxiosResponseModel<OrganizationResponse[]> => qrAuthTracker.post(`/organization/tree/${id}/subsidiaries?${qs.stringify(query)}`, params)
