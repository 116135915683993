import { useActionCreatorsTyped } from '../../redux'
import { ROLES_FOREMAN, ROLES_PROJECT_LEADER, ROLES_WORKER } from '../../../helpers/roles'
import { useEffect } from 'react'
import { useAppSelector } from '../../redux'
import { employeesActions } from 'store/reducers/EmployeesSlice'

export const useEmployeePutState = () => {
  const activeEmployee = useAppSelector(state => state.employeesReducer.activeEmployee)
  const statesEmployee = useAppSelector(state => state.employeesReducer.statesEmployee)
  const activeRoleEmployee = activeEmployee && activeEmployee.role.name

  const actions = useActionCreatorsTyped(employeesActions)

  useEffect(() => {
    if (!activeEmployee) return

    const { name, surname, patronymic, citizenship, email, phoneNumber, userName, birthDate } = activeEmployee

    const userData = {
      name,
      surname,
      patronymic,
      citizenship,
      email,
      phoneNumber,
      userName,
      birthDate,
      password: null,
    }

    switch (activeRoleEmployee) {
      case ROLES_FOREMAN:
        actions.setStatesEmployee({
          ...statesEmployee,
          statePutEmployee: {
            ...userData,
            workingMethod: activeEmployee.employee.employeeProjects[0].workingMethod,
          },
        })
        break
      case ROLES_PROJECT_LEADER:
        actions.setStatesEmployee({
          ...statesEmployee,
          statePutEmployee: {
            ...userData,
            workingMethod: activeEmployee.employee.employeeProjects[0].workingMethod,
          },
        })
        break
      case ROLES_WORKER:
        actions.setStatesEmployee({
          ...statesEmployee,
          statePutEmployee: {
            ...userData,
            dayTimeHourlyPayment: activeEmployee.employee.employeeProjects[0].dayTimeHourlyPayment,
            nightTimeHourlyPayment: activeEmployee.employee.employeeProjects[0].nightTimeHourlyPayment,
            positionId: activeEmployee.employee.employeeProjects[0].position.id,
            workingMethod: activeEmployee.employee.employeeProjects[0].workingMethod,
          },
        })
        break
    }
  }, [activeEmployee, activeRoleEmployee])
}
