import { AppDispatch } from '../store/store'
import {
  qrAuthDeleteOrganization,
  qrAuthEditOrganization,
  qrAuthGetChildOrganization,
  qrAuthGetOrganizationByFilter,
  qrAuthGetOrganizationById,
  qrAuthPostOrganization,
} from '../api/utils/organizationApi'
import { OrganizationResponse } from '../models/responses/organization.response'
import { organizationActions } from '../store/reducers/OrganizationSlice'
import { OrganizationRequestsBodyModel, OrganizationRequestsQueryModel } from '../models/requests/organization-requests.model'

export const organizationFetchAll =
  (query: OrganizationRequestsQueryModel.postFilter = {}, params: OrganizationRequestsBodyModel.postFilter = {}) =>
  async (dispatch: AppDispatch) => {
    try {
      const response = await qrAuthGetOrganizationByFilter(params, query)

      const filteredOrganization = response.data.items.filter((item: OrganizationResponse) => item.foundingOrganizationId === null)

      dispatch(organizationActions.setLastPageOrg(response.data?.paging?.lastPage || 1))
      dispatch(organizationActions.setTotalItemsOrg(response.data?.paging?.totalItems || 1))
      dispatch(organizationActions.organizationAll(filteredOrganization))
      dispatch(organizationActions.setStatus('loading'))
    } catch (err: any) {
      dispatch(organizationActions.setStatus('error'))
    } finally {
      dispatch(organizationActions.setStatus('success'))
    }
  }

export const organizationGetChildren =
  (
    id: number,
    params: OrganizationRequestsBodyModel.postFilterSubsidiaries,
    query: OrganizationRequestsQueryModel.postFilterSubsidiaries,
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      const response = await qrAuthGetChildOrganization(id, params, query)

      dispatch(organizationActions.organizationChildrenAll(response.data.items))
      dispatch(organizationActions.setLastChildrenPageOrg(response.data?.paging?.lastPage || 1))
      dispatch(organizationActions.setTotalItemsChildrenOrg(response.data?.paging?.totalItems || 1))
      dispatch(organizationActions.setStatus('loading'))
      return Promise.resolve(response)
    } catch (err) {
      console.log(err)
      dispatch(organizationActions.setStatus('error'))
      return Promise.reject(err)
    } finally {
      dispatch(organizationActions.setStatus('success'))
    }
  }

export const organizationGetCurrent = (id: number) => async (dispatch: AppDispatch) => {
  try {
    const response = await qrAuthGetOrganizationById(id)
    dispatch(organizationActions.setCurrentOrganization(response.data.items))
    dispatch(organizationActions.setStatus('loading'))
    return Promise.resolve(response)
  } catch (e) {
    console.log(e)
    dispatch(organizationActions.setStatus('error'))
    return Promise.reject(e)
  } finally {
    dispatch(organizationActions.setStatus('success'))
  }
}

export const organizationFetchAdd = (data: OrganizationRequestsBodyModel.post) => async (dispatch: AppDispatch) => {
  try {
    const response = await qrAuthPostOrganization(data)
    return Promise.resolve(response)
  } catch (err) {
    console.log(err)
  }
}

export const organizationDelete = (id: number) => async (dispatch: AppDispatch) => {
  try {
    await qrAuthDeleteOrganization(id)
  } catch (err) {
    console.log(err)
  }
}

export const organizationEdit = (id: number, params: OrganizationRequestsBodyModel.put) => async (dispatch: AppDispatch) => {
  try {
    await qrAuthEditOrganization(id, params)
  } catch (err) {
    console.log(err)
  }
}
