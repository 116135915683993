import { memo, FC } from 'react'
import { toast } from 'react-toastify'

import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { BasicModal } from '../BasicModal/BasicModal'
import { returnLocaleRole, ROLES_COMPANY_LEADER } from 'helpers/roles'
import { profilePhotoExtNames } from 'helpers/files'
import { useEmployeeCreateState } from 'hooks/employeeHooks/employeeState/useEmployeeCreateState'
import { employeesActions } from 'store/reducers/EmployeesSlice'
import { getCreateEmployeeParams } from 'helpers/employeeParams/getCreateEmployeeParams'
import { EmployeeStateStaffType, validationPostEmployee } from 'helpers/elementsCreateAndEditPeople'
import { useEmployeeFetches } from 'hooks/employeeHooks/useEmployeeFetches'

export const AddPeopleModal: FC = memo(() => {
  const { elements, statesEmployee } = useEmployeeCreateState()
  const staffState = { ...statesEmployee.stateCreateEmployee }
  const fetches = useEmployeeFetches()

  const currentRole = useAppSelector(state => state.rolesReducer.currentRole)
  const isModalAddEmployee = useAppSelector(state => state.employeesReducer.isModalAddEmployee)
  const optionsEmployee = useAppSelector(state => state.employeesReducer.optionsEmployee)

  const dispatch = useAppDispatch()

  const addEmployee = async (param: EmployeeStateStaffType) => {
    const valid = validationPostEmployee(param)

    if (valid.errorValidateMessages.length || !fetches.createEmployee) {
      valid.errorValidateMessages.forEach(item => toast.error(item))
      return
    }

    const params = getCreateEmployeeParams(param)
    await dispatch(fetches.createEmployee(params)).then(res => {
      if (res.meta.requestStatus !== 'fulfilled') return

      dispatch(employeesActions.employeesReset())
      dispatch(employeesActions.setIsAddEmployee())
      dispatch(employeesActions.setIsModalAddEmployee(false))
      dispatch(employeesActions.setIsModalChangeRoleAddEmployee(false))
      currentRole?.name === ROLES_COMPANY_LEADER && dispatch(employeesActions.setIsModalAddCompanyLeader())
      toast.success(`Сотрудник ${param.name} добавлен`)
    })
  }

  return (
    <>
      {isModalAddEmployee && currentRole && currentRole.name && elements && (
        <BasicModal
          open={isModalAddEmployee}
          redux
          setOpen={employeesActions.setIsModalAddEmployee}
          title={`Добавление сотрудника: Роль - ${returnLocaleRole(currentRole.name)}`}
          elements={elements}
          handleSave={addEmployee}
          options={optionsEmployee}
          initialState={staffState}
          fileExtNames={profilePhotoExtNames}
          isTabs={true}
        />
      )}
    </>
  )
})
