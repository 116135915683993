import { FC, memo } from 'react'
import { Stack, Tooltip } from '@mui/material'

import { plus, eye } from 'assets/images'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { PeriodicPayment } from 'models/requests/periodic-payment-requests.model'
import { ShiftModel } from 'models/shift.model'
import { employeesActions } from 'store/reducers/EmployeesSlice'
import { periodicPaymentActions } from 'store/reducers/PeriodicPaymentSlice'
import { TableBodyCellStyled } from '../../styled/StatisticsTable.styled'
import { fetchGetPeriodicPayment } from 'service/PeriodicPaymentThunks'
import { useCheckClaims } from 'hooks/useCheckClaims'

interface Props {
  shift: ShiftModel
  periodicPaymentType: PeriodicPayment
}

export const StatisticsTableBodyAmountOfFinesTableCell: FC<Props> = memo(props => {
  const { shift, periodicPaymentType } = props
  
  const dispatch = useAppDispatch()

  const { checkClaims } = useCheckClaims()

  const period = useAppSelector(state => state.shiftsReducer.period)
  const isEditStatuses = useAppSelector(state => state.statusReducer.isEditStatuses)

  const sumPeriodicPayment =
    periodicPaymentType === PeriodicPayment.SupervisorsFine
      ? shift.employeeProject.amountAllFinesFromSupervisor
      : shift.employeeProject.amountAllCompensation

  const setStatePeriodicPayment = () => {
    dispatch(employeesActions.setEmployeeId(shift.employeeProject.id))
    dispatch(periodicPaymentActions.setPeriodicPaymentType(periodicPaymentType))
  }

  const addPeriodicPayment = () => {
    setStatePeriodicPayment()
    dispatch(periodicPaymentActions.setIsAddPeriodicPaymentModal(true))
  }

  const viewPeriodicPayment = () => {
    setStatePeriodicPayment()
    dispatch(
      fetchGetPeriodicPayment({
        params: { employeeProjectIds: [shift.employeeProject.id], period, periodicPaymentType },
      }),
    )
    dispatch(periodicPaymentActions.setIsViewPeriodicPaymentModal(true))
  }

  return (
    <TableBodyCellStyled sx={{ position: 'relative' }}>
      {sumPeriodicPayment} {isEditStatuses && '₽'}
      {isEditStatuses && (
        <Stack sx={{ position: 'absolute', top: 2, right: 6 }}>
          {checkClaims('Front.periodicPayment.addFine') && (
            <Tooltip
              title={periodicPaymentType === PeriodicPayment.SupervisorsFine ? 'Добавить штраф' : 'Добавить компенсацию'}
              placement='right'
            >
              <img src={plus} alt={plus} width={18} style={{ cursor: 'pointer' }} onClick={addPeriodicPayment} />
            </Tooltip>
          )}
          {checkClaims('Front.periodicPayment.viewFines') && (
            <Tooltip
              title={periodicPaymentType === PeriodicPayment.SupervisorsFine ? 'Просмотреть штрафы' : 'Просмотреть компенсации'}
              placement='right'
            >
              <img src={eye} alt={eye} width={18} style={{ cursor: 'pointer' }} onClick={viewPeriodicPayment} />
            </Tooltip>
          )}
        </Stack>
      )}
    </TableBodyCellStyled>
  )
})
