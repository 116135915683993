import { UserResponse } from '../../../models/responses/user.response'
import { AxiosResponseModel } from '../../types'
import { qrAuthTracker } from '../../axios'
import { ForemanRequestsBodyModel } from '../../../models/requests/employee-role-requests/foreman-requests.model'

// ========================= FOREMAN =====================================

// Создать сотрудника организации с ролью "FOREMAN".
export const qrAuthPostCreateWithRoleForeman = (params: ForemanRequestsBodyModel.postCreate): AxiosResponseModel<UserResponse> =>
  qrAuthTracker.post(`/management/foreman`, params)

// Добавить существующего сотрудника с ролью "FOREMAN" в проект.
export const qrAuthPostExistWithRoleForeman = (params: any): AxiosResponseModel<UserResponse> =>
  qrAuthTracker.post(`/management/foreman/exist`, params)

// Обновить сотрудника с ролью "FOREMAN" по идентификатору.
export const qrAuthPutUpdateWithRoleForeman = (id: number, params: ForemanRequestsBodyModel.put): AxiosResponseModel<UserResponse> =>
  qrAuthTracker.put(`/management/foreman/${id}`, params)

// Изменить роль сотрудника на "FOREMAN" по идентификатору.
export const qrAuthPatchChangeRoleForeman = (id: number): AxiosResponseModel<null> => qrAuthTracker.patch(`/management/foreman/${id}/role`)

// Перенести сотрудника с ролью "FOREMAN" в другую организацию.
export const qrAuthPostTransferWithRoleForeman = (id: number, params: any): AxiosResponseModel<UserResponse> =>
  qrAuthTracker.patch(`/management/foreman/${id}/transfer`, params)
