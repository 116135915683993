import { useEffect, useState, FC, memo } from 'react'
import { toast } from 'react-toastify'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { Validation } from '../../helpers/validation'
import { organizationActions } from '../../store/reducers/OrganizationSlice'
import { OrganizationRequestsBodyModel } from '../../models/requests/organization-requests.model'
import { organizationEdit, organizationFetchAdd } from '../../service/Organization'
import { BasicModal } from './BasicModal/BasicModal'
import { useOrganizationCardActionsController } from '../../features/MainPage/modules/OrganizationCard/modules/OrganizationCardContent/controllers/useOrganizationCardActionsController'

interface IEdit {
  id: number
}

type IEditOrganizationParam = OrganizationRequestsBodyModel.put & IEdit

const elements = [
  {
    name: 'name',
    label: 'Название организации',
    type: 'input',
    required: true,
  },
  {
    name: 'managersFullName',
    label: 'ФИО руководителя',
    type: 'input',
    required: true,
  },
]

export const OrganizationsModal: FC = memo(() => {
  const isModalAddCompany = useAppSelector(state => state.organizationReducer.isModalAddCompany)
  const isEdit = useAppSelector(state => state.organizationReducer.isEdit)
  const state = useAppSelector(state => state.organizationReducer.stateModalEdit)
  const parentOrgId = useAppSelector(state => state.organizationReducer.organizationId)
  const currentOrganization = useAppSelector(state => state.organizationReducer.currentOrganization)

  const { getOrganization } = useOrganizationCardActionsController()

  const dispatch = useAppDispatch()

  const [titleModal, setTitleModal] = useState<string>('')

  const addCompany = (param: OrganizationRequestsBodyModel.post) => {
    if (!Validation.text(param.name)) {
      toast.error('Не валидное название организации', {
        toastId: param.name,
      })
      return
    }
    if (param.name.trim().length > 100) {
      toast.error('Введите название организации до 100 символов', {
        toastId: param.name,
      })
      return
    }

    if (!Validation.text(param.managersFullName)) {
      toast.error('Не валидное ФИО', {
        toastId: param.managersFullName,
      })
      return
    }

    let params: OrganizationRequestsBodyModel.post = {
      name: param.name,
      managersFullName: param.managersFullName,
      foundingOrganizationId: parentOrgId ? Number(parentOrgId) : undefined,
    }

    dispatch(organizationActions.setIsModal(false))
    dispatch(organizationFetchAdd(params)).then(async () => {
      dispatch(organizationActions.organizationResetData())
      await getOrganization(Number(parentOrgId))
      toast.success(`Организация ${param.name} успешна добавлена`)
    })
  }

  const editCompany = (param: IEditOrganizationParam) => {
    if (!Validation.text(param.name)) {
      toast.error('Не валидное название', {
        toastId: param.name,
      })
      return
    }

    if (param.name.trim().length > 100) {
      toast.error('Введите название организации до 100 символов', {
        toastId: param.name,
      })
      return
    }

    if (!Validation.text(param.managersFullName)) {
      toast.error('Не валидное ФИО', {
        toastId: param.managersFullName,
      })
      return
    }

    dispatch(organizationEdit(+param.id, param)).then(async () => {
      dispatch(organizationActions.organizationResetData())
      await getOrganization(Number(parentOrgId))
      toast.success(`Организация ${param.name} успешна изменена`)
    })

    dispatch(
      organizationActions.setStateModalEdit({
        id: '',
        name: '',
        managersFullName: '',
        foundingOrganizationId: '',
      }),
    )

    dispatch(organizationActions.setIsModal(false))
  }

  useEffect(() => {
    if (isEdit) {
      setTitleModal('Редактировать организацию')
      return
    }
    setTitleModal(currentOrganization ? 'Добавить дочернюю организацию' : 'Добавить организацию')
  }, [isEdit, currentOrganization])

  return (
    <>
      <BasicModal
        open={isModalAddCompany}
        setOpen={organizationActions.setIsModal}
        redux
        title={titleModal}
        elements={elements}
        handleSave={isEdit ? editCompany : addCompany}
        initialState={state}
      />
    </>
  )
})
