import axios, { AxiosRequestConfig } from 'axios';
import { toast } from 'react-toastify';
import { errorMessagesFromServer } from './localeErrorMessages';
import { baseAPI_URL } from './url';


export const qrAuthTracker = axios.create({
  baseURL: baseAPI_URL,
  timeout: 30000,
  // mode: 'cors',
  headers: {
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json; charset=utf-8',
    'Language': 'ru'
  },
  transformRequest: [
    (data) => {
      return JSON.stringify(data);
    }
  ]
});


export const qrAuthTrackerAuth = axios.create({
  baseURL: baseAPI_URL,
  timeout: 30000,
  // mode: 'cors',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
    'Language': 'ru'
  },
  transformRequest: [
    (data) => {
      return JSON.stringify(data);
    }
  ]
});

export const qrAuthTrackerUpload = axios.create({
  baseURL: baseAPI_URL,
  timeout: 30000,
  // mode: 'cors',
  headers: {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
    'Content-Type': 'multipart/form-data',
    'Access-Control-Allow-Origin': '*',
    'Language': 'ru'
  }
});

export const qrAuthTrackerDownloadFiles = axios.create({
  baseURL: baseAPI_URL,
  timeout: 30000,
  // mode: 'cors',
  headers: {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json; charset=utf-8',
    'Language': 'ru'
  },
  responseType: 'blob'
});

qrAuthTrackerAuth.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {

    console.log(error.response);

    type index = keyof typeof errorMessagesFromServer
    const errorCode: index = error?.response?.data?.code;
    const errorMessage =
      errorCode in errorMessagesFromServer
        ?
        errorMessagesFromServer[errorCode]
        :
        error?.response?.data?.message
          ?
          error?.response?.data?.message
          :
          'Неопределенная ошибка';

    toast.error(errorMessage, {
      toastId: 'AuthResReqError'
    });
    return Promise.reject(error.response);
  }
);


// Request interceptor for API UPLOAD calls
qrAuthTrackerUpload.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    const token = await localStorage.getItem('token');
    if (config.headers) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {

    type index = keyof typeof errorMessagesFromServer
    const errorCode: index = error?.response?.data?.code;
    const errorMessage =
      errorCode in errorMessagesFromServer
        ?
        errorMessagesFromServer[errorCode]
        :
        error?.response?.data?.message
          ?
          error?.response?.data?.message
          :
          'Неопределенная ошибка';


    toast.error(errorMessage, {
      toastId: 'AuthUploadError'
    });
    return Promise.reject(error.response);
  }
);

// Response interceptor for API UPLOAD calls
qrAuthTrackerUpload.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {

    type index = keyof typeof errorMessagesFromServer
    const errorCode: index = error?.response?.data?.code;
    const errorMessage =
      errorCode in errorMessagesFromServer
        ?
        errorMessagesFromServer[errorCode]
        :
        error?.response?.data?.message
          ?
          error?.response?.data?.message
          :
          'Неопределенная ошибка';


    toast.error(errorMessage, {
      toastId: 'UploadResError'
    });
    // localStorage.removeItem('token');
    return Promise.reject(error.response);
  }
);

// Request interceptor for API UploadFiles calls
qrAuthTrackerDownloadFiles.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    const token = await localStorage.getItem('token');
    if (config.headers) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {

    type index = keyof typeof errorMessagesFromServer
    const errorCode: index = error?.response?.data?.code;
    const errorMessage =
      errorCode in errorMessagesFromServer
        ?
        errorMessagesFromServer[errorCode]
        :
        error?.response?.data?.message
          ?
          error?.response?.data?.message
          :
          'Неопределенная ошибка';


    toast.error(errorMessage, {
      toastId: 'DownloadReqError'
    });
    return Promise.reject(error.response);
  }
);

// Response interceptor for API UploadFiles calls
qrAuthTrackerDownloadFiles.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {

    error?.response?.data?.text().then((res: any) => {

      const errorObj = JSON.parse(res)

      type index = keyof typeof errorMessagesFromServer
      const errorCode: index = errorObj?.code;
      const errorMessage =
        errorCode in errorMessagesFromServer
          ?
          errorMessagesFromServer[errorCode]
          :
          error?.response?.data?.message
            ?
            error?.response?.data?.message
            :
            'Нет данных для скачивания';


      toast.error(errorMessage, {
        toastId: 'DownloadResError'
      });

    })
    return Promise.reject(error.response);
  }
);

// Request interceptor for API calls
qrAuthTracker.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    const token = await localStorage.getItem('token');
    if (config.headers) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {

    type index = keyof typeof errorMessagesFromServer
    const errorCode: index = error?.response?.data?.code;
    const errorMessage =
      errorCode in errorMessagesFromServer
        ?
        errorMessagesFromServer[errorCode]
        :
        error?.response?.data?.message
          ?
          error?.response?.data?.message
          :
          'Неопределенная ошибка';


    toast.error(errorMessage, {
      toastId: 'TrackReqError'
    });
    return Promise.reject(error.response);
  }
);

// Response interceptor for API calls
qrAuthTracker.interceptors.response.use(
  (response) => {
    return response;
  },
  async function(error) {

    type index = keyof typeof errorMessagesFromServer
    const errorCode: index = error?.response?.data?.code;
    const errorMessage =
      errorCode in errorMessagesFromServer
        ?
        errorMessagesFromServer[errorCode]
        :
        error?.response?.data?.message
          ?
          error?.response?.data?.message
          :
          'Неопределенная ошибка';

    toast.error(errorMessage, {
      toastId: 'TrackResError'
    });

    const originalRequest = error.config;

    if (error && error.response && (error.response.status === 401 || error.response.status === 403) && (typeof originalRequest._retry === 'undefined' || !originalRequest._retry)) {
      localStorage.clear();
      window.location.replace('/');
    }
    return Promise.reject(error.response);
  }
);
