// Валидация полей
export const Validation = {
  email(email: string): boolean {
    const regexEmail = new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )

    return regexEmail.test(email)
  },
  text(text: string): boolean {
    if (!text) return false
    return text.trim().length > 0
  },
  number(text: number | null): boolean {
    if (!text) return false
    return text > 0
  },
  password(text: string): boolean {
    if (!text) return false
    return text.trim().length >= 6
  },
  phoneNumber(phoneNumber: string): boolean {
    return this.phoneNumberToMask(phoneNumber).length === 16
  },
  phoneNumberToMask(phoneNumber: string): string {
    if (!phoneNumber) return ''

    // удалим все кроме цифры и добавим +
    const num =
      '+' +
      phoneNumber
        .split('')
        .filter(item => {
          const noValidSymbol = isNaN(+item)

          if (item && !noValidSymbol) {
            return item
          }

          return ''
        })
        .join('')

    const phone = num.trim().split(' ').join('')

    const codeState = phone.substring(0, 2)

    const codeRegion = phone.substring(2, 5)

    const num1 = phone.substring(5, 8)

    const num2 = phone.substring(8, 10)

    const num3 = phone.substring(10, 12)

    // +7 988 123 45 67

    return `${codeState} ${codeRegion} ${num1} ${num2} ${num3}`
  },
}
