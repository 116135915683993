import { useReport } from 'hooks/useReport'
import { useStatisticsActionsDisabled } from '../controllers/useStatisticsActionsDisabled'
import { ButtonStyled } from '../styled/StatisticsActionsEdit.styled'

export const StatisticsActionsDownloadReport = () => {
  const { handleDownloadReport } = useReport()
  const { onDisabled } = useStatisticsActionsDisabled()

  return (
    <ButtonStyled onClick={handleDownloadReport} disabled={onDisabled()} variant='contained'>
      Скачать отчет
    </ButtonStyled>
  )
}
