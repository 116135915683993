import { Stack } from '@mui/material'
import { useAppSelector } from '../../../../../../hooks/redux'
import { TableHeadCellStyled } from '../../styled/StatisticsTable.styled'
import { StatisticsTableHeadCalendarTypeCondition } from './StatisticsTableHeadCalendarTypeCondition'

export const StatisticsTableHeadCalendar = () => {
  const intervalData = useAppSelector(state => state.shiftsReducer.intervalData)

  return (
    <Stack direction='row'>
      {intervalData.map((intervalItem, idx) => {
        return (
          <TableHeadCellStyled
            key={intervalItem}
            sx={{
              display: 'flex',
              padding: '8px 0',
              borderRight: intervalData.length - 1 !== idx ? '1px solid #FFFFFF' : 'none',
            }}
          >
            {StatisticsTableHeadCalendarTypeCondition(intervalItem)}
          </TableHeadCellStyled>
        )
      })}
    </Stack>
  )
}
