import qs from 'query-string'
import { ShiftRequestsBodyModel, ShiftRequestsQueryModel } from '../../models/requests/shift-requests.model'
import { ShiftModel } from '../../models/shift.model'
import { qrAuthTracker, qrAuthTrackerDownloadFiles } from '../axios'
import { AxiosResponseModel } from '../types'

// =================== SHIFT ==============================
export const qrAuthDownloadShiftReport = (params: ShiftRequestsBodyModel.downloadReport) =>
  qrAuthTrackerDownloadFiles.post(`/management/worker/shift/report`, params)

export const qrAuthGetShiftsByFilter = (
  query: ShiftRequestsQueryModel.postFilter,
  params: ShiftRequestsBodyModel.postFilter,
): AxiosResponseModel<ShiftModel[]> => qrAuthTracker.post(`/management/worker/shift/filter?${qs.stringify(query)}`, params)

export const qrAuthPostEmployeeShift = (params: ShiftRequestsBodyModel.postBatch): AxiosResponseModel<null> =>
  qrAuthTracker.post(`/management/worker/shift`, params)
