import { memo } from 'react'
import { StatisticsShiftsChangeStatusModal } from './components/StatisticsShiftsChangeStatusModal'
import { StatisticsViewListAmountOfFinesModal } from './components/StatisticsAmountOfFinesModals/StatisticsViewListAmountOfFinesModal'
import { StatisticsAddAmountOfFinesModal } from './components/StatisticsAmountOfFinesModals/StatisticsAddAmountOfFinesModal'
import { useAppSelector } from 'hooks/redux'

export const StatisticsShiftsModals = memo(() => {
  const isShiftModal = useAppSelector(state => state.shiftsReducer.isShiftModal)
  const isViewPeriodicPaymentModal = useAppSelector(state => state.periodicPaymentReducer.isViewPeriodicPaymentModal)
  const isAddPeriodicPaymentModal = useAppSelector(state => state.periodicPaymentReducer.isAddPeriodicPaymentModal)
  
  return (
    <>
      {isShiftModal && <StatisticsShiftsChangeStatusModal />}
      {isViewPeriodicPaymentModal && <StatisticsViewListAmountOfFinesModal />}
      {isAddPeriodicPaymentModal && <StatisticsAddAmountOfFinesModal />}
    </>
  )
})
