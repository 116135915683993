import { qrAuthDownloadShiftReport, qrAuthGetShiftsByFilter, qrAuthPostEmployeeShift } from '../api/utils/shiftApi'
import { shiftsActions } from '../store/reducers/ShiftsSlice'
import { AppDispatch } from '../store/store'
import { ShiftRequestsBodyModel, ShiftRequestsQueryModel } from '../models/requests/shift-requests.model'

export const getShiftsByFilter =
  (query: ShiftRequestsQueryModel.postFilter, params: ShiftRequestsBodyModel.postFilter) => async (dispatch: AppDispatch) => {
    dispatch(shiftsActions.setStatus('init'))
    try {
      const response = await qrAuthGetShiftsByFilter(query, params)

      dispatch(shiftsActions.shiftsFetch(response.data.items))
      dispatch(shiftsActions.setPageCount(response.data?.paging?.lastPage || 1))
      dispatch(shiftsActions.setPage(response.data?.paging?.page || 1))
      dispatch(shiftsActions.setStatus('loading'))

      return Promise.resolve(response)
    } catch (err: any) {
      dispatch(shiftsActions.setPageCount(1))
      dispatch(shiftsActions.shiftsFetch([]))
      dispatch(shiftsActions.setStatus('error'))
      return Promise.reject(err)
    } finally {
      dispatch(shiftsActions.setStatus('success'))
    }
  }

// Скачивание отчета
export const downloadReportShifts = (params: ShiftRequestsBodyModel.downloadReport) => async (dispatch: AppDispatch) => {
  try {
    const response = await qrAuthDownloadShiftReport(params)

    const downloadUrl = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = downloadUrl
    link.setAttribute('download', `Отчет_смен_${params.period.start + '-' + params.period.end}.xlsx`)
    document.body.appendChild(link)
    link.click()
    link.remove()

    return Promise.resolve(response)
  } catch (err: any) {
    return Promise.reject(err)
  } finally {
  }
}

// Пакетно обновлять смены
// export const putShiftsBatchService = (params: ShiftRequestsBodyModel.putBatch) => async (dispatch: AppDispatch) => {
//   try {
//     dispatch(shiftsActions.setStatus('loading'))

//     const response = await qrAuthPutBatchShifts(params)

//     return Promise.resolve(response)
//   } catch (err) {
//     dispatch(shiftsActions.setStatus('error'))

//     return Promise.reject(err)
//   } finally {
//     dispatch(shiftsActions.setStatus('success'))
//   }
// }

export const postEmployeeShift = (params: ShiftRequestsBodyModel.postBatch) => async (dispatch: AppDispatch) => {
  try {
    dispatch(shiftsActions.setStatus('loading'))
    const response = await qrAuthPostEmployeeShift(params)
    return Promise.resolve(response)
  } catch (err) {
    dispatch(shiftsActions.setStatus('error'))
    return Promise.reject(err)
  } finally {
    dispatch(shiftsActions.setStatus('success'))
  }
}
