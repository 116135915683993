import { StatusType } from '../../models/common/common.model'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { StatusResponse } from '../../models/responses/status.response'

interface IState {
  isEditStatuses: boolean
  statuses: StatusResponse[]
  status: StatusType
}

const initialState: IState = {
  statuses: [],
  isEditStatuses: false,
  status: 'init',
}

export const statusSlice = createSlice({
  name: 'statusSlice',
  initialState,
  reducers: {
    setStatus: (state, action: PayloadAction<StatusType>) => {
      state.status = action.payload
    },
    fetchStatuses: (state, action: PayloadAction<StatusResponse[]>) => {
      state.statuses = action.payload
    },
    setIsEditStatuses: (state, action: PayloadAction<boolean>) => {
      state.isEditStatuses = action.payload
    },
  },
})

export const { reducer: statusReducer, actions: statusActions } = statusSlice
