import { IItemElement } from '../../components/Modals/BasicModal/BasicModal'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface InitialState {
  tabsItems: IItemElement[]
  tabIndex: number
}

const initialState: InitialState = {
  tabsItems: [],
  tabIndex: 0,
}
export const tabsSlice = createSlice({
  name: 'actionsSlice',
  initialState,
  reducers: {
    setTabsItems: (state, action: PayloadAction<IItemElement[]>) => {
      state.tabsItems = action.payload
    },
    setTabIndex: (state, action: PayloadAction<number>) => {
      state.tabIndex = action.payload
    },
  },
})

export const { reducer: tabsReducer, actions: tabsActions } = tabsSlice
