import { useLocation, matchPath } from 'react-router-dom'
import { RoutesPath } from '../helpers/routePaths'

export const useDocumentTitle = () => {
  const location = useLocation()

  return () => {
    let title = ''

    RoutesPath.forEach(route => {
      if (matchPath(route, location.pathname)) {
        title = route.title
      }
    })

    document.title = title

    return title
  }
}
