import { styled, Typography } from '@mui/material'

export const TypographyMainStyled = styled(Typography)<{ isHover?: boolean }>(({ isHover = true, theme }) => ({
  fontSize: 20,
  fontWeight: 600,
  cursor: isHover ? 'pointer' : 'auto',
  transition: theme.transitions.create(['color'], { duration: '0.3s' }),
  '&:hover': {
    color: isHover && theme.palette.grey[500],
  },
}))
